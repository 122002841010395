import React from 'react';
import { Box, Popover, Typography } from '@mui/material';
import { TRAIN_TYPES } from '../../config';
import Colors from '../../styles/Colors';
import StatsCount from './StatsCount';

function RulePopover({ anchorEl, rules, sentence, ruleStats }) {
  const ruleNames = (rules || []).map(({ name }) => name);
  const playbookNames = [
    ...new Set(
      (rules || []).flatMap(({ playbookIds }) => playbookIds).map((p) => p.name)
    ),
  ];
  const sortOrder = ['accept', 'revise', 'reject', 'noop'];

  function editTypeSort(a, b) {
    let sortAVal = sortOrder.indexOf(a);
    let sortBVal = sortOrder.indexOf(b);
    if (sortAVal < 0) {
      sortAVal = Number.MAX_VALUE;
    }
    if (sortBVal < 0) {
      sortBVal = Number.MAX_VALUE;
    }
    return sortAVal - sortBVal;
  }

  return (
    <Popover
      sx={{ pointerEvents: 'none' }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={!anchorEl}
      disableRestoreFocus
    >
      <Box sx={{ width: 325, minHeight: 150, px: 2, py: 2 }}>
        <Typography variant="h3" sx={{ pb: 2 }}>
          More Information
        </Typography>
        <Box>
          <Typography variant="body1">
            From playbook: <b>{playbookNames.join(',')}</b>
          </Typography>
          <Typography variant="body1">
            From rule: <b>{ruleNames.join(',')}</b>
          </Typography>
          <br />
          <Typography variant="body1" pb={1}>
            This rule has:
          </Typography>
          {Object.entries(ruleStats)
            .filter((entry) => entry[0] === 'edit_type')
            .map(([stat, statVals]) => (
              <Box key={stat} pb={1}>
                {Object.entries(statVals)
                  .sort((entryA, entryB) => editTypeSort(entryA[0], entryB[0]))
                  .map(([statVal, statCount]) => (
                    <StatsCount
                      key={statVal}
                      count={statCount}
                      field={stat}
                      fieldValue={statVal}
                    />
                  ))}
              </Box>
            ))}
        </Box>
      </Box>
    </Popover>
  );
}

export default RulePopover;
