import React from 'react';
import PropTypes from 'prop-types';

export default function UnAuthenticatedRoute({ children, ...otherParams }) {
  const handleRoute = () => <div {...otherParams}>{children}</div>;

  return handleRoute();
}

UnAuthenticatedRoute.propTypes = {
  children: PropTypes.any.isRequired,
};
