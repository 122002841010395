import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import PriorityListActionBar from './PriorityListActionBar';

const styles = (theme) => ({
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
});

function PriorityList({
  title,
  items,
  selectedItems,
  hideButtons,
  hidePreferred,
  getId,
  getLabel,
  onItemsChanged,
  onSelectedItemsChanged,
  classes,
}) {
  const [hoverItem, setHoverItem] = useState();
  const [preferredItem, setPreferredItem] = useState();

  useEffect(() => {
    setPreferredItem(items[0]);
  }, [items]);

  function handleItemClicked(item) {
    if (selectedItems.includes(item)) {
      onSelectedItemsChanged((selectedItems) => selectedItems.filter((i) => i !== item));
    } else {
      onSelectedItemsChanged((selectedItems) => [...selectedItems, item]);
    }
  }

  function handleCheckAll() {
    if (selectedItems.length < items.length) {
      onSelectedItemsChanged(items);
    } else {
      onSelectedItemsChanged([]);
    }
  }

  function handleSetPreferred(preferredItem, ev) {
    ev.stopPropagation();
    setPreferredItem(preferredItem);
    const newItems = items.filter((item) => item !== preferredItem);
    onItemsChanged([preferredItem, ...newItems]);
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={hideButtons ? 12 : 8}>
        <Card>
          <CardHeader
            className={classes.cardHeader}
            avatar={
              <Checkbox
                onClick={() => handleCheckAll()}
                checked={
                  selectedItems.length > 0 && selectedItems.length === items.length
                }
                indeterminate={
                  selectedItems.length > 0 && selectedItems.length < items.length
                }
                disabled={items.length === 0}
                inputProps={{ 'aria-label': 'select all items' }}
              />
            }
            title={title}
            subheader={`${selectedItems.length}/${items.length} items selected`}
          />
          <Divider />
          <List className={classes.list} dense component="div" role="list">
            {items.map((listItem) => (
              <ListItemButton
                key={getId(listItem)}
                role="listitem"
                onClick={() => handleItemClicked(listItem)}
                onMouseEnter={() => setHoverItem(listItem)}
                onMouseLeave={() => setHoverItem(null)}
                sx={{ minHeight: 46 }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={selectedItems.includes(listItem)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': getId(listItem) }}
                  />
                </ListItemIcon>
                <ListItemText id={getId(listItem)} primary={getLabel(listItem)} />
                {!hidePreferred && (
                  <Tooltip
                    title={
                      <Box sx={{ fontSize: '12px' }}>Set as the preferred location</Box>
                    }
                  >
                    <IconButton
                      sx={{
                        ...((listItem !== hoverItem || listItem === preferredItem) && {
                          display: 'none',
                        }),
                      }}
                      onClick={(e) => handleSetPreferred(listItem, e)}
                      aria-label="set preferred item"
                    >
                      <StarOutlineIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!hidePreferred && (
                  <Tooltip
                    title={<Box sx={{ fontSize: '12px' }}>Your preferred location</Box>}
                  >
                    <IconButton
                      sx={{
                        ...(listItem !== preferredItem && {
                          display: 'none',
                        }),
                      }}
                      aria-label="preferred item"
                    >
                      <StarIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItemButton>
            ))}
          </List>
        </Card>
      </Grid>
      {!hideButtons && (
        <Grid item xs={4}>
          <PriorityListActionBar
            priorityLists={[
              {
                items: items,
                selectedItems: selectedItems,
                onItemsChanged: onItemsChanged,
                onSelectedItemsChanged: onSelectedItemsChanged,
              },
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
}

PriorityList.defaultProps = {
  title: 'Items',
  items: [],
  hideButtons: false,
  hidePreferred: false,
  getId: (f) => f.id,
  getLabel: (f) => f.name,
  onItemsChanged: (f) => f,
  onSelectedItemsChanged: (f) => f,
};

PriorityList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  hideButtons: PropTypes.bool,
  hidePreferred: PropTypes.bool,
  getId: PropTypes.func,
  getLabel: PropTypes.func,
  onItemsChanged: PropTypes.func,
  onSelectedItemsChanged: PropTypes.func,
};

export default withStyles(styles)(PriorityList);
