import {
  CLEAR_FAST_TRACK_REDUX_STORE,
  SET_CONFIG,
  SET_GATING_COMPLETE,
  SET_IP_GATING_COMPLETE,
  SET_SELECTIONS,
  SET_DOCUMENT_GENERATED,
  SET_DOCUMENT_FILEPATH,
  SET_COUNTERPARTY,
} from '../action-types/fastTrackTypes';

const initialState = {
  config: null,
  selections: null,
  gatingComplete: false,
  ipGatingComplete: false,
  documentGenerated: false,
  documentFilepath: null,
  counterparty: null,
};

function fastTrackReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    case SET_SELECTIONS:
      return {
        ...state,
        selections: action.payload,
      };
    case SET_GATING_COMPLETE:
      return {
        ...state,
        gatingComplete: action.payload,
      };
    case SET_IP_GATING_COMPLETE:
      return {
        ...state,
        ipGatingComplete: action.payload,
      };
    case SET_DOCUMENT_GENERATED:
      return {
        ...state,
        documentGenerated: action.payload,
      };
    case SET_DOCUMENT_FILEPATH:
      return {
        ...state,
        documentFilepath: action.payload,
      };
    case SET_COUNTERPARTY:
      return {
        ...state,
        counterparty: action.payload,
      };
    case CLEAR_FAST_TRACK_REDUX_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default fastTrackReducer;
