import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Popover } from '@mui/material';

import InfoPopoverStyles from './styles';

const InfoPopover = ({ classes, anchorEl, content, popoverClose }) => {
  const open = Boolean(anchorEl);

  return (
    <Popover
      className={classes.popover}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={popoverClose}
      disableRestoreFocus
    >
      <div className={classes.infoContent}>{content}</div>
    </Popover>
  );
};

InfoPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  popoverClose: PropTypes.func.isRequired,
};

export default withStyles(InfoPopoverStyles)(InfoPopover);
