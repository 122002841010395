import React from 'react';
import PropTypes from 'prop-types';

import { Button, Grid } from '@mui/material';

import { BaseDialog } from '../BaseDialog';

const OpenExportDialog = ({ open, handleExport = (f) => f, handleClose = (f) => f }) => (
  <div>
    <BaseDialog
      open={open}
      handleClose={handleClose}
      showContractIcon
      purpose="export"
      title="Export Contract"
      subtitle="Exporting a document signals to BlackBoiler that you are sending the contract back to the counter party. We use this opportunity to update our machine learning editing engine, so that in the future we can provide you better edits."
    >
      <Grid item>
        <Button onClick={handleClose} variant="outlined" fullWidth>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button onClick={handleExport} color="secondary" variant="contained" fullWidth>
          Export
        </Button>
      </Grid>
    </BaseDialog>
  </div>
);

OpenExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleExport: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default OpenExportDialog;
