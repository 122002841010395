import React from 'react';

import { MenuItem } from '@mui/material';

import FastTrackStyles from '../styles/FastTrackStyles';

export const getDateAdjustedStr = (days) => {
  // format: "MONTH_NAME DAY, YEAR"
  const currDate = new Date();
  currDate.setDate(currDate.getDate() + days);
  return `${currDate.toLocaleString('default', { month: 'long' })} ${currDate.getDate()}, ${currDate.getFullYear()}`;
};

export const buildMenuItems = (values) =>
  values.map((v) => (
    <MenuItem key={v} value={v} style={{ ...FastTrackStyles().menuSelect }}>
      {v}
    </MenuItem>
  ));

export const buildMenuItemsFromMap = (
  objs,
  menuItemKeyLabel = 'k',
  menuItemValueLabel = 'v'
) =>
  objs.map((obj) => (
    <MenuItem
      key={obj[menuItemKeyLabel]}
      value={obj[menuItemValueLabel]}
      style={{ ...FastTrackStyles().menuSelect }}
    >
      {obj[menuItemValueLabel]}
    </MenuItem>
  ));

export const isValueDuplicate = (arr, fieldName, value) =>
  arr.some((obj) => obj[fieldName] === value);

export const sortListOfObjectBy = (objList, fieldName) =>
  objList.sort((a, b) => {
    if (a[fieldName] < b[fieldName]) {
      return 1;
    }
    if (a[fieldName] > b[fieldName]) {
      return -1;
    }
    return 0;
  });
