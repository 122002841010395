import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import { FormControlLabel, Checkbox } from '@mui/material';

import Colors from '../../../../styles/Colors';
import FastTrackStyles from '../../../../styles/FastTrackStyles';

const CustomCheckbox = withStyles({
  root: {
    color: Colors.blue,
    '&$checked': {
      color: Colors.blue,
    },
  },
  checked: {},
})(({ ...props }) => <Checkbox color="default" {...props} />);

const CheckboxFormik = ({
  formikValues,
  setFieldValue,
  formikHandleBlur,
  name,
  title,
  classes,
  disabled = false,
}) => (
  <FormControlLabel
    key={`${name}Checkbox`}
    className={classes.checkboxFormControl}
    control={
      <CustomCheckbox
        checked={formikValues[name]}
        onChange={(e) => setFieldValue(name, e.target.checked)}
        onBlur={formikHandleBlur}
        name={name}
        value={formikValues[name]}
        disabled={disabled}
      />
    }
    label={title}
  />
);

CheckboxFormik.propTypes = {
  classes: PropTypes.object.isRequired,
  formikValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  formikHandleBlur: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default withStyles(FastTrackStyles)(CheckboxFormik);
