import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import withStyles from '@mui/styles/withStyles';
import { Grid, Link, Typography } from '@mui/material';

import breadCrumbStyles from './AppBarBreadCrumbStyles';

const AppBarBreadCrumb = ({
  classes,
  breadCrumbs,
  buttonRight = null,
  returnLabelText = null,
  returnLabelLink = null,
}) => {
  const { label: returnLabel, path: returnPath } = breadCrumbs[0];
  const breadCrumbsLength = breadCrumbs.length;

  return (
    <>
      <Grid container spacing={2} alignItems="center" className={classes.wrapper}>
        {breadCrumbs.map(({ label: linkLabel, path }, idx) =>
          breadCrumbsLength === idx + 1 ? (
            <Grid item key={path}>
              <Typography className={`${classes.primaryLink} ${classes.endCrumb}`}>
                {linkLabel}
              </Typography>
            </Grid>
          ) : (
            <React.Fragment key={path}>
              <Grid item>
                <Link component={RouterLink} to={path} underline="none">
                  <Typography className={classes.primaryLink}>{linkLabel}</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Typography>/</Typography>
              </Grid>
            </React.Fragment>
          )
        )}
      </Grid>

      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Grid item>{buttonRight}</Grid>
        <Grid item>
          <Link
            component={RouterLink}
            to={returnLabelLink || returnPath}
            underline="none"
          >
            <Typography className={classes.returnLink}>
              {`Back to ${returnLabelText || returnLabel}`}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

AppBarBreadCrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  breadCrumbs: PropTypes.array.isRequired,
  buttonRight: PropTypes.any,
  returnLabelText: PropTypes.string,
  returnLabelLink: PropTypes.string,
};

export default withStyles(breadCrumbStyles)(AppBarBreadCrumb);
