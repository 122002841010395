import Colors from '../../styles/Colors';

const AnswerQuestionsStatusStyles = () => ({
  startEditButton: {
    width: 75,
  },
  dividerTop: {
    borderTop: `1px solid ${Colors.grey1}`,
    marginBottom: 20,
    marginTop: 20,
  },
  dividerBottom: {
    borderBottom: `1px solid ${Colors.grey1}`,
    marginTop: 20,
  },
  ruleCount: {
    color: '#748494',
    fontWeight: 'normal',
    fontSize: 'small',
    verticalAlign: 'middle',
    display: 'inline-block',
    lineHeight: 'normal',
    height: '100%',
    marginLeft: 10,
  },
  deleteButton: {
    color: Colors.red,
  },
});

export default AnswerQuestionsStatusStyles;
