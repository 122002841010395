const ConfirmSaveRuleModalStyles = () => ({
  clearCommentButton: {
    minWidth: '100%',
    minHeight: '100%',
    backgroundColor: 'lightblue',
  },
  menuItem: {
    maxWidth: 550,
    minWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
  loadingWrapper: {
    float: 'none',
    textAlign: 'center',
    display: 'inline-block',
  },
});

export default ConfirmSaveRuleModalStyles;
