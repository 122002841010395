import Colors from './Colors';

const styles = () => ({
  modalWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    backgroundColor: Colors.grey1,
    borderRadius: '8px 8px 0px 0px',
  },
  modalContentWrapper: {
    borderRadius: 8,
    maxWidth: 740,
    width: '100%',
    backgroundColor: 'white',
  },
  modalModelImg: {
    width: 43,
  },
  modalCloseIcon: {
    fontSize: '2.5rem',
    color: Colors.grey2,
  },
  labelText: {
    marginBottom: 5,
  },
  warningText: {
    color: Colors.red,
    marginTop: 5,
  },
});

export default styles;
