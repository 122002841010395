import axios from 'axios';

import {
  QUESTIONNAIRES_ADD_SECTION_ENDPOINT,
  QUESTIONNAIRES_DEFAULT_ENDPOINT,
  QUESTIONNAIRES_ENDPOINT,
  QUESTIONNAIRES_LIST_ENDPOINT,
  QUESTIONNAIRES_MOVE_ENDPOINT,
} from '../endpoints.config';
import { addContentHeader } from '../utils/requests';

export async function GetQuestionnaire(headers, questionnaireId) {
  const response = await axios
    .get(`${QUESTIONNAIRES_ENDPOINT}/${questionnaireId}`, { headers })
    .catch((err) => {
      console.log('Error! Failed to retrieve questionnaire with error:', err);
      return err;
    });
  if (response.status === 200 && response.data) {
    return response.data;
  }
  throw Error('No questionnaire found');
}

export async function GetQuestionnaires(headers) {
  const response = await axios
    .get(`${QUESTIONNAIRES_LIST_ENDPOINT}`, { headers })
    .catch((err) => {
      console.log('Error! Failed to retrieve questionnaires list with error:', err);
      return err;
    });
  if (response.status === 200 && response.data) {
    return response.data;
  }
  throw Error('Failed to retrieve questionnaires');
}

export async function GetQuestionnairesByContractType(headers, contractType) {
  const response = await axios
    .get(`${QUESTIONNAIRES_LIST_ENDPOINT}?contractType=${contractType}`, { headers })
    .catch((err) => {
      console.log('Error! Failed to retrieve questionnaires list with error:', err);
      return err;
    });
  if (response.status === 200 && response.data) {
    return response.data;
  }
  throw Error('Failed to retrieve questionnaires');
}

export async function GetDefaultQuestionnaireByModelId(headers, modelId) {
  try {
    const { data } = await axios.get(
      `${QUESTIONNAIRES_DEFAULT_ENDPOINT}?modelId=${modelId}`,
      { headers }
    );
    return data;
  } catch (err) {
    const msg = `Error! Failed to retrieve default questionnaire for model ${modelId} with error: ${err}}`;
    console.error(msg);
  }
}

export async function GetQuestionnairesByModel(headers, modelId) {
  try {
    const { data } = await axios.get(`${QUESTIONNAIRES_ENDPOINT}?modelId=${modelId}`, {
      headers,
    });
    return data;
  } catch (err) {
    const msg = `Error! Failed to retrieve questionnaires for model ${modelId} with error: ${err}}`;
    console.error(msg);
  }
}

export async function CreateQuestionnaire(headers, data) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(`${QUESTIONNAIRES_ENDPOINT}`, data, {
    headers: updatedHeaders,
  });
  if (response.status === 200 && response.data) {
    return response.data;
  }
  throw Error('Error! Failed to create questionnaire');
}

export async function MoveQuestion(headers, id, direction, question, model) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(
    `${QUESTIONNAIRES_MOVE_ENDPOINT(id)}`,
    { direction, question, model },
    { headers: updatedHeaders }
  );
  if (response.status === 200) {
    return 200;
  }
  if (response.status === 204) {
    return 204;
  }
  throw Error(`Error! Failed to move question ${direction}`);
}

export async function AddQuestionnaireSection(headers, id, section, model) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(
    `${QUESTIONNAIRES_ADD_SECTION_ENDPOINT(id)}`,
    { section, model },
    { headers: updatedHeaders }
  );
  if (response.status === 200 && response.data) {
    return response.data;
  }
  throw Error('Error! Failed to add section to questionnaire');
}

export async function DeleteQuestionnaire(headers, params) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.delete(`${QUESTIONNAIRES_ENDPOINT}?${params}`, {
    headers: updatedHeaders,
  });
  if (response.status === 200) {
    return response;
  }
  throw Error('Error! Failed to delete questionnaire');
}
