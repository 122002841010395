import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Grid, IconButton, LinearProgress, Typography } from '@mui/material';

import contractIcon from '../../assets/images/contract_icon.png';
import BaseDialogStyles from './styles';

const BaseDialog = ({
  classes,
  open = null,
  loading = null,
  error = null,
  cancel = false,
  purpose,
  title,
  subtitle = '',
  showContractIcon = false,
  children = null,
  alert = false,
  size = 'sm',
  useFullWidth = false,
  handleClose = (f) => f,
  fasttrackDialog = false,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby={`${purpose}-dialog-title`}
    aria-describedby={`${purpose}-dialog-description`}
    maxWidth={size}
    fullWidth={useFullWidth}
  >
    <div className={classes.dialogWrapper}>
      <Grid container direction="column" className={classes.dialogContentWrapper}>
        {/* HEADER */}
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          spacing={1}
          className={alert ? classes.dialogHeaderAlert : classes.dialogHeader}
        >
          <Grid container item direction="row" justifyContent="space-between">
            <Grid item xs={1} />
            {showContractIcon ? (
              <Grid item className={classes.dialogModelGrid}>
                <img
                  src={contractIcon}
                  alt={`${purpose} icon`}
                  className={classes.dialogModelImg}
                />
              </Grid>
            ) : null}
            <Grid item xs={1}>
              <IconButton aria-label="close" onClick={handleClose} size="large">
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant="h2" className={classes.text}>
              {title}
            </Typography>
          </Grid>
          {subtitle ? (
            <Grid item>
              <Typography
                variant="body2"
                className={`${classes.text} ${classes.paddingTop}`}
              >
                {subtitle}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        {loading ? (
          <LinearProgress className={classes.dialogLoading} />
        ) : (
          <div className={classes.dialogLoading} />
        )}
        {error && !cancel ? (
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            spacing={2}
            className={classes.dialogError}
          >
            <Grid item>
              <Typography variant="h2">Error sending your request.</Typography>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {/* Content */}
        {fasttrackDialog ? (
          <Grid item className={classes.dialogFastTrackContent}>
            {children}
          </Grid>
        ) : (
          <Grid
            container
            item
            direction="row"
            justifyContent="space-around"
            className={classes.dialogContent}
          >
            {children}
          </Grid>
        )}
      </Grid>
    </div>
  </Dialog>
);

BaseDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  cancel: PropTypes.bool,
  purpose: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showContractIcon: PropTypes.bool,
  children: PropTypes.any,
  size: PropTypes.string,
  alert: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  useFullWidth: PropTypes.bool,
  fasttrackDialog: PropTypes.bool,
};

export default withStyles(BaseDialogStyles)(BaseDialog);
