import moment from 'moment';

import { ACCOUNT_GROUPS_CONFIG } from '../groups.config';

export const truthy = (val) => ['True', 'TRUE', 'true', true, 'y', 'yes'].includes(val);
export const falsey = (val) =>
  ['False', 'FALSE', 'false', false, 'n', 'no'].includes(val);
export const isBool = (val) => truthy(val) || falsey(val);

export const isDate = (val) => Date.parse(val);
export const isFormattedDate = (val) =>
  Date.parse(val) && moment(Date.parse(val)).format('MMMM D, YYYY');
export const formatDateToStandard = (val) => moment(val).format('MMMM D, YYYY');
export const formatDateToStandardDateTime = (val) =>
  moment(val).format('MMMM D, YYYY LT');

export const getValidRoles = (roles) => {
  const validRoles = Object.keys(ACCOUNT_GROUPS_CONFIG);
  return roles.reduce((acc, role) => {
    if (validRoles.includes(role._id)) {
      acc.push(role);
    }
    return acc;
  }, []);
};

/** Convert time to duration
 *
 * Time will be number. It can be string or number. exp: ('123', '12.3', 123)
 *
 * Type ('seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years')
 */
export const convertTimeToDuration = (time, type) => {
  if (time === 0 || time === '0') {
    return '-';
  }

  const durationTime = moment.duration(time, type).humanize();
  return durationTime;
};

export const convertOnlyOfficeErrorMessage = (code) => {
  switch (code) {
    case -1:
      return 'Unknown error';
    case -2:
      return 'Conversion timeout error';
    case -3:
      return 'Conversion error';
    case -4:
      return 'Error while downloading the document file to be converted';
    case -5:
      return 'Incorrect password';
    case -6:
      return 'Error while accessing the conversion result database';
    case -7:
      return 'Input error';
    case -8:
      return 'Invalid token';
    default:
      return `Error code: ${code}`;
  }
};
