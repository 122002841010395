import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

const ToggleableLinkButton = ({
  link = '',
  handleClick = (f) => f,
  disabled = false,
  children = null,
}) =>
  disabled ? (
    <Button
      color="primary"
      variant="contained"
      className="startBtn"
      onClick={handleClick}
      disabled
    >
      {children}
    </Button>
  ) : (
    <Button
      color="primary"
      variant="contained"
      className="startBtn"
      onClick={handleClick}
      component={RouterLink}
      to={link}
    >
      {children}
    </Button>
  );

ToggleableLinkButton.propTypes = {
  link: PropTypes.string,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default ToggleableLinkButton;
