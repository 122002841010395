import axios from 'axios';

import { WEBHOOK_EXPORT_CONTRACT_ENDPOINT } from '../endpoints.config';
import { addContentHeader } from '../utils/requests';

export async function submitContractExportWebhook(headers, id) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(
    `${WEBHOOK_EXPORT_CONTRACT_ENDPOINT(id)}`,
    {},
    { headers: updatedHeaders }
  );
  if (response.status === 200) {
    return response;
  }
  throw Error('Error! Failed to create question');
}
