import axios from 'axios';

import {
  INTEGRATION_AUTH_REFRESH_ENDPOINT,
  INTEGRATION_AUTH_UPDATE_ENDPOINT,
  INTEGRATION_AUTHORIZE_ENDPOINT,
  INTEGRATION_AUTH_TOKEN_ENDPOINT,
  INTEGRATION_REGISTER_ENDPOINT,
  INTEGRATION_DELETE_ENDPOINT,
  INTEGRATION_SUBSCRIBE_ENDPOINT,
  INTEGRATION_SUBSCRIPTIONS_ENDPOINT,
  INTEGRATION_DELETE_SUBSCRIPTION_ENDPOINT,
  INTEGRATIONS_ENDPOINT,
} from '../endpoints.config';

export function authorizeUser(headers, rpValues, subscribeOnAuthorize, isOnboarding) {
  if (subscribeOnAuthorize) {
    rpValues = { ...rpValues, subscribeOnAuthorize, isOnboarding };
  }
  return axios.post(INTEGRATION_AUTHORIZE_ENDPOINT, rpValues, { headers });
}

export function deleteIntegration(headers, id) {
  return axios.delete(INTEGRATION_DELETE_ENDPOINT(id), { headers });
}

export function deleteSubscription(headers, id, subscriptionId) {
  return axios.delete(INTEGRATION_DELETE_SUBSCRIPTION_ENDPOINT(id, subscriptionId), {
    headers,
  });
}

export function deleteSubscriptions(headers, id) {
  return axios.delete(INTEGRATION_SUBSCRIPTIONS_ENDPOINT(id), { headers });
}

export function getIntegration(headers, id) {
  return axios.get(`${INTEGRATIONS_ENDPOINT}/${id}`, { headers });
}

export function getIntegrations(headers) {
  return axios.get(INTEGRATIONS_ENDPOINT, { headers });
}

export function getSubscriptions(headers, id) {
  return axios.get(INTEGRATION_SUBSCRIPTIONS_ENDPOINT(id), { headers });
}

export function refreshTokens(headers, id, force = false) {
  return axios.post(INTEGRATION_AUTH_REFRESH_ENDPOINT, { id, force }, { headers });
}

export function requestTokens(headers, id) {
  return axios.post(INTEGRATION_AUTH_TOKEN_ENDPOINT, { id }, { headers });
}

export function registerIntegration(headers, integrationData) {
  return axios.post(INTEGRATION_REGISTER_ENDPOINT, integrationData, { headers });
}

export function subscribeToEvents(headers, id, data) {
  return axios.post(INTEGRATION_SUBSCRIBE_ENDPOINT(id), data, { headers });
}

export function updateConfig(headers, rpValues) {
  return axios.post(INTEGRATION_AUTH_UPDATE_ENDPOINT, rpValues, { headers });
}
