import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Button, Grid, Typography } from '@mui/material';

import FastTrackStyles from '../../styles/FastTrackStyles';
import { BaseDialog } from '../../components';

function ApprovalConfirmationDialog({
  classes,
  open,
  handleConfirm,
  handleClose,
  name,
  email,
  confirmDisabled,
}) {
  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      showContractIcon
      purpose="send for attorney approval"
      title="Request Attorney Approval"
    >
      <Grid container textAlign="center">
        <Grid item>
          <Typography variant="body2" className={`${classes.paper}`}>
            Requesting approval will email <b>{email}</b>. Are you sure you want to
            continue?
          </Typography>
        </Grid>
      </Grid>
      <Grid container item justifyContent="space-around">
        <Grid item>
          <Button onClick={handleClose} variant="outlined" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={confirmDisabled}
            onClick={() => handleConfirm({ name, email })}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
}

ApprovalConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  confirmDisabled: PropTypes.bool,
};

export default withStyles(FastTrackStyles)(ApprovalConfirmationDialog);
