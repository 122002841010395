import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Typography } from '@mui/material';

import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';

const PillButton = ({ classes, title = null, onClick = (f) => f }) => (
  <div className={`${classes.TrainTypePill} ${classes.TrainTypePillStatusGeneric}`}>
    <button type="submit" onClick={onClick}>
      <Typography
        variant="body1"
        className={`${classes.TrainTypePillText} ${classes.TrainTypePillTextSizeSmall}`}
      >
        {title}
      </Typography>
    </button>
  </div>
);

PillButton.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
};

export default withStyles(PlaybookBuilderStyles)(PillButton);
