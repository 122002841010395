import axios from 'axios';

import {
  TRAINING_ATTRIBUTES_ENDPOINT,
  TRAINING_CREATE_FROM_ZIP_ENDPOINT,
  TRAINING_PREVIEW_ENDPOINT,
} from '../endpoints.config';

export async function CreateFromZip(headers, id, data, file) {
  const fd = new FormData();
  fd.append('files', file);
  Object.entries(data).forEach(([k, v]) => fd.append(k, v));
  fd.append('model', id);
  const response = await axios.put(TRAINING_CREATE_FROM_ZIP_ENDPOINT, fd, {
    headers: { ...headers, 'Content-Type': 'multipart/form-data' },
  });
  if (response.status !== 200) {
    throw Error('Failed to create training data from zip');
  }
}

export function GetUniqueAttributes(headers, modelId) {
  return axios
    .get(`${TRAINING_ATTRIBUTES_ENDPOINT}/${modelId}`, { headers })
    .then((resp) => resp?.data)
    .catch((err) => {
      console.error(err);
      throw Error('Failed to get training attributes');
    });
}

export async function fetchEditPreviews(headers, trainingRuleIds) {
  try {
    const { data } = await axios.get(`${TRAINING_PREVIEW_ENDPOINT}`, {
      params: { trainingRuleIds },
      headers,
    });
    return data;
  } catch (err) {
    console.error(
      `Problem with fetching previews for trainingRuleIds ${trainingRuleIds}:`,
      err
    );
  }
}
