import { SET_SOCKET_ID } from '../action-types/globalEventTypes';

const initialState = {
  socketId: null,
};

function globalEventReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SOCKET_ID:
      return {
        ...state,
        socketId: action.payload,
      };
    default:
      return state;
  }
}

export default globalEventReducer;
