import React from 'react';
import PropTypes from 'prop-types';
import GeoService from '../../utils/GeoService';
import { Box } from '@mui/material';

function Location({ location, sx }) {
  const styles = {
    locationCategory: {
      color: 'rgba(0, 0, 0, 0.50)',
      fontSize: '1.2rem',
    },
  };

  const isCity = GeoService.isCity(location);
  const isCounty = GeoService.isCounty(location);

  return (
    <Box {...sx}>
      <Box>{location.name}</Box>
      {(isCounty || isCity) && (
        <Box sx={styles.locationCategory}>
          {location.adm_1?.name}, {location.adm_0?.Country}
        </Box>
      )}
    </Box>
  );
}

Location.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    adm_0: PropTypes.object,
    adm_1: PropTypes.object,
    adm_2: PropTypes.object,
  }),
};

export default Location;
