import { combineReducers } from 'redux';

import answerQuestionsReducer from './answerQuestionsReducer';
import fastTrackReducer from './fastTrackReducer';
import findSimilarReducer from './findSimilarReducer';
import globalEventReducer from './globalEventReducer';
import modelReducer from './modelReducer';
import onlyOfficeReducer from './onlyOfficeReducer';
import playbookBuilderReducer from './playbookBuilderReducer';
import poaReducer from './poaReducer';
import ruleReducer from './ruleReducer';
import snackbarReducer from './snackbarReducer';
import userFlowReducer from './userflowReducer';
import authReducer from './authReducer';

const combinedReducers = combineReducers({
  Model: modelReducer,
  PlaybookBuilder: playbookBuilderReducer,
  AnswerQuestions: answerQuestionsReducer,
  POA: poaReducer,
  Rule: ruleReducer,
  FastTrack: fastTrackReducer,
  OnlyOffice: onlyOfficeReducer,
  FindSimilar: findSimilarReducer,
  UserFlow: userFlowReducer,
  Snackbar: snackbarReducer,
  GlobalEvent: globalEventReducer,
  Auth: authReducer,
});

export default combinedReducers;
