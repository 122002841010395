import React, { useEffect } from 'react';

import { Grid, Typography } from '@mui/material';

import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { PageContent } from '../PageContent';

const ErrorFallback = (msg) => {
  const { sendErrorMetric } = useAuthUpdateContext();

  useEffect(() => {
    if (msg) {
      sendErrorMetric(msg?.error?.message, msg?.error?.stack);
    }
  }, [msg]);

  return (
    <PageContent>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Typography variant="h2">An unexpected error occurred.</Typography>
        </Grid>
      </Grid>
    </PageContent>
  );
};

export default ErrorFallback;
