import Colors from '../../styles/Colors';

const TooltipCustomStyles = () => ({
  tooltip: {
    color: Colors.trueWhite,
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
});

export default TooltipCustomStyles;
