export const ROUTE_PREFIX = '/bbweb';

export const HOME_ROUTE = `${ROUTE_PREFIX}/home`;
export const LOGIN_ROUTE = `${ROUTE_PREFIX}/login`;

// CLAUSES
export const CLAUSES_ROUTE = `${ROUTE_PREFIX}/clauses`;

// CONTRACTS
export const CONTRACTS_ROUTE = `${ROUTE_PREFIX}/contracts`;
export const CONTRACTS_NEW_ROUTE = `${CONTRACTS_ROUTE}/new`;
export const CONTRACTS_NEW_ARCHIVE_ROUTE = `${CONTRACTS_NEW_ROUTE}/archive`;

// USERS
export const USERS_ROUTE = `${ROUTE_PREFIX}/users`;
export const USERS_NEW_ROUTE = `${USERS_ROUTE}/new`;
export const USERS_EDIT_ROUTE = `${USERS_ROUTE}/edit`;

// SETTINGS
export const SETTINGS_ROUTE = `${ROUTE_PREFIX}/settings`;

// TENANTS
export const TENANTS_ROUTE = `${ROUTE_PREFIX}/tenants`;
export const TENANTS_NEW_ROUTE = `${TENANTS_ROUTE}/new`;

// MODEL
export const MODEL_ROUTE = `${ROUTE_PREFIX}/model`;
export const MODEL_EDIT_ROUTE = `${ROUTE_PREFIX}/model/edit`;

// DOCSERVER
export const DOCSERVER_ROUTE = `${ROUTE_PREFIX}/oo`;
export const DOCSERVER_READONLY_ROUTE = `${ROUTE_PREFIX}/readonly`;

// ANALYTICS
export const ANALYTICS_ROUTE = `${ROUTE_PREFIX}/analytics`;

// FAST TRACK
export const FAST_TRACK_ROUTE = `${ROUTE_PREFIX}/ft`;

// POA
export const POA_ROUTE = `${ROUTE_PREFIX}/poa`;

// REPORTING ROUTE
export const REPORTING_ROUTE = `${ROUTE_PREFIX}/reporting`;

// ACCOUNT
export const ACCOUNT_ROUTE = `${ROUTE_PREFIX}/account`;

// INTEGRATIONS
export const INTEGRATIONS_ROUTE = `${ROUTE_PREFIX}/integrations`;
