import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  TextField,
  Typography,
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';

import Colors from '../../styles/Colors';
import AdditiveListStyles from './styles';

const AdditiveList = ({
  options = [],
  radioOptions = [],
  radioKey = 'radio',
  textLabel = '',
  selectPlaceholder = 'Select an option...',
  textPlaceholder = 'Add text here...',
  selectLabelKey = 'name',
  tagLimit = 1,
  rowTextKey = 'text',
  rowSelectionsKey = 'selections',
  visible = true,
  rows,
  setRows,
  classes,
}) => {
  const handleAddRow = () => {
    if (radioOptions.length > 0) {
      const updated = [
        ...rows,
        { [rowTextKey]: '', [rowSelectionsKey]: [], [radioKey]: '' },
      ];
      setRows(updated);
    } else {
      const updated = [...rows, { [rowTextKey]: '', [rowSelectionsKey]: [] }];
      setRows(updated);
    }
  };

  const handleUpdateSelections = (idx, selections) => {
    const updated = [...rows];
    updated[idx][rowSelectionsKey] = selections;
    setRows(updated);
  };

  const handleUpdateText = (idx, text) => {
    const updated = [...rows];
    updated[idx][rowTextKey] = text;
    setRows(updated);
  };

  const handleChangeType = (idx, value) => {
    const updated = [...rows];
    updated[idx][radioKey] = value;
    setRows(updated);
  };

  const handleDeleteRow = (idx) => {
    const update = [...rows];
    update.splice(idx, 1);
    setRows(update);
  };

  return visible ? (
    <Grid container item direction="column" spacing={2}>
      {rows.length > 0 ? (
        <Grid item>
          {rows.map((row, rowIdx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={2}
              key={`input-row-${rowIdx}`}
            >
              <Grid item xs={6} className={classes.minHeight100}>
                <TextField
                  className={classes.alignBottom}
                  variant="standard"
                  label={textLabel}
                  value={row[rowTextKey]}
                  onChange={(evt) => handleUpdateText(rowIdx, evt.target.value)}
                  placeholder={textPlaceholder}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  limitTags={tagLimit}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      placeholder={
                        row[rowSelectionsKey].length === 0 ? selectPlaceholder : ''
                      }
                    />
                  )}
                  options={options}
                  getOptionLabel={(option) => option[selectLabelKey]}
                  value={row[rowSelectionsKey]}
                  onChange={(_, value) => handleUpdateSelections(rowIdx, value)}
                />
              </Grid>
              {radioOptions.length > 0 ? (
                <Grid item container>
                  <Grid item xs={10}>
                    <FormControl variant="standard" component="radio-array">
                      <RadioGroup
                        row
                        aria-label="radio-array-row"
                        name="text-type"
                        value={row[radioKey]}
                        onChange={(evt) => handleChangeType(rowIdx, evt.target.value)}
                      >
                        {radioOptions.map((option) => (
                          <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio color="primary" />}
                            label={
                              <Typography
                                className={classes.smallFont}
                                color="textPrimary"
                              >
                                {option}
                              </Typography>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={2}>
                    <Button
                      className={classes.delete}
                      onClick={() => handleDeleteRow(rowIdx)}
                      variant="outlined"
                      style={{ borderColor: Colors.brightRed }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          ))}
        </Grid>
      ) : null}
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <Button onClick={handleAddRow} variant="outlined" color="primary" fullWidth>
              <AddCircleIcon fontSize="large" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

AdditiveList.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array,
  radioOptions: PropTypes.array,
  radioKey: PropTypes.string,
  textLabel: PropTypes.string,
  selectPlaceholder: PropTypes.string,
  textPlaceholder: PropTypes.string,
  selectLabelKey: PropTypes.string,
  tagLimit: PropTypes.number,
  rowTextKey: PropTypes.string,
  rowSelectionsKey: PropTypes.string,
  visible: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired,
};

export default withStyles(AdditiveListStyles)(AdditiveList);
