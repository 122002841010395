import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import AccordionPaperStyles from './styles';

const AccordionPaper = ({ classes, title, children = null, headingTag = 'h2' }) => (
  <div className={classes.root}>
    <Accordion className={classes.content} data-cy={title}>
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant={headingTag}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.custom}>{children}</AccordionDetails>
    </Accordion>
  </div>
);

AccordionPaper.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  headingTag: PropTypes.string,
};

export default withStyles(AccordionPaperStyles)(AccordionPaper);
