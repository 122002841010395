const ModifyNumberObjectFieldsStyles = () => ({
  paperSetting: {
    backgroundColor: 'white',
    height: '25em',
  },
  sectionHeader: {
    marginBottom: '20px',
  },
  textField: {
    marginLeft: '20px',
    marginBottom: '10px',
    marginTop: '10px',
    width: '40%',
  },
  width40: {},
});

export default ModifyNumberObjectFieldsStyles;
