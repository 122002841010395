import React from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';

import GeoService from '../../utils/GeoService';
import Location from './Location';

function BBAutocomplete({ loading, ...props }) {
  return (
    <Autocomplete
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {GeoService.isGeo(option) ? (
            <Location location={option} />
          ) : (
            option.name || option.id
          )}
        </li>
      )}
    />
  );
}

export default BBAutocomplete;
