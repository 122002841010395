import { EditSimilar, FindSimilar } from './requests';
import { FIND_SIMILAR_GENERAL_SIMILARITY_THRESHOLD } from '../config';
import { setErrMsg, setSuccessMsg } from '../redux/actions/snackbarActions';

export const FindSimilarTextUtil = async (
  headers,
  sectionIdParam,
  model,
  tuid,
  text,
  textType,
  textSections,
  rule = null,
  limit = null
) => {
  let sectionForQuery = textSections.length > 0 ? textSections[0] : 'none';
  if (sectionIdParam) {
    sectionForQuery = sectionIdParam;
  }
  const reqData = {
    model,
    text,
    threshold: FIND_SIMILAR_GENERAL_SIMILARITY_THRESHOLD,
    section: sectionForQuery,
    text_type: textType,
    tuid,
    increment: 0.01,
  };
  if (limit) {
    reqData.limit = limit;
  }
  if (rule) {
    reqData.rule = rule;
  }
  return FindSimilar(headers, reqData)
    .then((data) => data)
    .catch((err) => {
      throw Error(`Failed to find similar text with error: ${err}`);
    });
};

export const findSimilarText = async (
  getAuthHeader,
  addSimilarTextData,
  dispatch,
  tuid,
  text,
  textType,
  textSections,
  sectionIdParam,
  modelId,
  ruleParam
) => {
  const data = await getAuthHeader().then((headers) =>
    FindSimilarTextUtil(
      headers,
      sectionIdParam,
      modelId,
      tuid,
      text,
      textType,
      textSections,
      ruleParam
    ).catch((err) => {
      console.log(err);
      return null;
    })
  );
  if (data) {
    dispatch(setSuccessMsg(`Found ${data.length} similar texts`));
    addSimilarTextData(data);
  } else {
    dispatch(setErrMsg('An error occurred'));
  }
};

export const EditSimilarTextUtil = async (
  headers,
  sectionIdParam,
  model,
  tuid,
  text,
  editedText,
  textType,
  textSections,
  rule = null,
  limit = null
) => {
  let sectionForQuery = textSections.length > 0 ? textSections[0] : 'none';
  if (sectionIdParam) {
    sectionForQuery = sectionIdParam;
  }
  const reqData = {
    model,
    text,
    edited_text: editedText,
    threshold: FIND_SIMILAR_GENERAL_SIMILARITY_THRESHOLD,
    section: sectionForQuery,
    text_type: textType,
    tuid,
  };
  if (limit) {
    reqData.limit = limit;
  }
  if (rule) {
    reqData.rule = rule;
  }
  return EditSimilar(headers, reqData)
    .then((data) => data)
    .catch((err) => {
      throw Error(`Failed to edit similar text with error: ${err}`);
    });
};
