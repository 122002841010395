import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';

import { BaseDialog } from '../BaseDialog';

const ConfirmRiskRoutingDialog = ({
  open,
  title,
  actionPrompt,
  handleCloseWithConfirmation,
  showContractIcon = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [isCc, setIsCc] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = () => {
    handleCloseWithConfirmation(false);
    setIsOpen(false);
    setIsCc(false);
  };

  const handleConfirm = () => {
    handleCloseWithConfirmation(true, isCc);
    setIsOpen(false);
    setIsCc(false);
  };

  return (
    <div>
      <BaseDialog
        open={isOpen}
        handleClose={handleClose}
        showContractIcon={showContractIcon}
        purpose="confirmation prompt"
        title={title}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <p> {actionPrompt} </p>
            <FormControlLabel
              control={
                <Checkbox value={isCc} onChange={() => setIsCc(!isCc)} color="primary" />
              }
              label={"Check this box to be cc'd on the email back to the Business User."}
              labelPlacement="end"
            />
          </Grid>
          <Grid container item justifyContent="space-around">
            <Grid item>
              <Button onClick={handleClose} variant="outlined" fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleConfirm}
                color="secondary"
                variant="contained"
                fullWidth
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </BaseDialog>
    </div>
  );
};

ConfirmRiskRoutingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  actionPrompt: PropTypes.string.isRequired,
  handleCloseWithConfirmation: PropTypes.func.isRequired,
  showContractIcon: PropTypes.bool,
};

export default ConfirmRiskRoutingDialog;
