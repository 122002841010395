import React, { useEffect, useState, useCallback, useReducer } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Checkbox,
  LinearProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';
import { findSimilarText } from '../../utils/FindSimilar';
import {
  clearFoundByBlackBoilerFromTextList,
  filterFXISearch,
  filterFXISection,
  filterTextByTypes,
  getUniqueSections,
  handleCheckBoxSelection,
  handleSelectAllCheckboxes,
} from '../../utils/PlaybookBuilderUtils';
import { GetTextBySearch, GetTextBySection } from '../../utils/requests';
import { FoundByBlackBoilerText } from '../FoundByBlackBoilerText';
import { PlaybookBuilderPage } from '../PlaybookBuilderPage';
import { SearchInput } from '../SearchInput';

const initialState = {};
const findSimilarReducer = (state, action) => {
  const { tuid, isLoading } = action;
  switch (isLoading) {
    case true:
      return { ...state, [tuid]: true };
    case false:
      return { ...state, [tuid]: false };
    default:
      throw new Error("what's going on?");
  }
};

const SectionTextSelection = ({
  classes,
  title,
  description,
  textData = [],
  onNextClick = (f) => f,
  onBackClick = (f) => f,
  currentStep = 1,
  totalSteps = 1,
  showHeader = true,
  showFooter = true,
  noMargin = false,
  selectable = true,
}) => {
  const { getAuthHeader } = useAuthUpdateContext();

  const dispatch = useDispatch();

  const { id: modelId } = useParams();

  const urlSearchParams = new URLSearchParams(useLocation().search);
  const sectionIdParam = urlSearchParams.get('sectionId') || '';
  const searchParam = urlSearchParams.get('search');
  const ruleParam = urlSearchParams.get('rule');

  const [dataOriginal, setDataOriginal] = useState(null);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [sections, setSections] = useState([]);

  const [findSimilarState, dispatchFn] = useReducer(findSimilarReducer, initialState);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    if (textData.length) {
      setDataFiltered(textData);
      setDataOriginal(textData);
      const allSections = textData.map((text) => text.sections).flat();
      setSections(getUniqueSections(allSections));
      setIsLoading(false);
    } else if (searchParam) {
      getAuthHeader().then((headers) => {
        GetTextBySearch(headers, searchParam, sectionIdParam, modelId, ruleParam).then(
          (data) => {
            setDataFiltered(data);
            setDataOriginal(data);
            const allSections = data.map((text) => text.sections).flat();
            setSections(getUniqueSections(allSections));
            setIsLoading(false);
          }
        );
      });
    } else if (sectionIdParam) {
      getAuthHeader().then((headers) => {
        GetTextBySection(headers, sectionIdParam, modelId, ruleParam).then((data) => {
          setDataFiltered(data);
          setDataOriginal(data);
          const allSections = data.map((text) => text.sections).flat();
          setSections(getUniqueSections(allSections));
          setIsLoading(false);
        });
      });
    }
  }, []);

  const [search, setSearch] = useState('');
  const [section, setSection] = useState('');
  const [textTypesFilter, setTextTypesFilter] = useState(['']);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllVisible, setSelectAllVisible] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [numberOfSelectedCheckboxes, setNumberOfSelectedCheckboxes] = useState(0);

  const applyFilters = (
    searchText = search,
    sectionId = section,
    textTypes = textTypesFilter
  ) => {
    let filtered = dataOriginal;
    if (searchText && searchText.length > 0) {
      filtered = filterFXISearch(filtered, searchText);
    }
    if (sectionId) {
      filtered = filterFXISection(filtered, sectionId);
    }
    if (textTypes.length > 1) {
      filtered = filterTextByTypes(
        filtered,
        textTypes.slice(1).map((type) => type.toLowerCase())
      );
    }

    setDataFiltered(filtered);
    setSelectAllVisible(false);
    setSelectAll(false);
  };

  useEffect(() => {
    if (dataOriginal) {
      applyFilters();
    }
  }, [dataOriginal]);

  const handleSectionChange = (sectionId) => {
    setSection(sectionId);
    applyFilters(search, sectionId);
  };

  const handleSearchChange = (searchText) => {
    if (!dataOriginal) {
      return;
    }
    applyFilters(searchText, section);
  };

  const handleTextTypesChange = (textTypes) => {
    setTextTypesFilter(textTypes);
    applyFilters(search, section, textTypes);
  };

  useEffect(() => {
    // Debounce searching
    const searchTimer = setTimeout(() => {
      handleSearchChange(search);
    }, 500);
    return () => {
      clearTimeout(searchTimer);
    };
  }, [search]);

  const [rowIncrementer, setRowIncrementer] = useState(20);
  const [rowsToShow, setRowsToShow] = useState(rowIncrementer);

  useEffect(() => {
    setRowsToShow(rowIncrementer);
  }, [rowIncrementer]);

  const getItemsForCurrentPage = useCallback(() => {
    if (rowsToShow < dataFiltered.length) {
      const currentItems = dataFiltered.slice(0, rowsToShow);
      return currentItems;
    }
    return dataFiltered;
  }, [rowsToShow, dataFiltered]);

  useEffect(() => {
    if (selectAll) {
      handleSelectAllCheckboxes(
        dataFiltered,
        selectAll,
        setSelectAll,
        selectAllVisible,
        setSelectAllVisible,
        setSelectedCheckboxes,
        true
      );
    } else if (selectAllVisible) {
      handleSelectAllCheckboxes(
        dataFiltered,
        selectAll,
        setSelectAll,
        selectAllVisible,
        setSelectAllVisible,
        setSelectedCheckboxes,
        true,
        rowsToShow
      );
    }
  }, [rowsToShow]);

  useEffect(() => {
    const checked = Object.values(selectedCheckboxes).filter((value) => value === true);
    setNumberOfSelectedCheckboxes(checked.length);
  }, [selectedCheckboxes]);

  const handleSelectAll = () => {
    handleSelectAllCheckboxes(
      dataFiltered,
      selectAll,
      setSelectAll,
      selectAllVisible,
      setSelectAllVisible,
      setSelectedCheckboxes
    );
  };

  const handleSelectAllVisible = () => {
    handleSelectAllCheckboxes(
      dataFiltered,
      selectAll,
      setSelectAll,
      selectAllVisible,
      setSelectAllVisible,
      setSelectedCheckboxes,
      false,
      rowsToShow
    );
  };

  const handleCheckboxToggle = (event) => {
    handleCheckBoxSelection(
      selectAll,
      setSelectAll,
      selectAllVisible,
      setSelectAllVisible,
      selectedCheckboxes,
      rowsToShow,
      setSelectedCheckboxes,
      dataFiltered.length,
      event
    );
  };

  const addSimilarTextData = (data) => {
    const dataTuids = new Set([...data.map(({ tuid }) => tuid)]);

    const cleanedDataOriginal = clearFoundByBlackBoilerFromTextList(dataOriginal).filter(
      (d) => !dataTuids.has(d.tuid)
    );
    const cleanedDataFiltered = clearFoundByBlackBoilerFromTextList(dataFiltered).filter(
      (d) => !dataTuids.has(d.tuid)
    );
    const combinedOriginalData = [...data, ...cleanedDataOriginal];
    const combinedDataFiltered = [...data, ...cleanedDataFiltered];
    setDataOriginal(combinedOriginalData);
    setDataFiltered(combinedDataFiltered);
    if (selectAll) {
      // new data not selected by default
      setSelectAll(false);
    }
  };

  const handleFindSimilar = (tuid, value, textType, textSections) => {
    dispatchFn({ tuid, isLoading: true });
    findSimilarText(
      getAuthHeader,
      addSimilarTextData,
      dispatch,
      tuid,
      value,
      textType,
      textSections,
      sectionIdParam,
      modelId,
      ruleParam
    ).then(() => {
      dispatchFn({ tuid, isLoading: false });
    });
  };

  const resetData = () => {
    setDataFiltered(dataOriginal);
    if (selectAll) {
      handleSelectAll();
    }
  };

  const backLink = () => {
    urlSearchParams.delete('sectionId');
    urlSearchParams.delete('search');
    onBackClick(urlSearchParams);
  };

  const nextLink = () => {
    const checkedIds = [];
    Object.entries(selectedCheckboxes).forEach(([id, isChecked]) => {
      if (isChecked) {
        checkedIds.push(id);
      }
    });
    const cleanedTextData = clearFoundByBlackBoilerFromTextList(dataFiltered);
    const dataSelected = cleanedTextData.filter((item) => checkedIds.includes(item.tuid));

    onNextClick(dataSelected, urlSearchParams);
  };

  return (
    <>
      <PlaybookBuilderPage
        title={title}
        extendedTitle=""
        description={description}
        currentStep={currentStep}
        totalSteps={totalSteps}
        rButtonDisabled={numberOfSelectedCheckboxes === 0}
        rButtonOnClick={nextLink}
        lButtonOnClick={backLink}
        middleBtnContent={
          <div className="col text-center">
            {`${numberOfSelectedCheckboxes} Sentence${numberOfSelectedCheckboxes === 1 ? '' : 's'} Selected`}
          </div>
        }
        wideContent
        noMargin={noMargin}
        showHeader={showHeader}
        showFooter={showFooter}
      >
        <div className={`container ${classes.fullWidth} ${classes.noMargin}`}>
          <SearchInput
            isLoading={isLoading}
            search={search}
            setSearch={setSearch}
            section={section}
            setSection={setSection}
            textTypes={textTypesFilter}
            setTextTypes={setTextTypesFilter}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            selectAllVisible={selectAllVisible}
            setSelectAllVisible={setSelectAllVisible}
            resultsPerPage={rowIncrementer}
            setResultsPerPage={setRowIncrementer}
            sections={sections}
            resetData={resetData}
            handleSelectAll={handleSelectAll}
            handleSelectAllVisible={handleSelectAllVisible}
            handleSectionChange={handleSectionChange}
            handleTextTypesChange={handleTextTypesChange}
            selectable={selectable}
          />
          <div className="row mt-2">
            <TableContainer>
              <Table>
                <TableBody>
                  {getItemsForCurrentPage().map(
                    (
                      {
                        value,
                        tuid,
                        type: textType,
                        sections: textSections,
                        foundByBlackBoiler,
                        score,
                      },
                      i
                    ) => (
                      <TableRow key={tuid} data-oddrow={!(i % 2 === 0)}>
                        {selectable ? (
                          <TableCell>
                            <Checkbox
                              color="primary"
                              name={tuid}
                              checked={selectedCheckboxes[tuid] === true}
                              onChange={handleCheckboxToggle}
                            />
                          </TableCell>
                        ) : null}
                        <TableCell>
                          {foundByBlackBoiler ? (
                            <FoundByBlackBoilerText score={score} />
                          ) : null}
                          <Typography variant="body1">{value}</Typography>
                          {findSimilarState[tuid] ? (
                            <LinearProgress color="secondary" />
                          ) : null}
                        </TableCell>
                        <TableCell>
                          <button
                            type="button"
                            onClick={() =>
                              handleFindSimilar(tuid, value, textType, textSections)
                            }
                            disabled={findSimilarState[tuid]}
                          >
                            <Typography variant="body1" className={classes.linkText}>
                              <SearchIcon
                                color="primary"
                                className="mr-2"
                                fontSize="large"
                              />
                              Find Similar
                            </Typography>
                          </button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isLoading ? <LinearProgress className={classes.fullWidth} /> : null}
          {dataOriginal ? (
            <div className="row align-items-center mt-3">
              <div className="col">
                <Typography variant="body1">
                  Showing{' '}
                  {rowsToShow < dataFiltered.length ? rowsToShow : dataFiltered.length} of{' '}
                  {dataFiltered.length} sentences
                </Typography>
              </div>
              <div className="col" />
              <div className="col">
                <Button
                  variant="outlined"
                  fullWidth
                  disabled={rowsToShow >= dataFiltered.length}
                  onClick={() => setRowsToShow(rowsToShow + rowIncrementer)}
                >
                  Show More
                </Button>
              </div>
              <div className="col" />
              <div className="col" />
            </div>
          ) : null}
        </div>
      </PlaybookBuilderPage>
    </>
  );
};

SectionTextSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  textData: PropTypes.array,
  onNextClick: PropTypes.func,
  onBackClick: PropTypes.func,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  noMargin: PropTypes.bool,
  selectable: PropTypes.bool,
};

export default withStyles(PlaybookBuilderStyles)(SectionTextSelection);
