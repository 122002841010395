import Colors from './Colors';

const YesNoStyles = () => ({
  radioButton: {
    borderWidth: 'unset',
    borderStyle: 'unset',
  },
  formControlLabel: {
    width: '40%',
    backgroundColor: Colors.grey1,
    height: 50,
    marginRight: 25,
    borderRadius: 10,
  },
  radioGroup: {
    marginTop: 20,
    marginLeft: 10,
  },
});

export default YesNoStyles;
