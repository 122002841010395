import {
  TRAIN_TYPES,
  EDIT_PREVIEW_TYPES,
  FXI_ALIGNMENT_MODE,
  TAIL_POSITIONS,
} from '../config';

/**
 * Utility function to facilitate UI preview of an edit.
 *
 * @param {object} trainingSentenceRecord  mongodb record in Training collection
 * @returns an preview definition in the form { previewType: [ NORMAL | TAIL ], payload:... }
 */
export function beforeAndAfterEdit(trainingSentenceRecord) {
  const {
    sentence,
    edited_sentence: editedSentence,
    train_type: trainType,
  } = trainingSentenceRecord;

  switch (trainType?.toUpperCase()) {
    case TRAIN_TYPES.EMPTY:
    case TRAIN_TYPES.POINT.toUpperCase():
      return {
        editPreviewType: EDIT_PREVIEW_TYPES.NORMAL,
        payload: [sentence, editedSentence],
      };
    case TRAIN_TYPES.FSI:
    case TRAIN_TYPES.FPI:
      return fxiEditByAlignmentMode(trainingSentenceRecord);
    case TRAIN_TYPES.FCD:
    case TRAIN_TYPES.FPD:
    case TRAIN_TYPES.FSD:
      return {
        editPreviewType: EDIT_PREVIEW_TYPES.NORMAL,
        payload: [sentence, ''],
      };
    default:
      return {
        editPreviewType: EDIT_PREVIEW_TYPES.UNSUPPORTED,
        payload: null,
      };
  }
}

/**
 * Get the correct preview metadata for an fxi depending on its alignment_mode
 *
 * @param {object} trainingSentenceRecord mongodb record in Training collection
 * @returns
 */
export function fxiEditByAlignmentMode(trainingSentenceRecord) {
  const {
    alignment_mode: alignmentMode,
    context_before_section_label: sectionLabel,
    sentence,
    edited_sentence: editedSentence,
    tail_type: tailType,
  } = trainingSentenceRecord;
  if (alignmentMode === FXI_ALIGNMENT_MODE.TAIL) {
    return {
      editPreviewType: EDIT_PREVIEW_TYPES.TAIL,
      payload: {
        tailType,
        contextBlurb: tailTypeBlurb(tailType, sectionLabel),
        insertedSentence: editedSentence,
      },
    };
  } else {
    const originalSentenceWithContext = sentenceWithContext(
      sentence,
      trainingSentenceRecord
    );
    const editedSentenceWithContext = sentenceWithContext(
      editedSentence,
      trainingSentenceRecord
    );
    return {
      editPreviewType: EDIT_PREVIEW_TYPES.NORMAL,
      payload: [originalSentenceWithContext, editedSentenceWithContext],
    };
  }
}

/**
 * Get text above preview to help with context.
 *
 * @param {string} tailType at what position after the text shall be inserted
 * @param {string} sectionLabel an identifier for the relative anchor text
 * @returns
 */
export function tailTypeBlurb(tailType, sectionLabel) {
  sectionLabel = sectionLabel || 'a';
  switch (tailType) {
    case TAIL_POSITIONS.DOCUMENT:
      return '⌄ Inserted at the end of the body of the document';
    case TAIL_POSITIONS.SECTION:
      return `⌄ Inserted after ${sectionLabel} section`;
    case TAIL_POSITIONS.PARAGRAPH:
      return `⌄ Inserted after ${sectionLabel} paragraph`;
    case TAIL_POSITIONS.SENTENCE:
      return `⌄ Inserted after ${sectionLabel} sentence`;
    case TAIL_POSITIONS.END:
      return `⌄ Inserted as the final text in the document`;
    default:
      return `⌄ Inserted after an unknown point in the document`;
  }
}

/**
 * Return a chunk of text with more context around it.
 *
 * @param {string} sentence the relevant text
 * @param {object} trainingSentenceRecord the mongodb record in the Training collection
 * @param {object} trainingSentenceRecord.alignment_mode the type of context
 * @param {object} trainingSentenceRecord.context_before text before relevant text
 * @param {object} trainingSentenceRecord.context_following text after relevant text
 * @returns
 */
export function sentenceWithContext(
  sentence,
  {
    alignment_mode: alignmentMode,
    context_before: contextBefore,
    context_following: contextFollowing,
  }
) {
  contextBefore = contextBefore || '...context unavailable...';
  contextFollowing = contextFollowing || '...context unavailable...';
  switch (alignmentMode) {
    case FXI_ALIGNMENT_MODE.CONTEXT_BEFORE:
      return `${contextBefore} ${sentence}`;
    case FXI_ALIGNMENT_MODE.CONTEXT_FOLLOWING:
      return `${sentence} ${contextFollowing}`;
    case FXI_ALIGNMENT_MODE.FULL_CONTEXT:
      return `${contextBefore} ${sentence} ${contextFollowing}`;
    case FXI_ALIGNMENT_MODE.TAIL:
    case FXI_ALIGNMENT_MODE.NONE:
    default:
      return sentence;
  }
}
