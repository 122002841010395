import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import MenuIcon from '@mui/icons-material/Menu';
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';

import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import {
  CONTRACTS_PERMISSION,
  DOWNLOAD_CONTRACT_PERMISSION,
  // METRICS_DASHBOARD_PERMISSION,
  // METRICS_FASTTRACK_PERMISSION,
  CLAUSE_EDITOR_PERMISSION,
} from '../../groups.config';
import { DOCSERVER_ROUTE, DOCSERVER_READONLY_ROUTE } from '../../routes.config';
import { useGridContext } from '../../utils/GridProvider';

const GridCellPop = ({ mode }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const {
    isActiveTab,
    setOpenDelete,
    setOpenEdit,
    setOpenArchive,
    setOpenClause,
    setOpenDownload,
    setOpenContractLog,
    dataRefContext,
    activeRow,
  } = useGridContext();

  const { permissions } = useAuthUpdateContext();

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
    handleClose();
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
    handleClose();
  };

  const handleOpenArchive = () => {
    setOpenArchive(true);
    handleClose();
  };

  const handleOpenClause = () => {
    setOpenClause(true);
    handleClose();
  };

  const handleOpenDocumentServer = () => {
    setRedirect(true);
    handleClose();
  };

  const handleDownloadContract = () => {
    setOpenDownload(true);
    handleClose();
  };

  const handleOpenContractLog = () => {
    setOpenContractLog(true);
    handleClose();
  };

  const contractsContent = [
    {
      label: 'View Document',
      handler: handleOpenDocumentServer,
      permissions: permissions.has(CONTRACTS_PERMISSION),
    },
    {
      label: 'Edit Info',
      handler: handleOpenEdit,
      permissions: permissions.has(CONTRACTS_PERMISSION),
    },
    {
      label: 'Download',
      handler: handleDownloadContract,
      permissions:
        permissions.has(CONTRACTS_PERMISSION) &&
        permissions.has(DOWNLOAD_CONTRACT_PERMISSION),
    },
    // { label: 'Reports', handler: handleClose, permissions: (permissions.has(METRICS_DASHBOARD_PERMISSION) || permissions.has(METRICS_FASTTRACK_PERMISSION)) },
    // { label: 'View Edit Metrics', handler: handleClose, permissions: (permissions.has(METRICS_DASHBOARD_PERMISSION) || permissions.has(METRICS_FASTTRACK_PERMISSION)) },
    {
      label: isActiveTab ? 'Activate' : 'Archive',
      handler: handleOpenArchive,
      permissions: permissions.has(CONTRACTS_PERMISSION),
    },
    {
      label: 'Delete',
      handler: handleOpenDelete,
      permissions: permissions.has(CONTRACTS_PERMISSION),
    },
    {
      label: 'Contract Log',
      handler: handleOpenContractLog,
      permissions: permissions.has(CONTRACTS_PERMISSION),
    },
  ];

  const clausesContent = [
    {
      label: 'Edit Clause',
      handler: handleOpenClause,
      permissions: permissions.has(CLAUSE_EDITOR_PERMISSION),
    },
    {
      label: 'Delete Clause',
      handler: handleOpenDelete,
      permissions: permissions.has(CLAUSE_EDITOR_PERMISSION),
    },
  ];

  const setContent = () => {
    switch (mode) {
      case 'contracts':
        return contractsContent;
      case 'clauses':
        return clausesContent;
      default:
        return [];
    }
  };

  const [menuContent] = useState(setContent());

  if (redirect) {
    const {
      _id: contractId,
      path,
      storageState,
    } = dataRefContext.current.ungroupedData.filter((_, i) => i === activeRow)[0];
    const fileType = path.split('/').at(-1).split('.').at(-1);

    if (storageState === 'archived' || fileType?.toLowerCase() === 'pdf') {
      return <Navigate to={`${DOCSERVER_READONLY_ROUTE}?contract=${contractId}`} />;
    }
    return <Navigate to={`${DOCSERVER_ROUTE}?contract=${contractId}`} />;
  }

  return (
    <div data-cy="contractOptions">
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuContent.map(({ label, handler, permissions: roles }) =>
          roles ? (
            <MenuItem key={label} onClick={handler}>
              {label}
            </MenuItem>
          ) : null
        )}
      </Menu>
    </div>
  );
};

GridCellPop.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default GridCellPop;
