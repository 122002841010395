import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Grid, Icon, Popover, Typography } from '@mui/material';

import TargetSvg from '../../assets/svg/target.svg';
import GridCellBullseyeStyles from './styles';

const GridCellBullseye = ({ classes, data }) => {
  const [model, setModel] = useState();
  const [contractName, setContractName] = useState();

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const { modelName = 'UNKNOWN', singleContractModelContractId } = data;
    setModel(modelName.toUpperCase());

    if (singleContractModelContractId) {
      const { name } = singleContractModelContractId;
      setContractName(name);
    } else {
      setContractName(null);
    }
  }, [data]);

  return (
    <Grid container spacing={1} alignItems="center">
      {contractName && model ? (
        <>
          <Grid item>{model}</Grid>
          <Grid item>
            <Icon
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <img
                src={TargetSvg}
                alt="bullseye icon"
                className={classes.icon}
                data-cy="bullseyeIcon"
              />
            </Icon>
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography>{contractName}</Typography>
            </Popover>
          </Grid>
        </>
      ) : (
        <Grid item>{model}</Grid>
      )}
    </Grid>
  );
};

GridCellBullseye.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(GridCellBullseyeStyles)(GridCellBullseye);
