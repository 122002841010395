import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import CustomStepIconStyles from './styles';

const CustomStepIcon = ({ classes }) => (
  <div className={`${classes.root} ${classes.active}`}>
    <div className={classes.circle} />
  </div>
);

CustomStepIcon.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(CustomStepIconStyles)(CustomStepIcon);
