const POAStyles = () => ({
  datePicker: {
    width: '100%',
    height: 40,
    padding: 5,
    borderRadius: 5,
    borderStyle: 'dotted',
  },
  dropzone: {
    height: 100,
    width: 100,
  },
  bold: {
    fontWeight: 'bold',
  },
  underline: {
    textDecoration: 'underline',
  },
  outerContainer: {
    margin: 25,
  },
  autocompleteCompact: {
    padding: '2px 16px !important',
  },
});

export default POAStyles;
