import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { TextField, TextareaAutosize } from '@mui/material';

import ModifyJSONFieldsStyles from './styles';

const ModifyJSONFields = ({
  classes,
  label,
  initialValue,
  placeholder = null,
  fieldName,
  updateFieldData,
  addUpdatedFieldName = (f) => f,
  setErrors,
  errors,
  setContainsError = (f) => f,
}) => {
  const [value, setValue] = useState(JSON.stringify(initialValue));

  const handleUpdateObject = (e) => {
    setValue(e.target.value);
    addUpdatedFieldName(fieldName);
  };

  const isValidSubmission = (val) => {
    let isValid = true;
    try {
      JSON.parse(val);
      setErrors((errs) => ({
        ...errs,
        [fieldName]: false,
      }));
    } catch (e) {
      // show error when invalid JSON
      setErrors((errs) => ({
        ...errs,
        [fieldName]: 'Field contains invalid JSON.',
      }));
      isValid = false;
    }

    setContainsError(!isValid);

    return isValid;
  };

  useEffect(() => {
    const valueChangedTimer = setTimeout(() => {
      if (isValidSubmission(value)) {
        updateFieldData(JSON.parse(value), fieldName);
      }
    }, 500);
    return () => {
      clearTimeout(valueChangedTimer);
    };
  }, [value]);

  return (
    <div key={`modify-json-fields-${label}`}>
      <h5> {label} </h5>
      <TextField
        key={`modify-json-fields-label-${label}`}
        className={classes.textField}
        placeholder={placeholder}
        onChange={handleUpdateObject}
        value={value}
        variant="outlined"
        error={errors[fieldName]}
        helperText={errors[fieldName]}
        InputProps={{
          inputComponent: TextareaAutosize,
          inputProps: {
            minRows: 3,
            maxRows: 15,
          },
        }}
      />
    </div>
  );
};

ModifyJSONFields.propTypes = {
  classes: PropTypes.object.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  updateFieldData: PropTypes.func.isRequired,
  addUpdatedFieldName: PropTypes.func,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setContainsError: PropTypes.func,
};

export default withStyles(ModifyJSONFieldsStyles)(ModifyJSONFields);
