import {
  SET_PRESENCE_THRESHOLD_DATA,
  SET_CONTEXT_THRESHOLD_DATA,
  CLEAR_FIND_SIMILAR_REDUX_STORE,
} from '../action-types/findSimilarTypes';

const initialState = {
  presenceThresholdData: null,
  contextThresholdData: null,
};

function findSimilarReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PRESENCE_THRESHOLD_DATA:
      return {
        ...state,
        presenceThresholdData: action.payload,
      };
    case SET_CONTEXT_THRESHOLD_DATA:
      return {
        ...state,
        contextThresholdData: action.payload,
      };
    case CLEAR_FIND_SIMILAR_REDUX_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default findSimilarReducer;
