// TODO: Combine with /components/Header/styles.js after components directly restructure

const HeaderStyles = () => ({
  wrapper: {
    height: 50,
    paddingLeft: 12,
  },
  primaryLink: {
    height: '100%',
    color: 'white',
    fontSize: '1.5rem',
    letterSpacing: 0,
    fontWeight: 400,
    fontStyle: 'normal',
    borderRadius: 0,
    textTransform: 'none',
  },
  returnLink: {
    cursor: 'pointer',
    display: 'inline',
    color: 'white',
    marginRight: 15,
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  endCrumb: {
    fontWeight: 700,
  },
});

export default HeaderStyles;
