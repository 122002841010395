import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Grid, Button } from '@mui/material';

import { PageContent } from '../PageContent';
import PlaybookBuilderOptionsHeader from './PlaybookBuilderOptionsHeader';
import PlaybookBuilderPageStyles from './PlaybookBuilderPageStyles';

const PlaybookBuilderPage = ({
  classes,
  children = null,
  title,
  extendedTitle,
  description,
  lButtonOnClick = (f) => f,
  lButtonText = 'Back',
  rButtonOnClick = (f) => f,
  rButtonText = 'Next',
  currentStep = null,
  totalSteps = null,
  basicStylingOnly = true,
  imageContent = null,
  showTopNav = true,
  showHeader = true,
  showFooter = true,
  showLButton = true,
  showRButton = true,
  rButtonDisabled = false,
  lButtonDisabled = false,
  middleBtnContent = null,
  wideContent = false,
  noMargin = false,
}) => {
  const adjustMarginWidth = (width) => {
    if (noMargin) {
      return false;
    }
    return wideContent ? width - 1 : width;
  };

  const adjustWidth = (width) => {
    if (noMargin) {
      return 12;
    }
    return wideContent ? width + 2 : width;
  };

  const buttonsGrid = (
    <Grid item xs={adjustWidth(8)}>
      <Grid container justifyContent="space-between">
        <Grid item>
          {showLButton ? (
            <Button
              variant="outlined"
              onClick={lButtonOnClick}
              disabled={lButtonDisabled}
            >
              <KeyboardBackspaceIcon className="mr-2" fontSize="large" />
              {lButtonText}
            </Button>
          ) : null}
        </Grid>
        {middleBtnContent}
        <Grid item>
          {showRButton ? (
            <Button
              color="primary"
              variant="contained"
              onClick={rButtonOnClick}
              disabled={rButtonDisabled}
            >
              {rButtonText}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      {showTopNav ? (
        <>
          <Grid container sx={{ marginTop: '1.5rem' }}>
            <Grid item xs={adjustMarginWidth(2)} />
            {buttonsGrid}
          </Grid>
        </>
      ) : null}

      {showHeader ? (
        <Grid container>
          <Grid item xs={adjustMarginWidth(2)} />
          <Grid item xs={adjustWidth(imageContent ? 5 : 7)}>
            <PlaybookBuilderOptionsHeader
              title={title}
              extendedTitle={extendedTitle}
              description={description}
              currentStep={currentStep}
              totalSteps={totalSteps}
              basicStylingOnly={basicStylingOnly}
            />
          </Grid>
        </Grid>
      ) : null}

      <PageContent>
        <Grid container spacing={imageContent ? 2 : 0} sx={{ mb: 4 }}>
          <Grid item xs={adjustMarginWidth(2)} />
          <Grid item xs={adjustWidth(imageContent ? 5 : 8)}>
            {children}
          </Grid>
          {imageContent ? (
            <Grid item xs={adjustMarginWidth(2)}>
              <img src={imageContent} alt="" />
            </Grid>
          ) : null}
        </Grid>

        {showFooter ? (
          <>
            <div className={classes.footer}>
              <Grid container>
                <Grid item xs={adjustMarginWidth(2)} />
                {buttonsGrid}
              </Grid>
            </div>
          </>
        ) : null}
      </PageContent>
    </>
  );
};

PlaybookBuilderPage.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  extendedTitle: PropTypes.string,
  description: PropTypes.string,
  lButtonOnClick: PropTypes.func,
  lButtonText: PropTypes.string,
  rButtonOnClick: PropTypes.func,
  rButtonText: PropTypes.string,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  basicStylingOnly: PropTypes.bool,
  imageContent: PropTypes.any,
  showTopNav: PropTypes.bool,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  showLButton: PropTypes.bool,
  showRButton: PropTypes.bool,
  rButtonDisabled: PropTypes.bool,
  lButtonDisabled: PropTypes.bool,
  middleBtnContent: PropTypes.any,
  wideContent: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export default withStyles(PlaybookBuilderPageStyles)(PlaybookBuilderPage);
