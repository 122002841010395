import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Typography, TableCell, TableRow } from '@mui/material';

const ThresholdPreviewRow = React.memo(
  ({
    id,
    text,
    score,
    positiveInsert = false,
    insertText = 'Your text would be inserted',
    rejectText = 'Your text would NOT be inserted',
    classes,
  }) => (
    <TableRow id={id} key={id}>
      <TableCell className={classes.insertTextCell}>
        <Typography variant="body1">{text}</Typography>
      </TableCell>
      <TableCell className={classes.insertScoreCell}>
        <Typography variant="body1">{(score * 100).toFixed(2)}%</Typography>
      </TableCell>
      <TableCell>
        <div
          className={`${positiveInsert ? classes.insertTextGreen : classes.insertTextRed}`}
        >
          {positiveInsert ? <CheckCircleIcon /> : <NotInterestedIcon />}
          <Typography>{positiveInsert ? insertText : rejectText}</Typography>
        </div>
      </TableCell>
    </TableRow>
  )
);

ThresholdPreviewRow.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  positiveInsert: PropTypes.bool,
  insertText: PropTypes.string,
  rejectText: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default ThresholdPreviewRow;
