import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import userflow from 'userflow.js';

import { useAuthUpdateContext } from './auth/AuthUpdateProvider';
import {
  SetUserFlowIsEnabled,
  SetUserFlowToken,
  SetUserHash,
} from './redux/actions/userflowActions';
import { GetUserHash } from './utils/requests';

const UserFlowManager = ({ children = null }) => {
  const { getAuthHeader, config } = useAuthUpdateContext();

  const dispatch = useDispatch();

  useEffect(() => {
    if (config && config.userFlowEnabled && config.userflowToken) {
      getAuthHeader().then((headers) => {
        GetUserHash(headers)
          .then((data) => {
            if (data && Object.keys(data).length > 0) {
              const { hash } = data;

              dispatch(SetUserHash(hash));
              dispatch(SetUserFlowIsEnabled(config.userFlowEnabled));
              dispatch(SetUserFlowToken(config.userflowToken));

              userflow.init(config.userflowToken);
              userflow
                .identify(hash)
                .then(() => console.log('Connected to userflow'))
                .catch((err) =>
                  console.log('Failed to identify user with userflow with error:', err)
                );
              userflow
                .group(window.location.hostname.split('.')[0])
                .then(() => 'Userflow group initialized')
                .catch((err) =>
                  console.log('Failed to assign userflow group with error:', err)
                );
            }
          })
          .catch((err) =>
            console.log(
              'Failed to retrieve user information tracking data with error:',
              err
            )
          );
      });
    }
  }, [config]);

  return { ...children };
};

UserFlowManager.propTypes = {
  children: PropTypes.any,
};

export default UserFlowManager;
