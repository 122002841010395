import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setErrMsg } from '../../redux/actions/snackbarActions';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { getRedirect } from '../../auth/AuthUtils';
import * as integrationsRequests from '../../requests/integrations';
import { BaseDialogPage } from '../../components';

const OnboardingIntegration = ({ showOnboarding, setShowThankYou }) => {
  const [webHost, setWebHost] = useState(null);

  const { getAuthHeader } = useAuthUpdateContext();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const setErr = (value) => dispatch(setErrMsg(value));

  async function startAuth() {
    const headers = await getAuthHeader();
    try {
      const { data: authResp } = await integrationsRequests.authorizeUser(
        headers,
        { id: 'lawvu', webHost },
        true,
        true
      );
      const { authUri } = authResp;

      if (authUri) {
        console.debug(`Third party auth for integration navigating to ${authUri}`);
        window.location.href = authUri;
      } else {
        throw new Error(`Destination URI not present in response`);
      }
      return authResp;
    } catch (err) {
      console.error(err);
      setErr('Failed to authorize');
    }
  }

  async function handleRegisterIntegration(queryMap) {
    const headers = await getAuthHeader();
    try {
      console.debug(`Attempting to register integration...`);
      const { data: registerResp } = await integrationsRequests.registerIntegration(
        headers,
        queryMap
      );
      console.debug(`Register integration responded with:`);
      console.debug(registerResp);
      setShowThankYou(true);
    } catch (err) {
      console.error(err);
      setErr('Failed to authorize user via code flow');
    }
  }

  function checkForCodeFlow() {
    const { queryMap } = getRedirect(search);

    if (queryMap.state && queryMap.icode) {
      handleRegisterIntegration(queryMap);
    }
  }

  async function loadIntegration() {
    const headers = await getAuthHeader();
    const { data: integration } = await integrationsRequests.getIntegration(
      headers,
      'lawvu'
    );

    if (!integration) {
      return null;
    }

    setWebHost(integration.fields.webHost);
  }

  useEffect(() => {
    loadIntegration();
    checkForCodeFlow();
  }, []);

  return (
    <BaseDialogPage
      extendedTitle={`${showOnboarding ? 'Almost done, c' : 'C'}lick authorize to setup your LawVu integration now`}
      rButtonText="Authorize"
      rButtonOnClick={() => startAuth()}
      showLButton={false}
      showTopNav={false}
    />
  );
};

OnboardingIntegration.propTypes = {
  showOnboarding: PropTypes.bool,
  setShowThankYou: PropTypes.func,
};

export default OnboardingIntegration;
