import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Grid, Link } from '@mui/material';

import { DOCSERVER_ROUTE, DOCSERVER_READONLY_ROUTE } from '../../routes.config';
import GridCellLinkStyles from './styles';

const GridCellLink = ({ classes, data }) => {
  const [title, setTitle] = useState('');
  const [contractId, setContractId] = useState('');
  const [fileType, setFileType] = useState('');
  const [isActive, setIsActive] = useState('');

  useEffect(() => {
    const { _id: id, name, storageState, path } = data;
    setIsActive(storageState === 'active');
    setTitle(name);
    setContractId(id);
    setFileType(path.split('/').at(-1).split('.').at(-1));
  }, [data]);

  return (
    <Grid container spacing={1}>
      <Grid item className={`${classes.text} ${classes.link}`}>
        {/* Route active contracts with fileType pdf */}
        <Link
          component={NavLink}
          to={
            !isActive || fileType?.toLowerCase() === 'pdf'
              ? `${DOCSERVER_READONLY_ROUTE}?contract=${contractId}`
              : `${DOCSERVER_ROUTE}?contract=${contractId}`
          }
          underline="hover"
        >
          {title}
        </Link>
      </Grid>
    </Grid>
  );
};

GridCellLink.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(GridCellLinkStyles)(GridCellLink);
