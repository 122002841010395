import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';

import GridCellTimeStyles from './styles';

const GridCellTime = ({ classes, value, contract, action }) => {
  // TODO: add time in as well ==> hh:mm:ss
  const time = moment(value).format('MMM DD, YYYY h:mm a');

  let user = '';
  if (action === 'modified' && contract.modifiedBy) {
    const {
      modifiedBy: { email = '', username = '' },
    } = contract;
    user = email || username;
  } else {
    const {
      uploadUser: { email = '', username = '' },
    } = contract;
    user = email || username;
  }

  return (
    <Grid container direction="column">
      <Grid item>{time}</Grid>
      <Grid item className={classes.user}>
        {user}
      </Grid>
    </Grid>
  );
};

GridCellTime.propTypes = {
  classes: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default withStyles(GridCellTimeStyles)(GridCellTime);
