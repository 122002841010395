import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';
import Auth from '@aws-amplify/auth';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';

import { LOGIN_ROUTE } from './routes.config';
import { setUser } from './redux/actions/authActions';
import { LoginContent } from './components';

const integrationCallbackPaths = {
  '/bbweb/integrations/lawvu': {
    codeParam: 'code',
    newCodeParam: 'icode',
  },
};

const UserInjector = withAuthenticator(({ user, children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setUser(user?.username));
  }, []);
  return children;
});

const AuthProvider = ({
  login,
  onRedirectCallback = (f) => f,
  configNA = {},
  children = null,
}) => {
  const [isDoneFetchingUser, setIsDoneFetchingUser] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const decodedHref = decodeURIComponent(window.location.href.replace(/\+/g, ' '));
  const url = new URL(decodedHref);
  const decodedParams = new URLSearchParams(url.search);
  const codeParam = decodedParams.get('code');
  const { pathname } = window.location;
  const integration = integrationCallbackPaths?.[pathname];

  if (codeParam && integration) {
    // hacky, but due to AWS Cognito swallowing the 'code' parameter in query strings, replace it with a different key
    // use this key to extract the code query param from integration callbacks
    window.location.href = window.location.href.replace(
      `${integration.codeParam}=`,
      `${integration.newCodeParam}=`
    );
  }

  useEffect(() => {
    if (!configNA) {
      return;
    }

    switch (configNA.provider) {
      case 'aws':
        Amplify.configure({
          // (required)- Amazon Cognito Region
          region: configNA.awsRegion,
          // (optional) - Amazon Cognito User Pool ID
          userPoolId: configNA.awsUserPoolId,
          // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
          userPoolWebClientId: configNA.awsUserPoolWebClientId,
          // (optional) - Hosted UI configuration
          oauth: {
            domain: configNA.domain,
            scope: ['openid', 'profile', 'email', 'aws.cognito.signin.user.admin'],
            redirectSignIn: `${window.location.origin}/bbweb/home`,
            redirectSignOut: `${window.location.origin}/bbweb/login`,
            clientId: configNA.awsUserPoolWebClientId,
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
          },
        });
        if (!isLoggedIn) {
          Auth.currentAuthenticatedUser()
            .then(() => {
              setIsLoggedIn(true);
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              setIsDoneFetchingUser(true);
            });
        }
        break;

      default:
        break;
    }
  }, [configNA]);

  useEffect(() => {
    if (isDoneFetchingUser && !isLoggedIn) {
      // Store pathname to redirect from home page after login
      const pathName = window.location.pathname;
      if (!['/', '/bbweb/login', '/bbweb/home'].includes(pathName)) {
        localStorage.setItem('redirectPath', pathName);
      }
    }
  }, [isDoneFetchingUser]);

  if (!configNA) {
    return null;
  }

  switch (configNA.provider) {
    case 'aws':
      if (!isDoneFetchingUser) {
        return null;
      }

      return isLoggedIn ? (
        <Authenticator>
          <UserInjector>{children}</UserInjector>
        </Authenticator>
      ) : (
        <LoginContent
          onClick={login}
          logoUrl={configNA.companyLogo}
          provider={configNA.provider}
          directIdpTitle={configNA.directIdpTitle}
          directIdpName={configNA.directIdpName}
          showPasswordReset={configNA.showPasswordReset}
          customSiteText={configNA.customSiteText}
        />
      );
    case 'auth0':
      return (
        <Auth0Provider
          domain={configNA.domain}
          clientId={configNA.clientId}
          redirectUri={`${window.location.origin}${LOGIN_ROUTE}`}
          onRedirectCallback={onRedirectCallback}
          audience={configNA.audience}
        >
          {children}
        </Auth0Provider>
      );
    default:
      return (
        <LoginContent
          onClick={login}
          logoUrl={configNA.companyLogo}
          provider={configNA.provider}
          customSiteText={configNA.customSiteText}
        />
      );
  }
};

AuthProvider.propTypes = {
  login: PropTypes.func.isRequired,
  onRedirectCallback: PropTypes.func,
  configNA: PropTypes.object,
  children: PropTypes.any,
};

export default AuthProvider;
