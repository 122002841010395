import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import withStyles from '@mui/styles/withStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Grid, Menu, MenuItem, Typography } from '@mui/material';

import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { defaultReviewStates } from '../../config';
import { CONTRACTS_ENDPOINT } from '../../endpoints.config';
import { logDocumentExport } from '../../utils/OnlyOfficeUtils';
import { ConfirmRiskRoutingDialog } from '../ConfirmRiskRoutingDialog';
import GridCellStatusStyles from './styles';

const GridCellStatus = ({ classes, contract }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [id, setId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [storedEvent, setStoredEvent] = useState();
  const [targetEmail, setTargetEmail] = useState();

  const { uploadStage } = contract;

  const { getAuthHeader, config: companyConfig } = useAuthUpdateContext();
  const reviewStates =
    companyConfig && companyConfig.reviewStates.length > 0
      ? companyConfig.reviewStates
      : defaultReviewStates;

  useEffect(() => {
    const { _id: updatedId, custodyLabel: custody } = contract;

    setId(updatedId);
    reviewStates.forEach(({ name }, index) => {
      if (custody?.toLowerCase() === name?.toLowerCase()) {
        setSelectedIndex(index);
      }
    });
  }, [contract]);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSubmit(event, index, isCC = false) {
    const custodyLabel = reviewStates[index].name;
    const custodyAction = reviewStates[index].action;
    getAuthHeader().then((headers) => {
      const body = { ...contract, custodyLabel, isCC };
      axios
        .put(`${CONTRACTS_ENDPOINT}${id}`, body, { headers })
        .then(() => {
          if (
            custodyAction === 'returnemail' &&
            companyConfig &&
            companyConfig.enableRiskRouting
          ) {
            axios
              .put(`${CONTRACTS_ENDPOINT}${id}/mail`, body, { headers })
              .then(() => {
                logDocumentExport(headers, id);
              })
              .catch((e) => {
                console.log(e);
              });
          }
          setSelectedIndex(index);
          handleClose();
        })
        .catch((e) => {
          // TODO: Handle error and loading with popover
          console.log(e);
        });
    });
  }

  const handleMenuItemClick = (event, index) => {
    if (
      reviewStates[index].action === 'returnemail' &&
      companyConfig &&
      companyConfig.enableRiskRouting
    ) {
      const {
        uploadUser: { email },
      } = contract;
      setTargetEmail(email);
      setStoredEvent([event, index]);
      setIsOpen(true);
      return null;
    }
    return handleSubmit(event, index);
  };

  const handleCloseDialog = (isConfirmed, isCC = false) => {
    setIsOpen(false);
    setAnchorEl(null);

    if (isConfirmed && storedEvent) {
      const [event, index] = storedEvent;
      return handleSubmit(event, index, isCC);
    }

    return null;
  };

  const renderStatus = (params) => {
    switch (params) {
      case 'init':
        return <Typography className={classes.statusText}>Pending</Typography>;
      case 'siml':
        return (
          <>
            <Typography className={classes.statusText}>
              Comparing this contract
            </Typography>
            <Typography className={classes.statusText}>to other contracts...</Typography>
          </>
        );
      case 'sugg':
        return (
          <Typography className={classes.statusText}>Making suggestions...</Typography>
        );
      case 'clfy':
        return (
          <Typography className={classes.statusText}>
            Classifying the contract...
          </Typography>
        );
      case 'slts':
        return (
          <>
            <Typography className={classes.statusText}>Slot prediction</Typography>
            <Typography className={classes.statusText}>for clause library...</Typography>
          </>
        );
      case 'done': {
        return (
          <>
            <Button
              aria-controls="status-menu"
              aria-haspopup="true"
              onClick={handleClick}
              size="medium"
              variant="outlined"
              color={selectedIndex ? 'secondary' : 'primary'}
              className={classes.statusBtn}
            >
              <span
                className={`
                              ${classes.dot}
                              ${selectedIndex ? classes.redDot : ''}
                              ${selectedIndex > 1 ? classes.blackDot : ''}
                          `}
              />
              {selectedIndex ? reviewStates[selectedIndex].label : reviewStates[0].label}
            </Button>
            <ArrowDropDownIcon />
          </>
        );
      }
      case 'dlte':
        return <Typography className={classes.statusText}>Removing...</Typography>;
      default:
        return (
          <Typography className={classes.statusText}>
            Error processing document
          </Typography>
        );
    }
  };

  return (
    <div>
      <Grid container alignItems="center" justifyContent="flex-start">
        <Grid item>{renderStatus(uploadStage)}</Grid>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {reviewStates.map(({ label }, index) => (
          <MenuItem
            key={label}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {label}
            <span
              className={`
                            ${classes.dot}
                            ${classes.menuDot}
                            ${label === 'With Counterparty' ? classes.redDot : ''}
                            ${label !== 'With Counterparty' && label !== 'Under Review' ? classes.blackDot : ''}
                        `}
            />
          </MenuItem>
        ))}
      </Menu>
      <ConfirmRiskRoutingDialog
        open={isOpen}
        title="Confirm Status Change"
        actionPrompt={`Are you sure you want to send this contract back to ${targetEmail || 'the email address of the user who uploaded it'}?`}
        handleCloseWithConfirmation={handleCloseDialog}
        showContractIcon
      />
    </div>
  );
};

GridCellStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
};

export default withStyles(GridCellStatusStyles)(GridCellStatus);
