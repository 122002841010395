import React from 'react';
import PropTypes from 'prop-types';
import { diffWordsWithSpace } from 'diff';

import withStyles from '@mui/styles/withStyles';

import TextDiffStyles from './styles';

const TextDiff = ({ classes, original, modified }) =>
  diffWordsWithSpace(original, modified).map((item, i) => {
    const idx = `${i}`;
    if (item.added) {
      return (
        <span className={classes.added} key={`added_${item.value}_${idx}`}>
          {item.value}
        </span>
      );
    }
    if (item.removed) {
      return (
        <span className={classes.removed} key={`removed_${item.value}_${idx}`}>
          {item.value}
        </span>
      );
    }
    return <span key={`no_edit_${item.value}_${idx}`}>{item.value}</span>;
  });

TextDiff.propTypes = {
  classes: PropTypes.object.isRequired,
  original: PropTypes.string.isRequired,
  modified: PropTypes.string.isRequired,
};

export default withStyles(TextDiffStyles)(TextDiff);
