import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import { ACCOUNT_GROUPS_CONFIG } from '../groups.config';
import { METRIC_ACTIONS, METRICS } from '../metrics.config';
import { LOGIN_ROUTE, HOME_ROUTE, FAST_TRACK_ROUTE } from '../routes.config';
import { WriteMetrics } from '../utils/requests';
import { useAuthUpdateContext } from './AuthUpdateProvider';

export default function AuthenticatedRoute({ children, routePermissions }) {
  const {
    authenticated,
    authenticationFinished,
    permissions,
    hasRoleAuth,
    user,
    getAuthHeader,
  } = useAuthUpdateContext();

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const fullUri = pathname + search;
  const fastTrackRightNames = Object.entries(ACCOUNT_GROUPS_CONFIG.fasttrack)
    .filter(([, isEnabled]) => isEnabled)
    .map(([right]) => right);
  const hasOnlyFastTrackRights =
    fastTrackRightNames.every((right) => permissions.has(right)) &&
    permissions.size === fastTrackRightNames.length;

  useEffect(() => {
    if (!authenticationFinished) {
      return;
    }

    // User is not authenticated
    if (!authenticated) {
      console.error(`Redirecting to ${LOGIN_ROUTE} because user is not authenticated}`);
      navigate(`${LOGIN_ROUTE}?redirect=${fullUri}`);
    }

    // User is authenticated but only has fast track permissions
    else if (hasPermissions() && hasOnlyFastTrackRights) {
      console.error(`Redirecting to ${FAST_TRACK_ROUTE} due to permissions`);
      navigate(`${FAST_TRACK_ROUTE}`);
    }

    // User is authenticated but does not have permission to view route
    else if (!hasPermissions() && hasRoleAuth) {
      console.error(
        `User does not have permission to access this route. Redirecting to ${HOME_ROUTE}`
      );
      navigate(HOME_ROUTE);
    }

    // Happy path
    else {
      getAuthHeader().then((headers) => {
        WriteMetrics(headers, {
          metric: METRICS.USER,
          action: METRIC_ACTIONS.PAGEVIEW,
          path: fullUri,
          timestamp: new Date(),
          userId: user,
        });
      });
    }
  }, [authenticated, pathname, authenticationFinished, hasRoleAuth]);

  function hasPermissions() {
    return (
      hasRoleAuth &&
      routePermissions.reduce((result, p) => result || permissions.has(p), false)
    );
  }

  return authenticated && children;
}

AuthenticatedRoute.propTypes = {
  children: PropTypes.any.isRequired,
  routePermissions: PropTypes.array.isRequired,
};
