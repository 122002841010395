import Colors from '../../styles/Colors';

const GridCellRoutingStyles = () => ({
  emailIcon: {
    width: 28,
  },
  emailIconRecipient: {
    width: 28,
    color: Colors.blue2,
  },
  customContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 10,
  },
  emailDate: {
    fontSize: 13,
    color: Colors.grey3,
  },
  emailContent: {
    marginLeft: '-30px',
  },
});

export default GridCellRoutingStyles;
