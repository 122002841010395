import React from 'react';
import PropTypes from 'prop-types';
import { diffWordsWithSpace } from 'diff';

import withStyles from '@mui/styles/withStyles';

import {
  getSlotDetails,
  removeSlotsFromText,
  SLOT_REGEX_TRAINING,
} from '../../utils/slots';
import TextDiffWithSlotsStyles from './styles';

const replaceBetween = (origin, startIndex, endIndex, insertion) =>
  origin.substring(0, startIndex) + insertion + origin.substring(endIndex);

const buildTextSpan = (text, editType, idx, classes, isSlot = false) => {
  const randomKeyId = Math.random();
  // TODO: add support for mapping slot name to desired slot color as defined in the config
  switch (editType) {
    case 'added':
      return (
        <span
          className={`${classes.added} ${isSlot ? classes.slot : ''}`}
          key={`added_${text}_${idx}_${randomKeyId}`}
        >
          {text}
        </span>
      );
    case 'removed':
      return (
        <span
          className={`${classes.removed} ${isSlot ? classes.slot : ''}`}
          key={`removed_${text}_${idx}_${randomKeyId}`}
        >
          {text}
        </span>
      );
    default:
      return (
        <span
          className={`${isSlot ? classes.slot : ''}`}
          key={`no_edit_${text}_${idx}_${randomKeyId}`}
        >
          {text}
        </span>
      );
  }
};

const templateSlotSpan = (text, editType, match, idx, classes, currChar = 0) => {
  /*
    Break apart text to extract left text, formatted slot text, and right text
    Return [<span/>] allowing formatting of individual slots
     */
  const adjustedStartIdx = match.startIdx - currChar;
  const prefix = text.substring(0, adjustedStartIdx);
  const slot = match.value;
  const suffix = text.substring(adjustedStartIdx + match.match.length);
  const spans = [];

  spans.push(buildTextSpan(prefix, editType, idx, classes));
  spans.push(buildTextSpan(slot, editType, idx, classes, true));
  spans.push(buildTextSpan(suffix, editType, idx, classes));
  return spans;
};

const TextDiffWithSlots = ({ classes, original, modified }) => {
  const { matches, slotsRemovedText } = removeSlotsFromText(modified);

  let currTextIdx = 0;

  return diffWordsWithSpace(original, slotsRemovedText).map((item, i) => {
    let currChar = 0;
    const updatedItem = { ...item };
    if (!updatedItem.removed) {
      matches.forEach((match) => {
        const start = match.startIdx - currTextIdx;
        const end = start + match.name.length;
        if (updatedItem.value.substring(start, end) === match.name) {
          updatedItem.value = replaceBetween(updatedItem.value, start, end, match.match);
        }
      });
      currTextIdx += updatedItem.value.length;
    }

    const textMatches =
      [...updatedItem.value.matchAll(SLOT_REGEX_TRAINING)].map((match) => {
        const { name, value } = getSlotDetails(match[0]);
        return {
          name,
          value,
          match: match[0],
          startIdx: match.index,
          length: match[0].length,
        };
      }) || [];

    let editType = 'none';
    if (updatedItem.added) {
      editType = 'added';
    } else if (updatedItem.removed) {
      editType = 'removed';
    }

    const idx = `${i}`;
    if (textMatches.length > 0) {
      const matchSplits = updatedItem.value.split(SLOT_REGEX_TRAINING);

      // construct edit span items
      const items = matchSplits.map(
        (matchSplit, index) =>
          `${matchSplit}${textMatches.length > index ? textMatches[index].match : ''}`
      );

      return items.map((text, index) => {
        if (textMatches.length > index) {
          const spans = templateSlotSpan(
            text,
            editType,
            textMatches[index],
            idx,
            classes,
            currChar
          );
          if (!updatedItem.removed) {
            currChar += text.length;
          }
          return spans;
        }
        if (!updatedItem.removed) {
          currChar += text.length;
        }
        return buildTextSpan(text, editType, idx, classes);
      });
    }
    return buildTextSpan(updatedItem.value, editType, idx, classes);
  });
};

TextDiffWithSlots.propTypes = {
  classes: PropTypes.object.isRequired,
  original: PropTypes.string.isRequired,
  modified: PropTypes.string.isRequired,
};

export default withStyles(TextDiffWithSlotsStyles)(TextDiffWithSlots);
