import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Grid, Typography } from '@mui/material';

import PageHeaderStyles from './styles';

const PageHeader = ({
  classes,
  title,
  rightButton = null,
  hasTabs = false,
  isBlue = false,
  titleWidth = 6,
  children = null,
}) => (
  <div
    className={`${isBlue ? classes.pageHeaderWrapperBlue : classes.pageHeaderWrapper}`}
  >
    <Grid container direction="column">
      <Grid
        container
        item
        direction="row"
        justifyContent="space-between"
        className={classes.pageHeaderRow}
      >
        <Grid item xs={titleWidth}>
          <Typography variant="h1">{title}</Typography>
        </Grid>
        <Grid container item justifyContent="flex-end" xs={12 - titleWidth}>
          {rightButton}
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={`${hasTabs ? classes.pageHeaderTabRow : ''}`}
      >
        {children}
      </Grid>
    </Grid>
  </div>
);

PageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  rightButton: PropTypes.element,
  hasTabs: PropTypes.bool,
  isBlue: PropTypes.bool,
  titleWidth: PropTypes.number,
  children: PropTypes.element,
};

export default withStyles(PageHeaderStyles)(PageHeader);
