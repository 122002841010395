import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { TextField, Typography, Grid } from '@mui/material';

import FastTrackStyles from '../../../../styles/FastTrackStyles';

const NumberInputFormik = ({
  formikValues,
  formikErrors,
  formikTouched,
  formikHandleBlur,
  formikSetFieldValue,
  title,
  name,
  clarifyingText = null,
  classes,
  xs = 12,
}) => (
  <Grid container item spacing={1} direction="column" xs={xs}>
    <Grid item className={classes.zeroPaddingBottom}>
      <Typography className={classes.floatLeft}>{title}</Typography>
    </Grid>
    <Grid item>
      <Grid container direction="column">
        <Grid item className={classes.zeroPaddingBottom}>
          <TextField
            id={`${name}_${new Date().toString()}`}
            onChange={(e) => formikSetFieldValue(name, e.target.value)}
            value={formikValues[name]}
            onBlur={formikHandleBlur}
            InputProps={{
              inputProps: {
                min: '0',
                max: '1000',
                step: '1',
                style: {
                  textAlign: 'center',
                },
              },
            }}
            type="number"
            variant="outlined"
            className={classes.floatLeft}
          />
        </Grid>

        <Grid item className={classes.inputFormikRequiredText}>
          {formikTouched[name] && formikErrors[name] ? (
            <div className={classes.yupErrorMsg}>
              {title.replace('*', '')} is required.
            </div>
          ) : null}
        </Grid>
        {clarifyingText ? (
          <Grid item>
            <div className={classes.clarifyingText}>{clarifyingText}</div>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  </Grid>
);

NumberInputFormik.propTypes = {
  classes: PropTypes.object.isRequired,
  formikValues: PropTypes.object.isRequired,
  formikErrors: PropTypes.object.isRequired,
  formikTouched: PropTypes.object.isRequired,
  formikHandleBlur: PropTypes.func.isRequired,
  formikSetFieldValue: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  clarifyingText: PropTypes.string,
  xs: PropTypes.number,
};

export default withStyles(FastTrackStyles)(NumberInputFormik);
