import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import withStyles from '@mui/styles/withStyles';
import { Button, Checkbox, MenuItem, Select } from '@mui/material';

import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';
import { Search } from '../Search';

const TEXT_TYPES = ['Chunk', 'Paragraph', 'Sentence'];

const SearchAndSelectBar = ({
  classes,
  isLoading,
  search,
  setSearch,
  section,
  setSection,
  textTypes,
  setTextTypes,
  selectAll,
  setSelectAll,
  selectAllVisible,
  setSelectAllVisible,
  resultsPerPage,
  setResultsPerPage,
  sections,
  resetData,
  handleSelectAll,
  handleSelectAllVisible,
  handleSectionChange,
  handleTextTypesChange,
  selectable = true,
}) => {
  const handleClearFilters = () => {
    setSearch('');
    setSection('');
    setTextTypes(['']);
    setSelectAll(false);
    setSelectAllVisible(false);
    resetData();
  };

  return (
    <>
      <div className="row pt-5">
        <div className="col pl-0">
          <Search
            id="playbooks-text-search"
            placeholder="Search within"
            isLoading={isLoading}
            value={search}
            onChange={(evt) => setSearch(evt.target.value)}
            onClear={() => setSearch('')}
          />
        </div>
        <div className="col">
          <Select
            variant="outlined"
            displayEmpty
            disabled={isLoading}
            value={section}
            sx={{ height: '40px' }}
            inputProps={{
              'aria-label': 'Select section',
            }}
            renderValue={(option) =>
              option
                ? `Section: ${find(sections, { key: option }).value}`
                : 'Select: Section'
            }
            onChange={(evt) => handleSectionChange(evt.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="" disabled>
              Section: Select
            </MenuItem>
            {sections.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="col">
          <Select
            variant="outlined"
            displayEmpty
            disabled={isLoading}
            value={textTypes}
            sx={{ height: '40px' }}
            inputProps={{
              'aria-label': 'Select Text Type',
            }}
            multiple
            renderValue={(options) => {
              if (options.length === 1 && options[0] === '') {
                return 'Select: Text Type';
              }
              return `Type: ${options.slice(1).sort((a, b) => a.localeCompare(b))}`;
            }}
            onChange={(evt) => handleTextTypesChange(evt.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="" disabled>
              Text Type: Select
            </MenuItem>
            {TEXT_TYPES.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="col">
          <Select
            variant="outlined"
            displayEmpty
            disabled={isLoading}
            value={resultsPerPage.toString()}
            sx={{ height: '40px' }}
            inputProps={{
              'aria-label': 'Select Results',
            }}
            renderValue={(options) => {
              if (options.length === 1 && options[0] === '') {
                return 'Select: Results';
              }
              return `Results: ${options}`;
            }}
            onChange={(evt) => setResultsPerPage(Number(evt.target.value))}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="" disabled>
              Results: Select
            </MenuItem>
            {['20', '50', '100'].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className={`col ${classes.clearFilterBtnWrapper}`}>
          <Button className={classes.clearFilterButton} onClick={handleClearFilters}>
            Clear Filters
          </Button>
        </div>
      </div>
      <div className="row mt-3">
        {selectable ? (
          <div className="col">
            <span>
              <Checkbox
                color="primary"
                name="Select All Visible"
                checked={selectAllVisible}
                disabled={isLoading}
                onChange={handleSelectAllVisible}
              />
              <span className={`ml-2 ${classes.fontSizeSmall}`}>Select All Visible</span>
            </span>
            <span className="ml-5">
              <Checkbox
                color="primary"
                name="Select All"
                checked={selectAll}
                disabled={isLoading}
                onChange={handleSelectAll}
              />
              <span className={`ml-2 ${classes.fontSizeSmall}`}>Select All</span>
            </span>
          </div>
        ) : null}
      </div>
    </>
  );
};

SearchAndSelectBar.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired,
  setSection: PropTypes.func.isRequired,
  textTypes: PropTypes.array.isRequired,
  setTextTypes: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
  setSelectAll: PropTypes.func.isRequired,
  selectAllVisible: PropTypes.bool.isRequired,
  setSelectAllVisible: PropTypes.func.isRequired,
  handleSelectAllVisible: PropTypes.func.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  setResultsPerPage: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  resetData: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleSectionChange: PropTypes.func.isRequired,
  handleTextTypesChange: PropTypes.func.isRequired,
  selectable: PropTypes.bool,
};

export default withStyles(PlaybookBuilderStyles)(SearchAndSelectBar);
