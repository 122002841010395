import Colors from '../../styles/Colors';

const TextDiffWithSlotsStyles = () => ({
  added: {
    color: Colors.textDiffGreen,
  },
  removed: {
    textDecoration: 'line-through',
    color: Colors.textDiffRed,
  },
  slot: {
    backgroundColor: Colors.red2,
  },
});

export default TextDiffWithSlotsStyles;
