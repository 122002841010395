import React from 'react';
import { Box } from '@mui/material';
import { EDIT_TYPES } from '../../config';
import Colors from '../../styles/Colors';

function Nugget({ editType }) {
  function getEditTypeColor() {
    switch (editType) {
      case EDIT_TYPES.ACCEPT:
        return Colors.editTypeAccept;
      case EDIT_TYPES.REVISE:
        return Colors.editTypeRevise;
      case EDIT_TYPES.REJECT:
        return Colors.editTypeReject;
      default:
        return '#DFE8FE';
    }
  }

  return (
    <Box
      sx={{
        bgcolor: getEditTypeColor(),
        color: '#5C6E81',
        px: 2,
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
      }}
    >
      {editType?.toUpperCase() || 'ACCEPT'}
    </Box>
  );
}

export default Nugget;
