import Colors from '../../styles/Colors';

const styles = () => ({
  dialogWrapper: {
    overflow: 'hidden',
    overflowY: 'visible',
  },
  text: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
  },
  topPadding: {
    paddingTop: 15,
  },
  dialogHeader: {
    backgroundColor: Colors.grey1,
    padding: 30,
    marginBottom: 0,
  },
  dialogHeaderAlert: {
    backgroundColor: Colors.red2,
    padding: 30,
    marginBottom: 0,
  },
  dialogError: {
    backgroundColor: Colors.red2,
    padding: 10,
    marginBottom: 0,
  },
  dialogContent: {
    padding: 20,
    paddingTop: 30,
  },
  dialogFastTrackContent: {
    padding: 45,
    paddingTop: 30,
  },
  dialogModelGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogModelImg: {
    width: 43,
  },
  dialogCloseIcon: {
    fontSize: '2.5rem',
    color: Colors.grey2,
  },
  dialogLoading: {
    width: '100%',
    height: 4,
  },
});

export default styles;
