import Colors from '../../styles/Colors';

const GridCellStatusStyles = () => ({
  statusBtn: {
    textTransform: 'none',
    borderRadius: 20,
    minHeight: 0,
    height: 25,
    fontSize: '.75em',
    letterSpacing: 0,
    fontWeight: 400,
    color: 'black',
  },
  dot: {
    height: '8px',
    width: '8px',
    marginRight: '5px',
    backgroundColor: Colors.blue,
    borderRadius: '50%',
    display: 'inline-block',
  },
  redDot: {
    backgroundColor: Colors.red,
  },
  blackDot: {
    backgroundColor: Colors.black,
  },
  menuDot: {
    marginLeft: 10,
  },
  statusText: {
    color: '#555e68',
    fontSize: 13,
  },
});

export default GridCellStatusStyles;
