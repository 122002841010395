import React, { useState } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Button, Grid, Link, Typography } from '@mui/material';

import Colors from '../../styles/Colors';
import theme from '../../styles/MaterialTheme';
import CustomThemeProvider from '../../utils/CustomThemeProvider';
import PasswordResetByCode from './PasswordResetByCode';
import LoginContentStyles from './LoginContentStyles';

const DEFAULT_LOGO_URL = 'https://bbcedit-web-assets.s3.amazonaws.com/logos/bb_logo.png';

const LoginContent = ({
  classes,
  onClick = (f) => f,
  logoUrl = DEFAULT_LOGO_URL,
  provider,
  directIdpName = null,
  showPasswordReset = false,
  customSiteText = {},
}) => {
  const [isEnteringCode, setIsEnteringCode] = useState(false);

  return (
    <CustomThemeProvider theme={theme} productionPrefix="Routing-JSS-">
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={3}
        className={classes.root}
      >
        <Grid item>
          <img
            src={logoUrl || DEFAULT_LOGO_URL}
            alt="Company Logo"
            data-cy="company-logo"
            className={classes.logoImg}
          />
        </Grid>
        {directIdpName && provider === 'aws' ? (
          <>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => onClick(null, true)}
              fullWidth
            >
              Sign in
            </Button>
            <Grid item className={classes.buttonWide}>
              <Button variant="contained" color="secondary" onClick={onClick} fullWidth>
                Sign in with Another Provider
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item className={classes.button}>
            <Grid container direction="column" spacing={2}>
              {isEnteringCode ? null : (
                <Grid item>
                  <Button
                    data-cy="sign-in-button"
                    variant="contained"
                    color="secondary"
                    onClick={onClick}
                    fullWidth
                  >
                    Sign in
                  </Button>
                </Grid>
              )}
              {showPasswordReset ? (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  {!isEnteringCode ? (
                    <Grid item>
                      <button type="button" onClick={() => setIsEnteringCode(true)}>
                        <Typography style={{ color: Colors.blue }}>
                          Enter recovery code
                        </Typography>
                      </button>
                    </Grid>
                  ) : (
                    <PasswordResetByCode
                      setDisplay={setIsEnteringCode}
                      onClick={
                        directIdpName && provider === 'aws'
                          ? () => onClick(null, true)
                          : onClick
                      }
                    />
                  )}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        )}
        {Object.keys(customSiteText).length && !customSiteText.hidden ? (
          <Grid item>
            {customSiteText.type === 'TEXT' ? (
              <Typography>{customSiteText.text}</Typography>
            ) : null}
            {customSiteText.type === 'LINK' ? (
              <Typography>
                <Link href={customSiteText.linkDest} underline="hover">
                  {customSiteText.text}
                </Link>
              </Typography>
            ) : null}
          </Grid>
        ) : null}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
          className={classes.linkGrid}
        >
          <Grid item>
            <Typography>
              <Link
                href="https://www.blackboiler.com/blackboiler-llterms-of-use/"
                underline="hover"
              >
                Terms of Use
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <Link
                href="https://www.blackboiler.com/intellectual-property/"
                underline="hover"
              >
                Intellectual Property
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <Link href="https://www.blackboiler.com/privacy-policy/" underline="hover">
                Privacy Policy
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CustomThemeProvider>
  );
};

LoginContent.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  logoUrl: PropTypes.string,
  provider: PropTypes.string.isRequired,
  directIdpName: PropTypes.string,
  showPasswordReset: PropTypes.bool,
  customSiteText: PropTypes.object,
};

export default withStyles(LoginContentStyles)(LoginContent);
