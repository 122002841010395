import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Grid, Typography } from '@mui/material';

import DockTextBlockStyles from './styles';

const DockTextBlock = ({ classes, value = '', description = '' }) => (
  <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    className={classes.metricText}
  >
    <Grid item>
      <Typography variant="h1">{value}</Typography>
    </Grid>
    <Grid item>
      <Typography variant="h5">{description || 'No data available'}</Typography>
    </Grid>
  </Grid>
);

DockTextBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
};

export default withStyles(DockTextBlockStyles)(DockTextBlock);
