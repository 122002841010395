import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { TextField } from '@mui/material';

import { convertToTitle } from '../../utils/formatting';
import ModifyNumberObjectFieldsStyles from './styles';

const ModifyNumberObjectFields = ({
  classes,
  objectData,
  fieldName,
  updateFieldData,
  addUpdatedFieldName,
}) => {
  const [objectFieldData, setObjectFieldData] = useState(objectData);

  useEffect(() => {
    updateFieldData(objectFieldData, fieldName);
  }, [objectFieldData, setObjectFieldData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setObjectFieldData({
      ...objectFieldData,
      [name]: value,
    });
    addUpdatedFieldName(`${fieldName}.${name}`);
  };

  return (
    <>
      {Object.entries(objectData).map(([key, value]) => (
        <TextField
          key={key}
          className={classes.textField}
          label={convertToTitle(key)}
          name={key}
          type="number"
          value={value}
          onChange={handleChange}
          variant="outlined"
        />
      ))}
    </>
  );
};

ModifyNumberObjectFields.propTypes = {
  classes: PropTypes.object.isRequired,
  objectData: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  updateFieldData: PropTypes.func.isRequired,
  addUpdatedFieldName: PropTypes.func.isRequired,
};

export default withStyles(ModifyNumberObjectFieldsStyles)(ModifyNumberObjectFields);
