import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import withStyles from '@mui/styles/withStyles';
import { Button, Grid, Typography } from '@mui/material';

import FastTrackStyles from '../../../../styles/FastTrackStyles';
import './customDatePickerWidth.css';

const DatePickerFormik = ({
  formikSetFieldValue,
  formikValues,
  formikErrors,
  formikTouched,
  title,
  name,
  minDate,
  maxDate,
  classes,
  xs = 7,
  divWrap = true,
  disabled = false,
}) => (
  <Grid container spacing={1} direction="column">
    <Grid item className={classes.zeroPaddingBottom}>
      <Typography className={classes.floatLeft}>{title}</Typography>
    </Grid>
    <Grid item className={classes.zeroPaddingBottom}>
      <Grid container spacing={2}>
        <Grid item xs={xs}>
          {divWrap ? (
            <div
              className="customDatePickerWidth"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <DatePicker
                selected={formikValues[name]}
                onChange={(value) => formikSetFieldValue(name, value)}
                autoComplete="date"
                dateFormat="MMMM d, yyyy"
                minDate={new Date(minDate)}
                maxDate={new Date(maxDate)}
                monthsShown={2}
                className={classes.formikDatePicker}
                disabled={disabled}
              />
            </div>
          ) : (
            <DatePicker
              selected={formikValues[name]}
              onChange={(value) => formikSetFieldValue(name, value)}
              autoComplete="date"
              dateFormat="MMMM d, yyyy"
              minDate={new Date(minDate)}
              maxDate={new Date(maxDate)}
              monthsShown={2}
              className={classes.formikDatePicker}
              disabled={disabled}
            />
          )}
        </Grid>
        {!disabled && (
          <Grid item>
            <Button
              className={classes.inputActionButton}
              onClick={() => formikSetFieldValue(name, new Date())}
            >
              Today
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
    <Grid item className={classes.zeroPaddingTop}>
      {formikTouched[name] && formikErrors[name] ? (
        <div className={classes.yupErrorMsg}>{title.replace('*', '')} is required.</div>
      ) : null}
    </Grid>
  </Grid>
);

DatePickerFormik.propTypes = {
  classes: PropTypes.object.isRequired,
  formikSetFieldValue: PropTypes.func.isRequired,
  formikValues: PropTypes.object.isRequired,
  formikErrors: PropTypes.object.isRequired,
  formikTouched: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  xs: PropTypes.number,
  divWrap: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default withStyles(FastTrackStyles)(DatePickerFormik);
