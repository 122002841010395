const ChartBarStyles = () => ({
  root: {
    height: '100%',
    padding: 20,
  },
  titleGrid: {
    paddingBottom: 5,
  },
  chartGrid: {
    height: '100%',
  },
  customHeight: {
    height: 250,
  },
});

export default ChartBarStyles;
