import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import TransferListMUI from '../TransferListMUI/TransferListMUI';
import { setErrMsg } from '../../redux/actions/snackbarActions';
import { useDispatch } from 'react-redux';
import Colors from '../../styles/Colors';

const styles = {
  containerStyle: {
    backgroundColor: Colors.pageContentBlue,
    padding: 10,
  },
};

const ListEditor = ({ includeList, excludeList, setIncludeList, setExcludeList }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');

  const setErr = (value) => dispatch(setErrMsg(value));

  const handleListChange = (sideToInsert) => {
    const joined = [...includeList, ...excludeList];
    const splitText = text.split(',');
    if (splitText.some((t) => joined.includes(t))) {
      setErr('Text already exists');
      return;
    }
    if (sideToInsert === 'include') {
      setIncludeList([...includeList, ...splitText]);
    } else if (sideToInsert === 'exclude') {
      setExcludeList([...excludeList, ...splitText]);
    }
    setText('');
  };

  return (
    <Grid container sx={styles.containerStyle}>
      <Grid item xs={4}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              variant="outlined"
              label="Text to Add"
              value={text}
              onChange={(evt) => setText(evt.target.value)}
              placeholder="Add your text here"
            />
          </Grid>
          <Grid item sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              sx={{ width: '100%' }}
              onClick={() => handleListChange('include')}
              disabled={!text.length}
            >
              Include this text
            </Button>
          </Grid>
          <Grid item sx={{ textAlign: 'center' }}>
            <Typography variant="h4">OR</Typography>
          </Grid>
          <Grid item sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              sx={{ width: '100%' }}
              onClick={() => handleListChange('exclude')}
              disabled={!text.length}
            >
              Exclude this text
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <TransferListMUI
          left={includeList}
          setLeft={setIncludeList}
          right={excludeList}
          setRight={setExcludeList}
          leftText="Always Include"
          rightText="Always Exclude"
        />
      </Grid>
    </Grid>
  );
};

export default ListEditor;
