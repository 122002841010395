import axios from 'axios';
import { KJUR } from 'jsrsasign';

import { addContentHeader } from './requests';
import { ONLYOFFICE_BB_LOGO } from '../config';
import {
  LOG_ENDPOINT,
  ONLYOFFICE_CONFIG_ENDPOINT,
  ONLYOFFICE_TRACK_ENDPOINT,
} from '../endpoints.config';

const createDownloadRef = (docName, data) => {
  const link = document.createElement('a');
  link.download = docName;
  link.href = data;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// TODO: Add back in when ready for document version history
// const onRequestHistory = (event, fileVersion, onlyOfficeHistory) => {
//     // TODO: call server to get fresh history
//     window.DocsAPICopy.refreshHistory({ currentVersion: fileVersion, history: onlyOfficeHistory });
// };

const onRequestHistoryData = (event, onlyOfficeHistoryData) => {
  const version = event.data;
  try {
    window.DocsAPICopy.setHistoryData(onlyOfficeHistoryData[version - 1]);
  } catch (ex) {
    console.log('Error in RequestHistoryData', ex);
  }
};

const onDocumentStateChange = (event) => {
  // the document is modified
  const title = document.title.replace(/\*$/g, '');
  document.title = title + (event.data ? '*' : '');
};
const onOutdatedVersion = () => {
  // the document is opened for editing with the old document.key value
  window.location.reload(true);
};

export const combineConfig = (
  styles,
  contractInfo,
  onlyofficeConfig,
  docOrchUrl,
  defaultEditorName,
  bearer
) => {
  let result = {};
  const docName = contractInfo.contract.name;
  const { userId, email, roles } = contractInfo.user;
  const { _id: id } = contractInfo.contract;
  const userEditorName = defaultEditorName || email;
  const groups = contractInfo.contract.groups
    ? contractInfo.contract.groups.map(({ _id }) => _id)
    : [];
  const version = 1;
  const ext = contractInfo.contract.path.split('.').slice(-1)[0];
  const isLocal = window.location.host.includes('localhost');
  const company = isLocal ? 'abccorp' : window.location.host.split('.')[0];
  const internalUrl = isLocal
    ? 'http://host.docker.internal:3000'
    : `http://bbcedit-web.${window.location.host.split('.')[0]}.svc.cluster.local`;
  const fileUrl = `${docOrchUrl}/files/${company}/${id}/${version}.${ext}`;
  const callbackUrl = `${internalUrl}${ONLYOFFICE_TRACK_ENDPOINT}?user=${
    email
  }&filename=${id}.${version}.${ext}&company=${company}&groups=${groups}&userId=${
    userId
  }&roles=${roles}&authorization=BEARER%20${bearer}`;
  result = {
    height: styles.height,
    document: {
      fileType: ext,
      title: docName,
      key: onlyofficeConfig.editor.key,
      url: fileUrl,
      permissions: {
        download: true,
        edit: true,
        print: false,
        review: true,
        chat: false,
      },
    },
    documentType: 'word',
    editorConfig: {
      user: {
        id: userId,
        name: userEditorName,
      },
      embedded: {
        saveUrl: fileUrl,
        embedUrl: fileUrl,
        shareUrl: fileUrl,
        toolbarDocked: 'top',
      },
      customization: {
        review: {
          showReviewChanges: true,
          hoverMode: true,
          reviewDisplay: 'markup',
          trackChanges: true,
        },
        comments: true,
        autosave: true,
        compactToolbar: true,
        forcesave: true,
        customer: {
          info: 'v6.1.0',
          logo: ONLYOFFICE_BB_LOGO,
          mail: 'support@blackboiler.com',
          name: 'Daniel Broderick',
          www: 'www.blackboiler.com',
        },
        logo: {
          image: ONLYOFFICE_BB_LOGO,
          imageEmbedded: ONLYOFFICE_BB_LOGO,
          url: 'https://www.blackboiler.com',
        },
        uiTheme: 'default-light',
      },
      wopi: {
        enable: true,
      },
      callbackUrl: `${callbackUrl}`,
    },
    events: {
      onDownloadAs(event) {
        createDownloadRef(docName, event.data.url);
      },
      // TODO: re-enable this to display "show version history"
      // onRequestHistory(event) {
      //     onRequestHistory(event, onlyofficeConfig.file.version, onlyofficeConfig.history);
      // },
      onRequestHistoryData(event) {
        onRequestHistoryData(event, onlyofficeConfig.historyData);
      },
      onDocumentStateChange(event) {
        onDocumentStateChange(event);
      },
      onOutdatedVersion() {
        onOutdatedVersion();
      },
    },
  };

  return result;
};

export const toJWT = (payload, key) => {
  const sHeader = JSON.stringify({ alg: 'HS256', typ: 'JWT' });
  const sPayload = JSON.stringify(payload);
  const sJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, key);
  return sJWT;
};

export const appendScript = (scriptToAppend) => {
  const script = document.createElement('script');
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
};

export const logDocumentExport = (headers, contractId) => {
  const body = {
    message: `${contractId}: Exported`,
    action: 'export',
    documentId: contractId,
  };
  const updatedHeaders = addContentHeader(headers);

  axios
    .post(`${LOG_ENDPOINT}`, { ...body }, { headers: updatedHeaders })
    .then((resp) => {
      if (resp.status !== 200) {
        console.log('Uh oh! Failed to log document export with data:', resp.data);
      } else {
        console.log('Successfully logged document export with data:', resp.data);
      }
    })
    .catch((err) => {
      console.log('Failed to log document export with error:', err);
    });
};

export const forceSaveRequest = async (headers, onlyOfficeUrl, contractId) => {
  const updatedHeaders = addContentHeader(headers);
  const onlyOfficeEditorKey = await axios
    .get(`${ONLYOFFICE_CONFIG_ENDPOINT}?documentId=${contractId}`, { headers })
    .then(({ data }) => {
      if (data && data.editor && data.editor.key) {
        return data.editor.key;
      }
      throw Error(`Invalid only office configuration object: ${data}`);
    })
    .catch((err) => {
      console.log(
        `Failed to retrieve only office configuration details for contract ID ${contractId} with error:`,
        err
      );
    });

  const onlyOfficeCommandUri = `${onlyOfficeUrl}/coauthoring/CommandService.ashx`;

  const body = {
    c: 'forcesave',
    key: onlyOfficeEditorKey,
  };
  return axios
    .post(onlyOfficeCommandUri, body, { headers: updatedHeaders })
    .then((res) => {
      if (res.data && res.data.error) {
        const code = res.data.error;
        throw Error(`Failed to force save request with code: ${code}`);
      }
    })
    .catch((err) => {
      console.log('Failed to log document export with error:', err);
    });
};

// may be needed in future: https://github.com/BlackBoiler/bbcedit_web/blob/3f8cd5162c9657f4123d1f9d53d0731524e088c2/drywall/client/src/app/onlyoffice/onlyoffice.service.js#L191-L194
// export const removeInsertImageFromUrl = () => {
//     const $iframe = $('iframe')
//     $('#tlbtn-insertimage > ul > li:nth-child(2)', $iframe.contents()).remove()
//   }
