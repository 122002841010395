import React, { useEffect, useState, useCallback } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { debounce } from '@mui/material/utils';
import PropTypes from 'prop-types';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';
import geoService from '../../utils/GeoService';
import { BBSelect, BBAutocomplete, Location, DualPriorityList } from '../../components';

const GEO_TYPE_OPTIONS = geoService.getGeoOptions('governing-law');
const DEBOUNCE_ASYNC_TIME = 250;

function GeoSelector({
  classes,
  includeLocations,
  onIncludeLocationsChanged,
  excludeLocations,
  onExcludeLocationsChanged,
}) {
  const { getAuthHeader } = useAuthUpdateContext();

  // Selects
  const [locationSearchOpen, setLocationSearchOpen] = useState(false);
  const [jurisdictionType, setJurisdictionType] = useState('');
  const [locationSearchValue, setLocationSearchValue] = useState([]);
  const [locationSearchMode, setLocationSearchMode] = useState();
  const [locationValue, setLocationValue] = useState();
  const [locationSearchOptions, setLocationSearchOptions] = useState([]);
  const [locationSearchOptionsLoading, setLocationSearchOptionsLoading] = useState(false);

  useEffect(() => {
    let isComponentActive = { active: true };
    if (jurisdictionType) {
      const { searchMode } = GEO_TYPE_OPTIONS[jurisdictionType];
      if (locationSearchValue.length && searchMode === 'remote') {
        setLocationSearchOptionsLoading(true);
        getSearchOptionsDelayed(locationSearchValue, jurisdictionType, isComponentActive);
      }
    }
    return () => {
      isComponentActive.active = false;
    };
  }, [locationSearchValue]);

  useEffect(() => {
    let isComponentActive = { active: true };
    setLocationSearchOptions([]);
    if (jurisdictionType) {
      const { searchMode } = GEO_TYPE_OPTIONS[jurisdictionType];
      setLocationSearchMode(searchMode);
      fetchSearchResults('', jurisdictionType, isComponentActive);
    }
    return () => {
      isComponentActive.active = false;
    };
  }, [jurisdictionType]);

  const getSearchOptionsDelayed = useCallback(
    debounce((searchTerms, jurisdictionType, isComponentActive) => {
      fetchSearchResults(searchTerms, jurisdictionType, isComponentActive);
    }, DEBOUNCE_ASYNC_TIME),
    []
  );

  function handleAddInclude() {
    if (includeLocations.map((loc) => loc.id).includes(locationValue.id)) {
      return;
    }
    onIncludeLocationsChanged([...includeLocations, locationValue]);
    setLocationValue(null);
  }

  function handleAddExclude() {
    if (excludeLocations.map((loc) => loc.id).includes(locationValue.id)) {
      return;
    }
    onExcludeLocationsChanged([...excludeLocations, locationValue]);
    setLocationValue(null);
  }

  function handleLocationSearchFilterOptions(options, { inputValue }) {
    setLocationSearchValue(inputValue);
    return options;
  }

  function handleLocationSearchChanged(value) {
    setLocationValue(value);
  }

  async function fetchSearchResults(searchTerms, jurisdictionType, isComponentActive) {
    const jurisdictionFilters = GEO_TYPE_OPTIONS[jurisdictionType]?.filters;
    if (
      !isComponentActive.active ||
      searchTerms == null ||
      searchTerms == undefined ||
      !jurisdictionType
    ) {
      setLocationSearchOptionsLoading(false);
      return Promise.resolve();
    }
    setLocationSearchOptionsLoading(true);
    const authHeaders = await getAuthHeader();
    const searchResults = await geoService.fetchGeoData(authHeaders, {
      tokens: searchTerms.split(' ').map((t) => t.trim()),
      filters: jurisdictionFilters,
    });
    const searchResultsAnnotated = searchResults.map((sr) => ({
      ...sr,
      category: jurisdictionType,
    }));
    setLocationSearchOptions(searchResultsAnnotated);
    setLocationSearchOptionsLoading(false);
    return searchResults;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Grid container direction="column" spacing={2} sx={{ marginTop: 1 }}>
          <Grid item>
            <BBSelect
              label="Jurisdiction Type"
              value={jurisdictionType}
              options={Object.keys(GEO_TYPE_OPTIONS)}
              onChange={(evt) => setJurisdictionType(evt.target.value)}
            />
          </Grid>
          <Grid item>
            <BBAutocomplete
              label="Search Locations"
              options={locationSearchOptions}
              onChange={(_, val) => handleLocationSearchChanged(val)}
              disabled={!jurisdictionType}
              loading={locationSearchOptionsLoading}
              open={locationSearchOpen}
              onOpen={() => setLocationSearchOpen(true)}
              onClose={() => setLocationSearchOpen(false)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(opt) => opt.name}
              {...(locationSearchMode === 'remote' && {
                filterOptions: handleLocationSearchFilterOptions,
              })}
            />
          </Grid>
          <Grid item className={classes.alignTextCenter}>
            <Button
              variant="contained"
              className={classes.width100}
              onClick={handleAddInclude}
              disabled={
                !locationValue ||
                includeLocations.map((loc) => loc.id).includes(locationValue.id) ||
                excludeLocations.map((loc) => loc.id).includes(locationValue.id)
              }
            >
              Accept this Jurisdiction
            </Button>
          </Grid>
          <Grid item className={classes.alignTextCenter}>
            <Typography variant="h4">OR</Typography>
          </Grid>
          <Grid item className={classes.alignTextCenter}>
            <Button
              variant="contained"
              color="error"
              className={classes.width100}
              onClick={handleAddExclude}
              disabled={
                !locationValue ||
                excludeLocations.map((loc) => loc.id).includes(locationValue.id) ||
                includeLocations.map((loc) => loc.id).includes(locationValue.id)
              }
            >
              Exclude this Jurisdiction
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <DualPriorityList
          includeItems={includeLocations}
          onIncludeItemsChanged={(locs) => onIncludeLocationsChanged(locs)}
          excludeItems={excludeLocations}
          onExcludeItemsChanged={(locs) => onExcludeLocationsChanged(locs)}
          includeTitle="Accept Jurisdictions"
          excludeTitle="Exclude Jurisdictions"
          getLabel={(loc) => <Location location={loc} />}
        />
      </Grid>
    </Grid>
  );
}

GeoSelector.propTypes = {
  classes: PropTypes.object,
  includeLocations: PropTypes.array,
  onIncludeLocationsChanged: PropTypes.func,
  excludeLocations: PropTypes.array,
  onExcludeLocationsChanged: PropTypes.func,
};

export default withStyles(PlaybookBuilderStyles)(GeoSelector);
