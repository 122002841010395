import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import io from 'socket.io-client';

import { Typography, Button, Dialog, DialogTitle } from '@mui/material';

import { useAuthUpdateContext } from './auth/AuthUpdateProvider';
import { GLOBAL_EVENT_WEB_SOCKET_ENDPOINT } from './endpoints.config';
import { setSocketId } from './redux/actions/globalEventActions';

const GlobalEventManager = ({ children = null }) => {
  const { accessToken } = useAuthUpdateContext();

  const dispatch = useDispatch();

  const [showBackdrop, setShowBackdrop] = useState(false);
  const handleOpen = () => {
    setShowBackdrop(true);
  };

  useEffect(() => {
    if (accessToken) {
      // establish connection to namespace with token
      console.log('creating global event socket...');
      const socket = io(GLOBAL_EVENT_WEB_SOCKET_ENDPOINT, {
        // https://socket.io/docs/v4/middlewares/#sending-credentials
        auth: {
          token: accessToken,
        },
      });
      // establish socket connection (think of this as a loop that continues executing
      socket.on('connect', () => {
        console.log('global event socket connected, Socket ID:', socket.id);
        dispatch(setSocketId(socket.id));
        // listen for changes (if the server emits a message)
        socket.on('global_event_update', () => {
          // in future, additional events can be fired, causing different behavior
          handleOpen();
        });
      });

      return () => {
        console.log('socket disconnected, Socket ID:', socket.id);
        socket.disconnect();
      };
    }
    return () => null;
  }, [accessToken]);

  return (
    <>
      <Dialog onClose={() => console.log('close was attempted')} open={showBackdrop}>
        <DialogTitle>Your tenant has changed. Please reload the page.</DialogTitle>
        <Button variant="contained" onClick={() => window.location.reload()}>
          <Typography>Click to reload</Typography>
        </Button>
      </Dialog>
      {children}
    </>
  );
};

GlobalEventManager.propTypes = {
  children: PropTypes.any,
};

export default GlobalEventManager;
