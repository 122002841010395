const LoginContentStyles = () => ({
  root: {
    height: '90vh',
  },
  logoImg: {
    height: 100,
  },
  button: {
    width: 200,
  },
  buttonWide: {
    width: 350,
  },
  linkGrid: {
    paddingTop: 20,
  },
});

export default LoginContentStyles;
