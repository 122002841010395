import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Grid, Typography } from '@mui/material';

import GridCellGroupLinkStyles from './styles';

const GridCellGroupText = ({ classes, textList = [] }) => {
  const [text, setText] = useState();

  useEffect(() => {
    setText(
      textList &&
        textList
          .map(({ name }) => name)
          .sort((a, b) => a.localeCompare(b))
          .join(', ')
    );
  }, [textList]);

  return text ? (
    <Grid container spacing={1}>
      <Grid item>
        <Typography className={classes.text}>{text}</Typography>
      </Grid>
    </Grid>
  ) : null;
};

GridCellGroupText.propTypes = {
  classes: PropTypes.object.isRequired,
  textList: PropTypes.array,
};

export default withStyles(GridCellGroupLinkStyles)(GridCellGroupText);
