import {
  SET_USER_FLOW_IS_ENABLED,
  SET_USER_FLOW_TOKEN,
  SET_USER_HASH,
} from '../action-types/userflowTypes';

export const SetUserHash = (userHash) => ({
  type: SET_USER_HASH,
  payload: userHash,
});

export const SetUserFlowToken = (token) => ({
  type: SET_USER_FLOW_TOKEN,
  payload: token,
});

export const SetUserFlowIsEnabled = (isEnabled) => ({
  type: SET_USER_FLOW_IS_ENABLED,
  payload: isEnabled,
});
