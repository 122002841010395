import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Grid } from '@mui/material';

import { BaseDialog } from '../BaseDialog';

const CustomYesNoDialog = ({
  open,
  title,
  subtitle,
  handleCloseWithConfirmation,
  showContractIcon = false,
  isDangerous = true,
  confirmText = 'Delete',
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = () => {
    setIsOpen(false);
    handleCloseWithConfirmation(false);
  };

  const handleConfirm = (evt) => {
    evt.preventDefault();
    setIsOpen(false);
    handleCloseWithConfirmation(true);
  };

  return (
    <div>
      <BaseDialog
        open={isOpen}
        handleClose={handleClose}
        showContractIcon={showContractIcon}
        purpose="confirmation prompt"
        title={title}
        subtitle={subtitle}
      >
        <Grid item>
          <Button onClick={handleClose} variant="outlined" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleConfirm}
            color={isDangerous ? 'error' : 'secondary'}
            variant="contained"
            fullWidth
          >
            {confirmText}
          </Button>
        </Grid>
      </BaseDialog>
    </div>
  );
};

CustomYesNoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  handleCloseWithConfirmation: PropTypes.func.isRequired,
  showContractIcon: PropTypes.bool,
};

export default CustomYesNoDialog;
