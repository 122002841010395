import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import {
  LinearProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import InsertTextPreviewTableStyles from './styles';
import ThresholdPreviewRow from './ThresholdPreviewRow';

const InsertTextPreviewTable = ({
  classes,
  data = null,
  headerText,
  aboveTextFirst = true,
  firstTextIsInserts = true,
}) => {
  const [firstData, setFirstData] = useState([]);
  const [secondData, setSecondData] = useState([]);

  useEffect(() => {
    if (data) {
      const first = aboveTextFirst ? data.above : data.below;
      const second = aboveTextFirst ? data.below : data.above;
      setFirstData(first && first.length > 0 ? first : []);
      setSecondData(second && second.length > 0 ? second : []);
    }
  }, [data]);

  return (
    <TableContainer className="pt-3">
      <Table className={classes.insertTextTable}>
        <TableHead className={classes.insertTextTableHead}>
          {data !== null ? (
            <TableRow>
              <TableCell>
                <Typography variant="body2">
                  {headerText || 'Below are text examples'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Similarity Score</Typography>
              </TableCell>
              <TableCell style={{ width: 200 }}>
                <Typography variant="body2" className="text-center d-inline mr-3 mt-1">
                  Threshold Status
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell>
                <Typography variant="body2">Loading text examples</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        {data !== null ? (
          <TableBody>
            {firstData && firstData.length > 0
              ? firstData.map(({ id, text, score }) => (
                  <ThresholdPreviewRow
                    id={id}
                    text={text}
                    score={score}
                    classes={classes}
                    positiveInsert={firstTextIsInserts}
                  />
                ))
              : null}
            {secondData && secondData.length > 0
              ? secondData.map(({ id, text, score }) => (
                  <ThresholdPreviewRow
                    id={id}
                    text={text}
                    score={score}
                    classes={classes}
                    positiveInsert={!firstTextIsInserts}
                  />
                ))
              : null}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow id="text-preview-loading" key="text-preview-loading">
              <TableCell className={classes.insertTextCell}>
                <Typography variant="body1"> </Typography>
                <LinearProgress color="secondary" />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

InsertTextPreviewTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  headerText: PropTypes.string.isRequired,
  aboveTextFirst: PropTypes.bool,
  firstTextIsInserts: PropTypes.bool,
};

export default withStyles(InsertTextPreviewTableStyles)(InsertTextPreviewTable);
