import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import { Popover } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const MouseOverPopover = ({ anchorEl = null, handlePopoverClose, children = null }) => {
  const open = Boolean(anchorEl);

  const classes = useStyles();

  return (
    <div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {children}
      </Popover>
    </div>
  );
};

MouseOverPopover.propTypes = {
  anchorEl: PropTypes.any,
  handlePopoverClose: PropTypes.func.isRequired,
  children: PropTypes.any,
};

export default MouseOverPopover;
