import Colors from '../../styles/Colors';

const GridCellMenuStyles = () => ({
  statusBtn: {
    textTransform: 'none',
    borderRadius: 20,
    minHeight: 0,
    height: 25,
    fontSize: '.75em',
    letterSpacing: 0,
    fontWeight: 400,
    color: 'black',
  },
  dot: {
    height: '8px',
    width: '8px',
    marginRight: '5px',
    backgroundColor: Colors.blue,
    borderRadius: '50%',
    display: 'inline-block',
  },
  menuDot: {
    marginLeft: 10,
  },
});

export default GridCellMenuStyles;
