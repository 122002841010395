import React from 'react';

import { AttachFile, Description, PictureAsPdf } from '@mui/icons-material';

export const handleDropZoneMsg = (
  msg,
  variant,
  setErr = (f) => f,
  setSuccess = (f) => f
) => {
  if (variant.toUpperCase() === 'ERROR') {
    setErr(msg);
  } else if (variant.toUpperCase() === 'SUCCESS' || variant.toUpperCase() === 'INFO') {
    setSuccess(msg);
  } else {
    console.log('Unhandled message variant:', variant);
  }
};

export const handlePreviewIcon = (fileObject, iconClasses) => {
  const { type } = fileObject.file;
  const iconProps = {
    className: iconClasses.image,
  };

  switch (type) {
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <Description {...iconProps} />;
    case 'application/pdf':
      return <PictureAsPdf {...iconProps} />;
    case 'application/json':
      return <AttachFile {...iconProps} />;
    default:
      return <AttachFile {...iconProps} />;
  }
};
