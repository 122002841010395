import axios from 'axios';
import { FETCH_GEO } from '../endpoints.config';
import { GEO_OPTIONS } from '../config';

const GeoService = {
  fetchGeoData: async function fetchGeoData(headers, params) {
    const { data } = await axios.get(`${FETCH_GEO}`, { headers, params });
    return data.searchResults.map((sr) => sr[0]);
  },

  getGeoOptions: function getGeoOptions(page) {
    if (!page) {
      return GEO_OPTIONS;
    }
    return Object.entries(GEO_OPTIONS)
      .filter(([_, { showInOnly }]) => !showInOnly || showInOnly.includes(page))
      .reduce(
        (prev, [goKey, goOption]) => ({
          ...prev,
          [goKey]: goOption,
        }),
        {}
      );
  },

  isGeo: function isGeo(item) {
    if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
      return 'feature code' in item;
    }
    return false;
  },

  isCity: function isCity(item) {
    if (this.isGeo(item)) {
      return ['ADM3', 'PPL', 'PPLA', 'PPLA2', 'PPLA3'].includes(item['feature code']);
    }
    return false;
  },

  isCounty: function isCounty(item) {
    return ['ADM2'].includes(item['feature code']);
  },
};

Object.freeze(GeoService);
export default GeoService;
