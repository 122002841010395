import Colors from '../../styles/Colors';

const CustomStepIconStyles = () => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: Colors.grey3,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
});

export default CustomStepIconStyles;
