import { CLEAR_RULE_REDUX_STORE, SET_RULE_DATA } from '../action-types/ruleTypes';

const initialState = {
  data: null,
};

function ruleReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RULE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case CLEAR_RULE_REDUX_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default ruleReducer;
