// GLOBAL EVENT
import {
  SET_AUTHENTICATED,
  SET_CONFIG,
  SET_FINISH_AUTHENTICATION,
  SET_USER,
  UPDATE_MOUNT_COUNT,
} from '../action-types/authActionTypes.js';

export function setAuthenticated(isAuthenticated) {
  return {
    type: SET_AUTHENTICATED,
    payload: isAuthenticated,
  };
}

export function setConfig(config) {
  return {
    type: SET_CONFIG,
    payload: config,
  };
}

export function setAuthenticationFinished(isFinished) {
  return {
    type: SET_FINISH_AUTHENTICATION,
    payload: isFinished,
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    payload: user,
  };
}

export function updateMountCount() {
  return {
    type: UPDATE_MOUNT_COUNT,
  };
}
