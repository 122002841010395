import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import withStyles from '@mui/styles/withStyles';
import { Grid, Typography } from '@mui/material';

import Colors from '../../styles/Colors';
import { DockTextBlock } from '../DockTextBlock';
import ChartBarStyles from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

let options = {
  responsive: true,
  maintainAspectRatio: false,
  maxBarThickness: 100,
  plugins: {
    legend: {
      display: true,
      position: 'right',
    },
  },
};

const verticalBarScales = {
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        font: {
          size: 14,
        },
        precision: 0,
      },
    },
  },
};

const stackedBarScales = {
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 14,
        },
        major: true,
      },
      stacked: true,
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        precision: 0,
      },
      stacked: true,
    },
  },
};

const tooltip = {
  callbacks: {
    afterBody: () => 'minutes',
  },
};

const ChartBar = ({
  classes,
  input,
  stacked = false,
  legend = true,
  title = '',
  titleMetric = '',
}) => {
  const { labels, datasets } = input;

  let data;

  if (stacked) {
    data = {
      labels,
      datasets: datasets.map((value, index) => ({
        ...value,
        backgroundColor: Colors.colorPalette.at(index % Colors.colorPalette.length),
      })),
    };
  } else {
    data = {
      labels,
      datasets: [{ ...datasets, backgroundColor: Colors.colorPalette }],
    };
  }

  const scales = stacked ? stackedBarScales.scales : verticalBarScales.scales;
  const plugins = stacked
    ? { legend: { display: legend } }
    : { legend: { display: legend }, tooltip };

  options = { ...options, scales, plugins };

  if (data.datasets && data.datasets.length) {
    return (
      <Grid container direction="column" spacing={0} className={classes.root}>
        {title && titleMetric && (
          <Grid
            container
            item
            alignItems="center"
            spacing={1}
            className={classes.titleGrid}
          >
            <Grid item>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">{titleMetric}</Typography>
            </Grid>
          </Grid>
        )}
        <Grid item className={!title && classes.chartGrid}>
          <Bar options={options} data={data} className={title && classes.customHeight} />
        </Grid>
      </Grid>
    );
  }

  return <DockTextBlock />;
};

ChartBar.propTypes = {
  classes: PropTypes.object.isRequired,
  stacked: PropTypes.bool,
  legend: PropTypes.bool,
  input: PropTypes.object.isRequired,
  title: PropTypes.string,
  titleMetric: PropTypes.string,
};

export default withStyles(ChartBarStyles)(ChartBar);
