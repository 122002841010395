import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Grid, Link } from '@mui/material';

import GridCellLinkStyles from '../GridCellLink/styles';

const GridCellLinkSimple = ({ classes, title, path }) => (
  <Grid container spacing={1}>
    <Grid item className={`${classes.text} ${classes.link}`}>
      <Link component={NavLink} to={path} underline="hover">
        {title}
      </Link>
    </Grid>
  </Grid>
);

GridCellLinkSimple.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default withStyles(GridCellLinkStyles)(GridCellLinkSimple);
