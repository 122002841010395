import {
  CLEAR_PLAYBOOK_BUILDER_REDUX_STORE,
  SET_AVAILABLE_SECTIONS,
  SET_FXI_DATA,
  SET_FXI_DEFINED_TERMS_RAW,
  SET_FXI_DEFINED_TERMS_TEMPLATED,
  SET_FXI_PRONOUN_RAW,
  SET_FXI_PRONOUN_TEMPLATED,
  SET_FXI_SECTION,
  SET_FXI_TRAIN_TYPE,
  SET_IMPORTING_RULES,
  SET_TEXT_DATA,
} from '../action-types/playbookBuilderTypes';

const initialState = {
  textData: null,
  availableSections: [],
  fxiDefinedTermsRaw: '',
  fxiPronounRaw: '',
  fxiDefinedTermsTemplated: '',
  fxiPronounTemplated: '',
  fxiSection: '',
  fxiTrainType: '',
  fxiData: null,
  importingRules: 0,
};

function playbookBuilderReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEXT_DATA:
      return {
        ...state,
        textData: action.payload,
      };
    case SET_AVAILABLE_SECTIONS:
      return {
        ...state,
        availableSections: action.payload,
      };
    case SET_FXI_DEFINED_TERMS_RAW:
      return {
        ...state,
        fxiDefinedTermsRaw: action.payload,
      };
    case SET_FXI_PRONOUN_RAW:
      return {
        ...state,
        fxiPronounRaw: action.payload,
      };
    case SET_FXI_DEFINED_TERMS_TEMPLATED:
      return {
        ...state,
        fxiDefinedTermsTemplated: action.payload,
      };
    case SET_FXI_PRONOUN_TEMPLATED:
      return {
        ...state,
        fxiPronounTemplated: action.payload,
      };
    case SET_FXI_SECTION:
      return {
        ...state,
        fxiSection: action.payload,
      };
    case SET_FXI_TRAIN_TYPE:
      return {
        ...state,
        fxiTrainType: action.payload,
      };
    case SET_FXI_DATA:
      return {
        ...state,
        fxiData: action.payload,
      };
    case SET_IMPORTING_RULES:
      return {
        ...state,
        importingRules: action.payload,
      };
    case CLEAR_PLAYBOOK_BUILDER_REDUX_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default playbookBuilderReducer;
