import axios from 'axios';

import {
  FIND_SIMILAR_CONTEXT_ENDPOINT,
  FIND_SIMILAR_EDIT_ENDPOINT,
  FIND_SIMILAR_GENERAL_ENDPOINT,
  FIND_SIMILAR_PRESENCE_ENDPOINT,
  IMPORT_RULES_ENDPOINT,
  METRICS_ENDPOINT,
  MODELS_ENDPOINT,
  ONLYOFFICE_CONFIG_ENDPOINT,
  TEXT_ENDPOINT,
  TRAINING_DELETE_BY_IDS_ENDPOINT,
  TRAINING_MODIFY_EDITS_ENDPOINT,
  TRAINING_RULE_SECTIONS_ENDPOINT,
  TRAINING_RULES_ANSWER_QUESTIONS_ENDPOINT,
  TRAINING_RULES_ENDPOINT,
  TRAINING_RULES_IMPORT_RULES_ENDPOINT,
  USER_INFO_TRACK_ENDPOINT,
} from '../endpoints.config';

const CONTENT_TYPE_JSON = 'application/json;charset=UTF-8';

export const addContentHeader = (headers) => {
  const updatedHeaders = headers;
  updatedHeaders['Content-Type'] = CONTENT_TYPE_JSON;
  return updatedHeaders;
};

export async function GetOnlyOfficeConfig(headers, contractId) {
  const response = await axios.get(
    `${ONLYOFFICE_CONFIG_ENDPOINT}?documentId=${contractId}`,
    { headers }
  );
  if (response.status === 200) {
    return response.data;
  }
  throw Error(
    `Request for only office config with contract ID ${contractId} failed with error ${response.status} ${response.statusText}`
  );
}

export async function GetPresenceThresholdExamples(headers, textData) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(
    FIND_SIMILAR_PRESENCE_ENDPOINT,
    { ...textData },
    { headers: updatedHeaders }
  );
  if (response.status === 200) {
    return response.data;
  }
  throw Error(
    `Request for similar presence threshold data failed with error ${response.status} ${response.statusText}`
  );
}

export async function GetContextThresholdExamples(headers, textData) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(
    FIND_SIMILAR_CONTEXT_ENDPOINT,
    { ...textData },
    { headers: updatedHeaders }
  );
  if (response.status === 200) {
    return response.data;
  }
  throw Error(
    `Request for similar context threshold data failed with error ${response.status} ${response.statusText}`
  );
}

export async function FindSimilar(headers, data) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(
    FIND_SIMILAR_GENERAL_ENDPOINT,
    { ...data },
    { headers: updatedHeaders }
  );
  if (response.status === 200) {
    return response.data;
  }
  throw Error(
    `Request for find similar data failed with error ${response.status} ${response.statusText}`
  );
}

export async function EditSimilar(headers, data) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(
    FIND_SIMILAR_EDIT_ENDPOINT,
    { ...data },
    { headers: updatedHeaders }
  );
  if (response.status === 200) {
    return response.data;
  }
  throw Error(
    `Request for edit similar data failed with error ${response.status} ${response.statusText}`
  );
}

export async function GetRule(headers, id) {
  const response = await axios.get(`${TRAINING_RULES_ENDPOINT}/${id}`, { headers });
  if (response.status === 200) {
    return response.data;
  }
  throw Error(
    `Request for rule ID ${id} failed with error ${response.status} ${response.statusText}`
  );
}

export async function UpdateRuleText(headers, id, name, description) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.put(
    `${TRAINING_RULES_ENDPOINT}/${id}`,
    { name, description },
    { headers: updatedHeaders }
  );
  if (response.status === 200) {
    return response.data;
  }
  throw Error(
    `Request to update rule text failed with error ${response.status} ${response.statusText}`
  );
}

export async function UpdateRuleSection(headers, id, section) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.put(
    `${TRAINING_RULES_ENDPOINT}/${id}/${section}`,
    {},
    { headers: updatedHeaders }
  );
  if (response.status === 200) {
    return true;
  }
  throw Error(
    `Request to update rule text failed with error ${response.status} ${response.statusText}`
  );
}

export async function MergeIntoRule(headers, id, target) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.put(
    `${TRAINING_RULES_ENDPOINT}/${id}/merge/${target}`,
    {},
    { headers: updatedHeaders }
  );
  if (response.status === 200) {
    return true;
  }
  throw Error(
    `Request to update rule text failed with error ${response.status} ${response.statusText}`
  );
}

export async function UpsertEditedSentence(
  headers,
  data,
  model,
  ruleId,
  ruleStrengthDivisor,
  maxRuleStrength
) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.put(
    `${TRAINING_MODIFY_EDITS_ENDPOINT}`,
    {
      data,
      model,
      ruleId,
      ruleStrengthDivisor,
      maxRuleStrength,
      action: 'upsert',
    },
    { headers: updatedHeaders }
  );
  if (!response || !response.data || !response.data.SUCCESS) {
    console.log('Error! Upsert training data failed.');
    return false;
  }
  return true;
}

export async function DeleteTrainingDataByIds(
  headers,
  ids,
  model,
  ruleId,
  ruleStrengthDivisor,
  maxRuleStrength
) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.put(
    `${TRAINING_DELETE_BY_IDS_ENDPOINT}`,
    {
      ids,
      model,
      ruleId,
      ruleStrengthDivisor,
      maxRuleStrength,
      action: 'delete',
    },
    { headers: updatedHeaders }
  );
  if (!response || !response.data || !response.data.SUCCESS) {
    console.log('Error! Delete training data by ids failed.');
    return false;
  }
  return true;
}

export async function GetRuleCommentsUnique(headers, id) {
  const response = await axios.get(`${TRAINING_RULES_ENDPOINT}/${id}?comments=true`, {
    headers,
  });
  if (response.status === 200) {
    return response.data;
  }
  throw Error(
    `Request for rule ID ${id} comments failed with error ${response.status} ${response.statusText}`
  );
}

export async function FetchImportRulesData(headers, modelId, params) {
  const response = await axios
    .get(`${IMPORT_RULES_ENDPOINT}/${modelId}?${params}`, { headers })
    .catch((err) => {
      console.log('Error! Failed to retrieve import rules data with error:', err);
    });
  if (!response.data) {
    console.log('Error! No import rules data retrieved.');
    return {};
  }
  return response.data;
}

export async function GetModelData(headers, modelId, params = '') {
  const response = await axios
    .get(`${MODELS_ENDPOINT}/${modelId}?${params}`, { headers })
    .catch((err) => {
      console.log('Error! Failed to retrieve model data with error:', err);
      return err;
    });
  if (!response || !response.data || !response.data.SUCCESS) {
    console.log('Error! No model data retrieved.');
    return {
      SUCCESS: false,
      data: null,
    };
  }
  return {
    SUCCESS: true,
    ...response.data,
  };
}

export function UpdateModelAttributes(headers, data) {
  const updatedHeaders = addContentHeader(headers);
  const {
    _id: id,
    name,
    comment,
    isDeleteProtected,
    stage,
    defaultQuestionnaire,
    groups,
    enableBullseyeByEmail,
    generateAddendum,
    addendumTemplate,
    emailOnUpload,
    convertPdfToDocx,
    bullseyeTrainingModels,
    nano_slot_model,
    enableRiskRouting,
    enableNewFsdMethod,
  } = data;
  return axios.patch(
    `${MODELS_ENDPOINT}/${id}`,
    {
      name,
      comment,
      isDeleteProtected,
      stage,
      defaultQuestionnaire,
      groups,
      enableBullseyeByEmail,
      generateAddendum,
      addendumTemplate,
      emailOnUpload,
      convertPdfToDocx,
      bullseyeTrainingModels,
      nano_slot_model,
      enableRiskRouting,
      enableNewFsdMethod,
    },
    {
      headers: updatedHeaders,
    }
  );
}

export async function GetTrainingModelSections(headers, id) {
  const response = await axios
    .get(`${TRAINING_RULE_SECTIONS_ENDPOINT}/${id}`, { headers })
    .catch((err) => {
      console.log(
        'Error! Failed to retrieve training rule sections data with error:',
        err
      );
      return [];
    });
  if (!response.data) {
    console.log('Error! No training rule sections data retrieved.');
    return [];
  }
  return response.data;
}

export async function GetTrainingModelSectionsForContractType(headers, contractType) {
  const response = await axios
    .get(`${TRAINING_RULE_SECTIONS_ENDPOINT}/contractType/${contractType}`, { headers })
    .catch((err) => {
      console.log(
        `Error! Failed to retrieve training rule sections for contract type ${contractType} data with error:`,
        err
      );
      return [];
    });
  if (!response.data) {
    console.log(
      `Error! No training rule sections data for contract type ${contractType} retrieved.`
    );
    return [];
  }
  return response.data;
}

export async function GetTextBySection(headers, sectionId, modelId, rule = null) {
  const queryString = `?sectionId=${sectionId}&modelId=${modelId}${rule ? `&rule=${rule}` : ''}`;
  const response = await axios
    .get(`${TEXT_ENDPOINT}${queryString}`, { headers })
    .catch((err) => {
      console.log('Error! Failed to retrieve text data by section ID with error:', err);
      return [];
    });
  if (!response.data) {
    console.log('Error! No text data retrieved.');
    return [];
  }
  return response.data;
}

export async function GetTextBySearch(headers, search, sectionId, modelId, rule = null) {
  const queryString = `?search=${search}&sectionId=${sectionId}&modelId=${modelId}${rule ? `&rule=${rule}` : ''}`;
  const response = await axios
    .get(`${TEXT_ENDPOINT}${queryString}`, { headers })
    .catch((err) => {
      console.log('Error! Failed to retrieve text data by search with error:', err);
      return [];
    });
  if (!response.data) {
    console.log('Error! No text data retrieved.');
    return [];
  }
  return response.data;
}

export async function CreateTextData(headers, data) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(`${TEXT_ENDPOINT}`, data, {
    headers: updatedHeaders,
  });
  if (response.status === 200) {
    return true;
  }
  throw Error('Error! Failed to create text data');
}

export async function DeleteRule(headers, modelId, ruleId) {
  const response = await axios
    .delete(`${TRAINING_RULES_ENDPOINT}`, {
      headers,
      data: {
        model: modelId,
        rule: ruleId,
      },
    })
    .catch((err) => {
      console.log('Error! Failed to delete training rule with error:', err);
      return false;
    });
  if (!response.data || !response.data.SUCCESS) {
    console.log('Error! Delete failed.');
    return false;
  }
  return response.data.SUCCESS;
}

export async function WriteMetrics(headers, data) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(
    `${METRICS_ENDPOINT}`,
    { ...data },
    { headers: updatedHeaders }
  );
  if (!response || !response.data || !response.data.SUCCESS) {
    console.log('Error! Writing metric failed.');
    return false;
  }
  return true;
}

export async function SaveImportRules(headers, data) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(
    `${TRAINING_RULES_IMPORT_RULES_ENDPOINT}`,
    { ...data },
    { headers: updatedHeaders }
  );
  if (!response || !response.data || !response.data.SUCCESS) {
    console.log('Error! Writing metric failed.');
    return false;
  }
  return true;
}

export async function SaveAnswerQuestionsRules(headers, data) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(`${TRAINING_RULES_ANSWER_QUESTIONS_ENDPOINT}`, data, {
    headers: updatedHeaders,
  });
  if (response.status !== 200) {
    console.log('Error! Writing metric failed.');
    return false;
  }
  return true;
}

export async function DeleteModel(headers, id) {
  const response = await axios
    .delete(`${MODELS_ENDPOINT}/${id}`, {
      headers,
    })
    .catch((err) => {
      console.log('Error! Failed to delete model with error:', err);
      return false;
    });
  if (!response.data || !response.data.SUCCESS) {
    console.log('Error! Delete model failed.');
    return false;
  }
  return response.data.SUCCESS;
}

export async function GetUserHash(headers) {
  const response = await axios.get(USER_INFO_TRACK_ENDPOINT, { headers }).catch((err) => {
    console.log(
      'Error! Failed to retrieve user information tracking data with error:',
      err
    );
    return [];
  });
  if (!response.data) {
    console.log('Error! No user information tracking data retrieved.');
    return [];
  }
  return response.data;
}
