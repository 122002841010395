import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { CircularProgress, Grid, Typography } from '@mui/material';

import CircularLoadingStyles from './styles';

const CircularLoading = ({ classes, error = false, size = '50px' }) => (
  <Grid container alignItems="center" justifyContent="center" className={classes.root}>
    {!error ? (
      <Grid item>
        <CircularProgress size={size} />
      </Grid>
    ) : (
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h2">Error loading page</Typography>
        </Grid>
      </Grid>
    )}
  </Grid>
);

CircularLoading.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  size: PropTypes.string,
};

export default withStyles(CircularLoadingStyles)(CircularLoading);
