import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';
import Colors from '../../styles/Colors';

const ContentWindowHighlighter = ({
  classes,
  prefixText = '',
  text,
  highlightWordCount,
}) => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const textPosition = urlSearchParams.get('textPosition');
  const insertAfter = textPosition === 'after';
  const textSplit = text.split(' ');
  const textSplitLen = textSplit.length;
  const highlightText = textSplit.map((word, idx) => {
    if (insertAfter) {
      return (
        <span
          style={{
            backgroundColor: `${textSplitLen - highlightWordCount <= idx ? Colors.textHighlight : ''}`,
          }}
          className={classes.textHighlighting}
        >
          {`${word} `}
        </span>
      );
    }
    return (
      <span
        style={{
          backgroundColor: `${idx + 1 <= highlightWordCount ? Colors.textHighlight : ''}`,
        }}
        className={classes.textHighlighting}
      >
        {`${word}${textSplitLen - 1 > idx ? ' ' : ''}`}
      </span>
    );
  });
  const prefix = (
    <span className={`${classes.textHighlighting} ${classes.textInsertion}`}>
      {prefixText}{' '}
    </span>
  );
  if (insertAfter) {
    return [...highlightText, prefix];
  }
  return [prefix, ...highlightText];
};

ContentWindowHighlighter.propTypes = {
  classes: PropTypes.object.isRequired,
  prefixText: PropTypes.string,
  text: PropTypes.string.isRequired,
  highlightWordCount: PropTypes.number.isRequired,
};

export default withStyles(PlaybookBuilderStyles)(ContentWindowHighlighter);
