// GENERAL
export const SET_CURRENT_QUESTION = 'setCurrentQuestion';
export const SET_CURRENT_SECTION = 'setCurrentSection';
export const RESET_ANSWER_QUESTIONS = 'resetAnswerQuestions';
export const SET_SELECTED_QUESTIONS = 'setSelectedQuestions';
// STATUS
export const SET_SECTION_STATUS = 'setSectionStatus';
export const SET_SECTION_STATUSES = 'setSectionStatuses';
export const DELETE_SECTION_STATUS = 'deleteSectionStatus';
// DATA
export const SET_QUESTIONNAIRE = 'setQuestionnaire';
export const SET_ANSWER = 'setAnswer';
export const SET_ANSWER_PREVIEWS = 'setAnswerPreviews';
export const SET_QUESTION_TYPES = 'setQuestionTypes';
