import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Select, MenuItem } from '@mui/material';

import LabeledSelectStyles from './styles';

const LabeledSelect = ({
  value,
  setValue,
  title,
  items = [],
  keyField = '_id',
  valueField = 'name',
  width = null,
  classes,
  visible = true,
  extraMenuItems = [],
  styleProps = {},
  variant = 'outlined',
}) =>
  visible ? (
    <Select
      variant={variant}
      displayEmpty
      value={value}
      sx={{ height: '40px' }}
      inputProps={{
        'aria-label': `Select ${title}`,
      }}
      style={{
        width,
        ...styleProps,
      }}
      renderValue={(option) => {
        if (option && items.length > 0) {
          const optionValue = items.filter((item) => item[keyField] === option)[0][
            valueField
          ];
          return `${title ? `${title}: ` : ''}${optionValue}`;
        }
        return `${title ? 'Select: ' : ''}${title}`;
      }}
      className={classes.basicBackground}
      onChange={(evt) => setValue(evt.target.value)}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      <MenuItem value="" disabled>
        Select: {title}
      </MenuItem>
      {items.map((item) => (
        <MenuItem key={item[keyField]} value={item[keyField]}>
          {item[valueField]}
        </MenuItem>
      ))}
      {extraMenuItems && extraMenuItems.map((item) => item)}
    </Select>
  ) : null;

LabeledSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
  keyField: PropTypes.string,
  valueField: PropTypes.string,
  width: PropTypes.number,
  visible: PropTypes.bool,
  extraMenuItems: PropTypes.array,
  styleProps: PropTypes.object,
  variant: PropTypes.string,
};

export default withStyles(LabeledSelectStyles)(LabeledSelect);
