import React from 'react';

import { Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import POAStyles from '../../styles/POAStyles';
import { formatDateToStandardDateTime } from '../../utils/parsingUtils';
import moment from 'moment';

function PoaTextDetails({
  classes,
  title,
  submissionId,
  attachments,
  onDownload,
  hideHeading = false,
}) {
  function parseDateFromName(fileName) {
    if (!fileName) {
      return ['Unknown File', null];
    }
    const parts = fileName.split('.');
    if (parts.length < 3) {
      return [fileName, null];
    }
    const ext = parts.pop();
    const epoch = +parts.pop();
    if (Number.isNaN(epoch) || epoch < 946684800) {
      // 01-01-2000
      return [fileName, null];
    }
    const dateVal = moment(+epoch * 1000);
    if (!dateVal.isValid()) {
      return [fileName, null];
    }
    return [`${parts.join('.')}.${ext}`, dateVal];
  }

  function friendlyAttachments() {
    // Secondary sort
    const nameSort = (nameA, nameB) => nameA.localeCompare(nameB);

    return attachments
      .map((a) => [...parseDateFromName(a), a])
      .sort((itemA, itemB) => {
        const [nameA, dateA] = itemA;
        const [nameB, dateB] = itemB;
        // Unknown Dates go last
        if (dateA && !dateB) {
          return -1;
        }
        if (!dateA && dateB) {
          return 1;
        }
        if (!dateA && !dateB) {
          return nameSort(nameA, nameB);
        }
        // In ascending order
        return dateA < dateB ? -1 : dateA > dateB ? 1 : nameSort(nameA, nameB);
      });
  }

  const formattedAttachments = friendlyAttachments();

  return (
    <Stack spacing={1}>
      {!hideHeading && (
        <Grid item>
          <Typography className={`${classes.bold} ${classes.underline}`}>
            {title}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Stack>
          {formattedAttachments.map(([attachmentName, attachmentDate, srcName]) => {
            return (
              <Grid item key={attachmentName + attachmentDate}>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={3}>
                    <Typography className={classes.bold}>
                      {attachmentDate
                        ? formatDateToStandardDateTime(attachmentDate)
                        : 'Unknown Date'}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>
                      <Link
                        onClick={() => onDownload(srcName, submissionId, attachmentName)}
                        component="button"
                      >
                        {attachmentName}
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Stack>
      </Grid>
    </Stack>
  );
}

PoaTextDetails.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  submissionId: PropTypes.string,
  attachments: PropTypes.array,
  hideHeading: PropTypes.bool,
};

export default withStyles(POAStyles)(PoaTextDetails);
