import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Typography, Grid, Stack } from '@mui/material';

import POAStyles from '../../styles/POAStyles';
import { isFormattedDate } from '../../utils/parsingUtils';

function PoaTextDetails({
  classes,
  title,
  doc,
  detailsObj,
  selectFields,
  hideHeading = false,
}) {
  function getFieldValue(fieldName) {
    const value = doc[fieldName];
    switch (typeof value) {
      case 'string':
        return isFormattedDate(value) || value;
      case 'boolean':
        return value ? 'Yes' : 'No';
      case 'object':
        if (value instanceof Date) {
          return isFormattedDate(value);
        }
      default:
        if (!value) {
          return 'None';
        }
        if (Array.isArray(value)) {
          return value.join(', ');
        }
        return JSON.stringify(value);
    }
  }

  return (
    <Stack spacing={1}>
      {!hideHeading && (
        <Grid item>
          <Typography className={`${classes.bold} ${classes.underline}`}>
            {title}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Stack>
          {Object.entries(detailsObj)
            .filter(([fieldName]) =>
              selectFields ? selectFields.includes(fieldName) : true
            )
            .map(([fieldName, prettyName]) => (
              <Grid item key={fieldName}>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={3}>
                    <Typography className={classes.bold}>{prettyName}:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>{getFieldValue(fieldName)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Stack>
      </Grid>
    </Stack>
  );
}

PoaTextDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  doc: PropTypes.object.isRequired,
  detailsObj: PropTypes.object.isRequired,
  selectFields: PropTypes.array,
  hideHeading: PropTypes.bool,
};

export default withStyles(POAStyles)(PoaTextDetails);
