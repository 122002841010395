import {
  SET_INTAKE_FORM_VALUES,
  SET_FIELD_DEFINITIONS,
  SET_FIELD_DEPENDENCY_MAP,
  SET_POA_GEM_CONFIG,
} from '../action-types/poaTypes';

const initialState = {
  reactiveDependencyMap: {},
  intakeFormValues: {},
  fieldDefinitions: {},
  poaGemConfig: {},
};

export default function poaReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INTAKE_FORM_VALUES:
      return { ...state, intakeFormValues: action.payload };

    case SET_FIELD_DEFINITIONS:
      return { ...state, fieldDefinitions: action.payload };

    case SET_FIELD_DEPENDENCY_MAP:
      return { ...state, reactiveDependencyMap: action.payload };

    case SET_POA_GEM_CONFIG:
      return { ...state, poaGemConfig: action.payload };

    default:
      return state;
  }
}
