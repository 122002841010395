import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Grid, Typography } from '@mui/material';

import { EDIT_TYPES } from '../../config';
import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';
import CombineStyles from '../../utils/CombineStyles';
import EditTypePillStyles from './styles';

const EditTypePill = ({ classes, title = '', editType, noGrid = false }) => (
  <>
    {noGrid ? (
      <span
        className={`${classes.EditTypePill} ${classes[`EditTypePillStatus${editType}`]}`}
      >
        <Typography variant="body1" className={classes.EditTypePillText}>
          {editType}
        </Typography>
      </span>
    ) : (
      <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
        <Grid item>
          <Typography variant="body1">
            <strong>{title}</strong>
          </Typography>
        </Grid>
        <Grid item>
          <span
            className={`${classes.EditTypePill} ${classes[`EditTypePillStatus${editType}`]}`}
          >
            <Typography variant="body1" className={classes.EditTypePillText}>
              {editType}
            </Typography>
          </span>
        </Grid>
      </Grid>
    )}
  </>
);

EditTypePill.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  editType: PropTypes.oneOf(Object.values(EDIT_TYPES)).isRequired,
  noGrid: PropTypes.bool,
};

const combinedStyles = CombineStyles(PlaybookBuilderStyles, EditTypePillStyles);
export default withStyles(combinedStyles)(EditTypePill);
