import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';

import { MenuItem, Select } from '@mui/material';

import { getSlotStyleByContractType } from '../../utils/ClauseUtils';
import { GenerateDecorator, TemplateText } from '../../utils/TextEditorUtils';

const TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY = {
  TRAINING: 'TRAINING',
  CLAUSE: 'CLAUSE',
};

/*
    USAGE: Custom Draft.js plugin to insert slots into text
    HOW IT WORKS: Text is input by a user. When a slot is inserted, a new draft.js entity is created,
     and the decorator runs across all the text, highlighting the selected entity.
    SOURCES:
        https://draftjs.org/docs/advanced-topics-entities
        https://reactrocket.com/post/getting-started-with-draft-js-plugins/
        https://github.com/juliankrispel/draft-js-building-search-and-replace/blob/master/src/App.js
 */

const DraftJsInsertSlotPlugin = ({
  editorState,
  setEditorState,
  slotStrategy = TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY.TRAINING,
  setRaw = (f) => f,
  setTemplated = (f) => f,
  setCombined = (f) => f,
  contractType = 'default',
}) => {
  const SLOT_KEYS = Object.keys(getSlotStyleByContractType(contractType));

  const insertSlot = (e) => {
    const slotText = e.target.value;

    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    // track all text in editor for later reference in editing slots
    const blockTexts = [];
    contentState.getBlockMap().forEach((block) => {
      blockTexts.push(block.getText());
    });

    // Create an entity to isolate this text from the other text as an IMMUTABLE entity
    // -- this allows for proper deletion and text transformation effects
    // -- additionally, add in context data for future extraction
    const contentStateWithEntity = contentState.createEntity(slotText, 'IMMUTABLE', {
      raw: slotText,
      blockTexts,
      anchorOffset: selection.getAnchorOffset(),
      focusOffset: selection.getFocusOffset(),
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    let modifiedContent;
    if (selection.isCollapsed()) {
      // insert text
      modifiedContent = Modifier.insertText(
        contentStateWithEntity,
        selection.merge({
          anchorOffset: selection.getAnchorOffset(),
          focusOffset: selection.getFocusOffset(),
        }),
        `${slotText}`,
        editorState.getCurrentInlineStyle(),
        entityKey
      );
    } else {
      // replace text
      modifiedContent = Modifier.replaceText(
        contentStateWithEntity,
        selection.merge({
          anchorOffset: selection.getAnchorOffset(),
          focusOffset: selection.getFocusOffset(),
        }),
        `${slotText}`,
        editorState.getCurrentInlineStyle(),
        entityKey
      );
    }

    const modifiedEditorState = EditorState.createWithContent(
      modifiedContent,
      GenerateDecorator()
    );
    setEditorState(modifiedEditorState);
  };

  const getSlottedText = () => {
    /*
            Extract character index ranges for each entity (effectively decorated slots) in the text
            For example:
                text: Try this deal.
                desired slotted text: Try this ${deal}.
                entity range(s): [{start: 9, end: 13}] (start: inclusive, end: exclusive)
         */
    const currentContent = editorState.getCurrentContent();
    const currentText = currentContent.getPlainText();

    const textRanges = [];
    const entityRanges = [];
    let blockIdx = 0;
    let blockLength = 0;
    currentContent.getBlockMap().forEach((block) => {
      block.findEntityRanges(
        (character) => {
          // iterate across entities to get character index ranges
          const entityKey = character.getEntity();
          if (entityKey) {
            const { blockTexts, anchorOffset, focusOffset } = currentContent
              .getEntity(entityKey)
              .getData();
            textRanges.push({
              entityKey,
              text: currentContent.getEntity(entityKey).getData().raw,
              previous:
                blockTexts && blockTexts[blockIdx]
                  ? blockTexts[blockIdx].slice(anchorOffset, focusOffset)
                  : '',
              mutability: currentContent.getEntity(entityKey).getMutability(),
            });
          }
          return entityKey !== null;
        },
        (start, end) => {
          entityRanges.push({ start: start + blockLength, end: end + blockLength });
        }
      );
      blockLength += block.getLength() + 1;
      blockIdx += 1;
    });
    const mergedEntityRanges = [
      ...textRanges.map((tr, idx) => ({
        ...tr,
        ...entityRanges[idx],
      })),
    ];

    // Template text according to desired output result
    const buildReplaceText = () => {
      switch (slotStrategy) {
        case TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY.TRAINING:
          // EXAMPLE: ${<slot_text>:'<slot text>'}
          return (text, previous = '') => {
            if (SLOT_KEYS.includes(text)) {
              return `\${${text}:'${previous || text.replace('_', ' ')}'}`;
            }
            return text;
          };
        case TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY.CLAUSE:
          // EXAMPLE: ${<slot_text>}
          return (text) => (SLOT_KEYS.includes(text) ? `\${${text}}` : text);
        default:
          console.log('Error! Invalid slot template strategy');
          return (text) => `\${${text}:''}`;
      }
    };

    let templatedText = currentText;
    let OFFSET_INC;
    let indexOffset = 0;
    switch (slotStrategy) {
      case TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY.TRAINING:
        OFFSET_INC = 6;
        break;
      case TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY.CLAUSE:
        OFFSET_INC = 3;
        break;
      default:
        console.log('Error! Invalid slot template strategy');
        OFFSET_INC = 6;
        break;
    }
    mergedEntityRanges.forEach(({ start, end, text, previous, mutability }) => {
      const slottedText = currentText.substring(start, end);
      const replaceText = buildReplaceText();
      const replacement = replaceText(slottedText, previous);
      const startOffset = start + indexOffset;
      const endOffset = end + indexOffset;

      templatedText = TemplateText(templatedText, replacement, startOffset, endOffset);
      if (mutability === 'IMMUTABLE') {
        indexOffset += OFFSET_INC;
        if (slotStrategy === TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY.TRAINING) {
          // Increase by variable length of text being added:
          // e.g. "This agreement" => "This ${agreement: 'agreement'}"
          indexOffset += previous ? previous.length : text.length;
        }
      }
    });

    return {
      raw: currentText,
      templated: templatedText,
    };
  };

  useEffect(() => {
    const { raw, templated } = getSlottedText();
    setRaw(raw);
    setTemplated(templated);
    setCombined({ raw, templated });
  }, [editorState]);

  return (
    <Select
      variant="outlined"
      displayEmpty
      value=""
      inputProps={{
        'aria-label': 'Insert Slot',
      }}
      onChange={insertSlot}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      <MenuItem value="" disabled>
        Insert Slot
      </MenuItem>
      {SLOT_KEYS.map((definedSlotKey) => (
        <MenuItem key={definedSlotKey} value={definedSlotKey}>
          {definedSlotKey}
        </MenuItem>
      ))}
    </Select>
  );
};

DraftJsInsertSlotPlugin.propTypes = {
  editorState: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  slotStrategy: PropTypes.string,
  setRaw: PropTypes.func,
  setTemplated: PropTypes.func,
  setCombined: PropTypes.func,
  contractType: PropTypes.string,
};

export default DraftJsInsertSlotPlugin;
