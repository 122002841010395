import axios from 'axios';

import { QUESTIONS_ENDPOINT } from '../endpoints.config';
import { addContentHeader } from '../utils/requests';

export async function GetQuestionTypes(headers) {
  const response = await axios
    .get(`${QUESTIONS_ENDPOINT}/types`, { headers })
    .catch((err) => {
      console.log('Error! Failed to retrieve question types with error:', err);
      return err;
    });
  if (response.status !== 200 || !response.data) {
    throw Error('No question types found');
  }
  return response.data;
}

export async function CreateQuestion(headers, data) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.post(`${QUESTIONS_ENDPOINT}`, data, {
    headers: updatedHeaders,
  });
  if (response.status === 200) {
    return response;
  }
  throw Error('Error! Failed to create question');
}

export async function UpdateQuestion(headers, data) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.put(`${QUESTIONS_ENDPOINT}`, data, {
    headers: updatedHeaders,
  });
  if (response.status === 200) {
    return response;
  }
  throw Error('Error! Failed to update question');
}

export async function DeleteQuestion(headers, params) {
  const updatedHeaders = addContentHeader(headers);
  const response = await axios.delete(`${QUESTIONS_ENDPOINT}?${params}`, {
    headers: updatedHeaders,
  });
  if (response.status === 200) {
    return response;
  }
  throw Error('Error! Failed to delete question');
}
