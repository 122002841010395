import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

import { convertToTitle } from '../../utils/formatting';
import { ModifyJSONFields } from '../ModifyJSONFields';
import ModifyMixObjectFieldsStyles from './styles';

const ModifyMixObjectFields = ({
  classes,
  objectData,
  fieldName,
  addUpdatedFieldName = (f) => f,
  updateFieldData,
  setErrors = (f) => f,
  errors = null,
  setContainsError = (f) => f,
}) => {
  const [objectFieldData, setObjectFieldData] = useState(objectData);

  useEffect(() => {
    updateFieldData(objectFieldData, fieldName);
  }, [objectFieldData, setObjectFieldData]);

  const handleChange = (e) => {
    const { name } = e.target;

    if (e.target.type === 'text') {
      setObjectFieldData({ ...objectFieldData, [name]: e.target.value });
    }
    if (e.target.type === 'checkbox') {
      setObjectFieldData({ ...objectFieldData, [name]: e.target.checked });
    }

    addUpdatedFieldName(`${fieldName}.${name}`);
  };
  return (
    <>
      {Object.entries(objectFieldData)
        .sort()
        .map(([key, value]) => {
          if (typeof value === 'string') {
            return (
              <TextField
                key={`modify-mix-obj-field-${key}` || 'unknown-string-field'}
                className={classes.textField}
                label={convertToTitle(key)}
                name={key}
                type="text"
                value={value}
                onChange={handleChange}
                variant="outlined"
              />
            );
          }
          if (typeof value === 'object') {
            return (
              <div key={`modify-mix-obj-field-${key}`}>
                <ModifyJSONFields
                  label={convertToTitle(key)}
                  initialValue={value}
                  fieldName={`${fieldName}.${key}`}
                  setErrors={setErrors}
                  errors={errors}
                  setContainsError={setContainsError}
                  updateFieldData={updateFieldData}
                  addUpdatedFieldName={addUpdatedFieldName}
                />
              </div>
            );
          }
          if (typeof value === 'boolean') {
            return (
              <FormControlLabel
                key={`modify-mix-obj-field-${key}` || 'unknown-boolean-checkbox'}
                className={classes.formControlLabel}
                control={
                  <Checkbox
                    checked={value}
                    onChange={handleChange}
                    name={key}
                    color="primary"
                  />
                }
                label={convertToTitle(key)}
              />
            );
          }

          return null;
        })}
    </>
  );
};

ModifyMixObjectFields.propTypes = {
  classes: PropTypes.object.isRequired,
  objectData: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  updateFieldData: PropTypes.func.isRequired,
  addUpdatedFieldName: PropTypes.func,
  setErrors: PropTypes.func,
  errors: PropTypes.object,
  setContainsError: PropTypes.func,
};

export default withStyles(ModifyMixObjectFieldsStyles)(ModifyMixObjectFields);
