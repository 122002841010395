import { TSV_EXPORT_SEPARATOR } from '../../../config';
import { isBool, truthy } from '../../../utils/parsingUtils';

export const downloadBlob = (blob, fileName) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const downloadJsonAsFile = (
  data,
  prettyKeyMap = {},
  boolMap = { true: 'true', false: 'false' },
  separator = TSV_EXPORT_SEPARATOR,
  filename = 'data.tsv'
) => {
  const rows = Object.entries(data)
    .map(([key, value]) => {
      const prettyKey = prettyKeyMap?.[key] || key;
      if (isBool(value)) {
        const boolVal = truthy(value);
        return [prettyKey, boolMap?.[boolVal]].join(separator);
      }
      if (typeof value === 'string') {
        return [prettyKey, value.replaceAll(/\t/g, ' ').replaceAll(/\n/g, ' ')].join(
          separator
        );
      }
      if (typeof value === 'number') {
        return [prettyKey, value].join(separator);
      }
      if (!value) {
        return [prettyKey, ''];
      }
      return [prettyKey, JSON.stringify(value)];
    })
    .join('\n');
  const blob = new Blob([rows], { type: 'text/csv;charset=utf-8;' });
  downloadBlob(blob, filename);
};

export const exportCSV = (
  gridRef,
  filename = 'data.csv',
  separator = TSV_EXPORT_SEPARATOR,
  useColumnHeader = false,
  gridData = null
) => {
  const columns = gridRef.current.visibleColumns;

  const header = columns
    .map((c) => (useColumnHeader ? c.header : c.name))
    .join(separator);
  const rows = (gridData || gridRef.current.data).map((data) =>
    columns
      .map((c) => {
        if (c.name === 'customFieldsMulti') {
          const customField = data.customFieldsMulti.find((cf) => cf.name === c.id);
          return `"${customField?.dropDownList
            .filter(({ isSelected }) => isSelected)
            .map(({ name }) => name)
            .join(', ')}"`;
        }
        if (typeof data[c.id] === 'string') {
          // strip out tabs if present
          return data[c.id].replaceAll(/\t/g, ' ');
        }
        return data[c.id];
      })
      .join(separator)
  );

  const contents = [header].concat(rows).join('\n');
  const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

  downloadBlob(blob, filename);
};

export const exportCSV2 = ({
  columns,
  data,
  filename = 'data.csv',
  separator = TSV_EXPORT_SEPARATOR,
  useColumnHeader = false,
}) => {
  if (!data || !data.length) {
    throw new Error('Unable to export CSV with no data');
  }
  const header = columns
    .map((c) => (useColumnHeader ? c.header : c.name))
    .join(separator);
  const rows = data.map((data) =>
    columns
      .map((c) => {
        let cId = c.id;
        let gridData = data;
        if (cId?.includes('.')) {
          // Nested field
          const pathParts = c.id.split('.');
          let pathIdx = 0;
          while (pathIdx < pathParts.length - 1) {
            cId = pathParts[pathIdx];
            gridData = gridData[cId];
            pathIdx++;
          }
          cId = pathParts[pathIdx];
        }
        if (c.name === 'customFieldsMulti') {
          const customField = gridData.customFieldsMulti.find((cf) => cf.name === cId);
          return `"${customField?.dropDownList
            .filter(({ isSelected }) => isSelected)
            .map(({ name }) => name)
            .join(', ')}"`;
        }
        if (typeof gridData[cId] === 'string') {
          // strip out tabs if present
          return gridData[cId].replaceAll(/\t/g, ' ');
        }
        return gridData[cId];
      })
      .join(separator)
  );

  const contents = [header].concat(rows).join('\n');
  const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
  downloadBlob(blob, filename);
};
