import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import { SetAnswer } from '../../redux/actions/answerQuestionsActions';
import ListEditor from '../ListEditor/ListEditor';

function ListEdit() {
  const dispatch = useDispatch();
  const { currentSection, currentQuestion, currentQuestionId } = useSelector(
    (state) => state.AnswerQuestions
  );

  const { answer: currentAnswer } = currentQuestion;
  const [included, setIncluded] = useState(currentAnswer?.included || []);
  const [excluded, setExcluded] = useState(currentAnswer?.excluded || []);

  useEffect(() => {
    updateAnswer({
      included,
      excluded,
    });
  }, [included, excluded]);

  useEffect(() => {
    const { answer: ans } = currentQuestion || {};
    setIncluded(ans?.included || []);
    setExcluded(ans?.excluded || []);
  }, [currentQuestionId]);

  function updateAnswer(newAnswer) {
    return dispatch(SetAnswer(currentSection._id, currentQuestion._id, newAnswer));
  }

  return (
    <Grid container direction="column" spacing={2} style={{ marginTop: 10 }}>
      <Grid item>
        <ListEditor
          includeList={included}
          excludeList={excluded}
          setIncludeList={setIncluded}
          setExcludeList={setExcluded}
        />
      </Grid>
    </Grid>
  );
}

export default ListEdit;
