import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { TextField, Typography, Grid, Button } from '@mui/material';

import FastTrackStyles from '../../../../styles/FastTrackStyles';

const InputFormik = ({
  formikValues,
  formikErrors,
  formikTouched,
  formikHandleBlur,
  formikSetFieldValue,
  title,
  name,
  clarifyingText = null,
  quarterWidth = false,
  classes,
  xs = 12,
  disabled = false,
  onChangeOverride = null,
  actions = [],
  ...rest
}) => {
  let width = '100%';
  if (quarterWidth) {
    width = '74%';
  }

  return (
    <Grid container item spacing={1} direction="column" xs={xs}>
      <Grid item className={classes.zeroPaddingBottom}>
        <Typography className={classes.floatLeft}>{title}</Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid container spacing={2}>
            <Grid item className={`${classes.zeroPaddingBottom} ${classes.flexible}`}>
              <TextField
                id={`${name}_${new Date().toString()}`}
                onChange={(e) =>
                  onChangeOverride
                    ? onChangeOverride(e)
                    : formikSetFieldValue(name, e.target.value)
                }
                value={formikValues[name]}
                onBlur={formikHandleBlur}
                InputProps={{
                  className: classes.formikInput,
                  autoComplete: 'off',
                }}
                variant="outlined"
                style={{
                  width,
                }}
                className={classes.floatLeft}
                {...rest}
              />
            </Grid>
            {!!actions.length &&
              actions.map(({ title, action, disabled }) => (
                <Grid item>
                  <Button
                    key={title}
                    disabled={disabled}
                    className={classes.inputActionButton}
                    onClick={() => action(name, formikValues[name])}
                  >
                    {title}
                  </Button>
                </Grid>
              ))}
          </Grid>
          <Grid item className={classes.inputFormikRequiredText}>
            {formikTouched[name] && formikErrors[name] ? (
              <div className={classes.yupErrorMsg}>
                {title.replace('*', '')} is required.
              </div>
            ) : null}
          </Grid>
          {clarifyingText ? (
            <Grid item>
              <div className={classes.clarifyingText}>{clarifyingText}</div>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

InputFormik.propTypes = {
  classes: PropTypes.object.isRequired,
  formikValues: PropTypes.object.isRequired,
  formikErrors: PropTypes.object.isRequired,
  formikTouched: PropTypes.object.isRequired,
  formikHandleBlur: PropTypes.func.isRequired,
  formikSetFieldValue: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  clarifyingText: PropTypes.string,
  quarterWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  xs: PropTypes.number,
  disabled: PropTypes.bool,
  onChangeOverride: PropTypes.func,
};

export default withStyles(FastTrackStyles)(InputFormik);
