import Colors from '../../styles/Colors';

const PasswordResetByCodeStyles = () => ({
  titleMargin: {
    marginBottom: 10,
  },
  fullWidth: {
    width: '100%',
  },
  redText: {
    color: Colors.red,
  },
});

export default PasswordResetByCodeStyles;
