import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { AppBar, Toolbar } from '@mui/material';

import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import {
  BLACKBOILER_ADMIN_PERMISSION,
  CONTRACTS_PERMISSION,
  CLAUSE_EDITOR_PERMISSION,
  COMPANY_ADMIN_PERMISSION,
  PLAYBOOK_BUILDER_PERMISSION,
  FAST_TRACK_PERMISSION,
  METRICS_DASHBOARD_PERMISSION,
  METRICS_FASTTRACK_PERMISSION,
  POA_INTAKE_PERMISSION,
  POA_REVIEW_PERMISSION,
  POA_GEMS_PERMISSION,
} from '../../groups.config';
import { SetCurrentModelData } from '../../redux/actions/modelActions';
import { setErrMsg } from '../../redux/actions/snackbarActions';
import { GetModelBasics } from '../../requests/trainingModel';
import {
  HOME_ROUTE,
  CLAUSES_ROUTE,
  CONTRACTS_ROUTE,
  CONTRACTS_NEW_ROUTE,
  USERS_ROUTE,
  USERS_NEW_ROUTE,
  USERS_EDIT_ROUTE,
  SETTINGS_ROUTE,
  // MODEL_ROUTE,
  DOCSERVER_ROUTE,
  DOCSERVER_READONLY_ROUTE,
  MODEL_ROUTE,
  MODEL_EDIT_ROUTE,
  FAST_TRACK_ROUTE,
  ANALYTICS_ROUTE,
  REPORTING_ROUTE,
  TENANTS_ROUTE,
  POA_ROUTE,
  INTEGRATIONS_ROUTE,
} from '../../routes.config';
import { ExportButton } from '../ExportButton';
import AppBarBreadCrumb from './AppBarBreadCrumb';
import AppBarFull from './AppBarFull';
import AppBarUser from './AppBarUser';
import Styles from './styles';

function BaseAppBar({ classes }) {
  const [company, setCompany] = useState();
  const { pathname } = useLocation();
  const {
    getAuthHeader,
    hasRoleAuth,
    permissions,
    getFamilyName,
    getGivenName,
    getEmail,
    config,
  } = useAuthUpdateContext();

  const { id = null } = useParams();
  const dispatch = useDispatch();

  const modelData = useSelector((state) => state.Model.currentModelData, shallowEqual);
  const availableSections = useSelector(
    (state) => state.PlaybookBuilder.availableSections,
    shallowEqual
  );

  const setErr = (value) => dispatch(setErrMsg(value));

  useEffect(() => {}, [config]);

  useEffect(() => {
    if (id && !modelData) {
      // refresh model data basics asynchronously if unavailable and on a model path
      getAuthHeader().then((headers) => {
        GetModelBasics(headers, id)
          .then((model) => {
            dispatch(SetCurrentModelData(model));
          })
          .catch((err) => {
            setErr('Failed to retrieve model data');
            throw err;
          });
      });
    }
  }, [id]);

  const capitalize = (str) =>
    str
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

  const navList = [
    { label: 'Home', path: HOME_ROUTE, role: true },
    {
      label: 'Contracts',
      path: CONTRACTS_ROUTE,
      role: permissions.has(CONTRACTS_PERMISSION),
    },
  ];

  if (config) {
    // enable various services
    if (config.showClauseLibrary) {
      navList.push({
        label: 'Clause Library',
        path: CLAUSES_ROUTE,
        role: permissions.has(CLAUSE_EDITOR_PERMISSION),
      });
    }
    if (config.showPlaybookBuilder) {
      navList.push({
        label: 'Playbook Builder',
        path: MODEL_ROUTE,
        role: permissions.has(PLAYBOOK_BUILDER_PERMISSION),
      });
    }
    if (config.showFastTrack) {
      navList.push({
        label: 'Fast Track',
        path: FAST_TRACK_ROUTE,
        role: permissions.has(FAST_TRACK_PERMISSION),
      });
    }
    if (config.showPOA) {
      navList.push({
        label: 'PoA',
        path: POA_ROUTE,
        role:
          permissions.has(POA_INTAKE_PERMISSION) ||
          permissions.has(POA_GEMS_PERMISSION) ||
          permissions.has(POA_REVIEW_PERMISSION),
      });
    }
    if (config.showAnalytics) {
      navList.push({
        label: 'Analytics',
        path: ANALYTICS_ROUTE,
        role:
          permissions.has(METRICS_DASHBOARD_PERMISSION) ||
          permissions.has(METRICS_FASTTRACK_PERMISSION),
      });
    }
    if (config.showReportingDashboard) {
      navList.push({
        label: 'Reporting',
        path: REPORTING_ROUTE,
        role: permissions.has(BLACKBOILER_ADMIN_PERMISSION),
      });
    }
    if (config.showIntegrations) {
      navList.push({
        label: 'Integrations',
        path: INTEGRATIONS_ROUTE,
        role: permissions.has(BLACKBOILER_ADMIN_PERMISSION),
      });
    }
  }
  navList.push({
    label: 'Users',
    path: USERS_ROUTE,
    role: permissions.has(COMPANY_ADMIN_PERMISSION),
  });
  navList.push({
    label: 'Settings',
    path: SETTINGS_ROUTE,
    role: permissions.has(BLACKBOILER_ADMIN_PERMISSION),
  });
  if (!!config?.useTenantManagement && permissions.has(BLACKBOILER_ADMIN_PERMISSION)) {
    navList.push({
      label: 'Tenants',
      path: TENANTS_ROUTE,
      role: permissions.has(BLACKBOILER_ADMIN_PERMISSION),
    });
  }

  const playbookBuilderAnswerQuestionsLookupMap = {
    select: 'Answer Questions',
    status: 'Answer Questions',
  };
  if (availableSections) {
    availableSections.forEach(({ className, name }) => {
      playbookBuilderAnswerQuestionsLookupMap[className] = name;
    });
  }

  const renderSwitch = () => {
    const playbookBuilderAnswerQuestionsRegex = new RegExp(
      `${MODEL_EDIT_ROUTE}/[0-9a-z]*/rules/aq/.*`,
      'g'
    );

    const breadcrumbs = [];

    let fullName = `${getGivenName()} ${getFamilyName()}`;
    if (!getGivenName() || !getFamilyName) {
      fullName = getEmail();
    }

    switch (true) {
      case CONTRACTS_NEW_ROUTE === pathname:
        breadcrumbs.push(navList[1]);
        breadcrumbs.push({ label: 'Upload Contracts', path: pathname });
        return (
          <AppBar position="static" className={classes.root}>
            <Toolbar>
              <AppBarBreadCrumb pathname={pathname} breadCrumbs={breadcrumbs} />
            </Toolbar>
          </AppBar>
        );
      case DOCSERVER_ROUTE === pathname:
        breadcrumbs.push(navList[1]);
        breadcrumbs.push({ label: 'View Contract', path: pathname });
        return (
          <AppBar position="static" className={classes.root}>
            <Toolbar>
              <AppBarBreadCrumb
                pathname={pathname}
                breadCrumbs={breadcrumbs}
                buttonRight={<ExportButton />}
              />
            </Toolbar>
          </AppBar>
        );
      case DOCSERVER_READONLY_ROUTE === pathname:
        breadcrumbs.push(navList[1]);
        breadcrumbs.push({ label: 'View Archived', path: pathname });
        return (
          <AppBar position="static" className={classes.root}>
            <Toolbar>
              <AppBarBreadCrumb pathname={pathname} breadCrumbs={breadcrumbs} />
            </Toolbar>
          </AppBar>
        );
      case USERS_NEW_ROUTE === pathname:
        return (
          <AppBar position="static" elevation={1} className={classes.white}>
            <Toolbar>
              <AppBarUser />
            </Toolbar>
          </AppBar>
        );
      case USERS_EDIT_ROUTE === pathname:
        return (
          <AppBar position="static" elevation={1} className={classes.white}>
            <Toolbar>
              <AppBarUser />
            </Toolbar>
          </AppBar>
        );
      case (pathname.match(playbookBuilderAnswerQuestionsRegex) || []).length > 0:
        // SPECIAL ROUTING FOR ANSWER QUESTIONS PLAYBOOK BUILDER FLOW
        const splitPath = pathname.split('/rules/aq');

        if (splitPath.length !== 2) {
          // default if no path specified
          return (
            <AppBar position="static" className={classes.root}>
              <Toolbar>
                <AppBarFull
                  pathname={pathname}
                  navList={navList}
                  companyName={company}
                  userName={fullName}
                />
              </Toolbar>
            </AppBar>
          );
        }
        // e.g. /bbweb/model/edit/602fe1a3f554de005a02c371/rules/aq/select/example => ['select', 'example']
        const paths = splitPath
          .at(-1)
          .split('/')
          .filter((o) => o.length > 0);

        let gradualPath = `${splitPath[0]}/rules/aq`;
        // add core breadcrumb paths
        if (permissions.has(PLAYBOOK_BUILDER_PERMISSION)) {
          breadcrumbs.push({
            label: modelData ? modelData.name : '',
            path: `${splitPath[0]}`,
          });
        }
        if (paths.filter((path) => path === 'select' || path === 'status').length === 0) {
          // not on /select or /status pages
          breadcrumbs.push({ label: 'Answer Questions', path: `${gradualPath}/status` });
        }

        paths.forEach((path) => {
          gradualPath += `/${path}`;
          breadcrumbs.push({
            label: playbookBuilderAnswerQuestionsLookupMap[path],
            path: gradualPath,
          });
        });

        return (
          <AppBar position="static" className={classes.root}>
            <Toolbar>
              <AppBarBreadCrumb
                pathname={pathname}
                breadCrumbs={breadcrumbs}
                returnLabelText="Model"
                returnLabelLink={splitPath[0]}
              />
            </Toolbar>
          </AppBar>
        );
      default:
        return (
          <AppBar position="static" className={classes.root}>
            <Toolbar>
              <AppBarFull
                pathname={pathname}
                navList={navList}
                companyName={company}
                userName={fullName}
              />
            </Toolbar>
          </AppBar>
        );
    }
  };

  useEffect(() => {
    if (hasRoleAuth && config) {
      if (config.displayName) {
        setCompany(config.displayName);
      } else {
        setCompany(capitalize(config.name));
      }
    }
  }, [hasRoleAuth, config]);

  return hasRoleAuth ? renderSwitch() : null;
}

BaseAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(BaseAppBar);
