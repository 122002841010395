import Colors from '../../styles/Colors';

const EditTextControlsStyles = () => ({
  topMargin: {
    marginTop: 10,
  },
  makeEditButton: {
    '&:hover, &:focus': {
      color: `1px solid ${Colors.blue}`,
      border: `1px solid ${Colors.blue}`,
    },
  },
  cancelEditButton: {
    '&:hover, &:focus': {
      color: `1px solid ${Colors.red}`,
      border: `1px solid ${Colors.red}`,
    },
  },
  acceptEditButton: {
    '&:hover, &:focus': {
      color: `1px solid ${Colors.green}`,
      border: `1px solid ${Colors.green}`,
    },
  },
  ruleText: {
    color: 'black',
    overflowWrap: 'break-word',
  },
});

export default EditTextControlsStyles;
