import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Auth from '@aws-amplify/auth';

import withStyles from '@mui/styles/withStyles';
import { Grid, Typography } from '@mui/material';

import PasswordResetByCodeStyles from './PasswordResetByCodeStyles';
import { SnackbarCustom } from '../SnackbarCustom';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/;

const validationSchema = yup.object({
  code: yup
    .number()
    .typeError('code must be a number')
    .required('This is a required field'),
  email: yup
    .string()
    .max(150, 'Email is too long - should be 150 chars maximum.')
    .email()
    .required('This is a required field'),
  password: yup
    .string()
    .min(14, 'Password is too short - should be 14 chars minimum.')
    .max(25, 'Password is too long - should be 25 chars maximum.')
    .matches(
      passwordRegex,
      'Passwords must contain: 1 lower case letter, 1 upper case letter, 1 special character, 1 number, and must be at least 14 characters in length'
    )
    .required('This is a required field'),
  confirmPassword: yup
    .string()
    .min(14, 'Password is too short - should be 14 chars minimum.')
    .max(25, 'Password is too long - should be 25 chars maximum.')
    .matches(
      passwordRegex,
      'Passwords must contain: 1 lower case letter, 1 upper case letter, 1 special character, 1 number, and must be at least 14 characters in length'
    )
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('This is a required field'),
});

const PasswordResetByCode = ({ classes, onClick, setDisplay }) => {
  const [successMsg, setSuccessMsg] = useState(null);
  const [errMsg, setErrMsg] = useState(null);

  const handleSubmitVerificationCode = ({ email, code, password }) => {
    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        // redirect to sign-in page
        setSuccessMsg('Password changed successfully');
        onClick();
      })
      .catch((error) => {
        setErrMsg('An error occurred');
        console.log('An error occurred during password submission:', error);
      });
  };

  const formik = useFormik({
    initialValues: {
      code: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setTimeout(() => {
        handleSubmitVerificationCode(values);
      }, 400);
    },
  });

  return (
    <Grid container direction="column" justifyContent="flex-start">
      <form onSubmit={formik.handleSubmit}>
        <Grid item>
          <Typography className={classes.titleMargin}>
            Please enter the password reset code you received via email below:
          </Typography>
        </Grid>
        <Grid item>
          <Typography>Code:</Typography>
          <input
            id="code"
            name="code"
            autoComplete="none"
            type="text"
            className={classes.fullWidth}
            onChange={formik.handleChange}
            value={formik.values.code}
            onBlur={formik.handleBlur}
          />
          {formik.touched.code && formik.errors.code ? (
            <span className={classes.redText}>{formik.errors.code}</span>
          ) : null}
        </Grid>
        <Grid item>
          <Typography>Email:</Typography>
          <input
            id="email"
            name="email"
            autoComplete="none"
            type="text"
            className={classes.fullWidth}
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <span className={classes.redText}>{formik.errors.email}</span>
          ) : null}
        </Grid>
        <Grid item>
          <Typography>New Password:</Typography>
          <input
            id="password"
            name="password"
            autoComplete="new-password"
            type="password"
            required
            className={classes.fullWidth}
            onChange={formik.handleChange}
            value={formik.values.password}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? (
            <span className={classes.redText}>{formik.errors.password}</span>
          ) : null}
        </Grid>
        <Grid item>
          <Typography>Enter New Password Again:</Typography>
          <input
            id="confirmPassword"
            name="confirmPassword"
            autoComplete="new-password"
            type="password"
            required
            className={classes.fullWidth}
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            onBlur={formik.handleBlur}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <span className={classes.redText}>{formik.errors.confirmPassword}</span>
          ) : null}
        </Grid>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <button type="button" onClick={() => setDisplay(false)}>
              Cancel
            </button>
          </Grid>
          <Grid item>
            <button type="submit" onClick={formik.handleSubmit}>
              Submit
            </button>
          </Grid>
        </Grid>
      </form>
      <SnackbarCustom severity="warning" msg={errMsg} setMsg={setErrMsg} />
      <SnackbarCustom severity="success" msg={successMsg} setMsg={setSuccessMsg} />
    </Grid>
  );
};

PasswordResetByCode.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  setDisplay: PropTypes.func.isRequired,
};

export default withStyles(PasswordResetByCodeStyles)(PasswordResetByCode);
