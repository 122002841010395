import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';

import { MouseOverPopover } from '../MouseOverPopover';
import EmailRecipientIcon from './images/email-recipient.svg';
import EmailSentIcon from './images/email-sent.svg';
import GridCellRoutingStyles from './styles';

const GridCellRouting = ({ classes, value }) => {
  let routedTo = '';

  if (value && value.routedTo) {
    switch (value.routedTo) {
      case 'business_norisk':
        routedTo = 'No Risk';
        break;
      case 'business_risk_lowcoverage':
      case 'legal':
        routedTo = 'Risk';
        break;
      case 'tst':
        routedTo = 'Test';
        break;
      default:
        console.log(`Failed routedTo type with ${value.routedTo}`);
    }
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return value && Object.keys(value).length ? (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      className={classes.customContainer}
    >
      {routedTo ? <Grid item>{routedTo}</Grid> : null}
      {value.logs?.length ? (
        <Grid item>
          <MouseOverPopover anchorEl={anchorEl} handlePopoverClose={handlePopoverClose}>
            <div className={classes.emailContent}>
              {value.logs.map(({ message, sentDate }) => (
                <dl>
                  <dd className={classes.emailDate}>
                    {moment(sentDate).format('MMM DD, YYYY h:mm a')}
                  </dd>
                  <dd>{message}</dd>
                </dl>
              ))}
            </div>
          </MouseOverPopover>
          <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
            <img
              src={anchorEl ? EmailRecipientIcon : EmailSentIcon}
              alt="Email Icon"
              className={anchorEl ? classes.emailIconRecipient : classes.emailIcon}
            />
          </div>
        </Grid>
      ) : null}
    </Grid>
  ) : null;
};

GridCellRouting.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
};

export default withStyles(GridCellRoutingStyles)(GridCellRouting);
