import { EDIT_TYPES, MAX_RULE_STRENGTH, TEXT_TYPES, TRAIN_TYPES } from '../config';

export const encodeArrayForUrlParams = (arr) => encodeURIComponent(JSON.stringify(arr));

export const createLookupMapFromArr = (data) =>
  Object.entries(data).reduce((acc, [, { _id, name, className }]) => {
    acc[_id] = { name, className };
    return acc;
  }, {});

export const getUniqueSections = (data) => {
  if (!data || data.length === 0) {
    return [];
  }
  const sectionKeys = new Set();
  const sectionList = [];

  data.forEach(({ _id, name }) => {
    if (!sectionKeys.has(_id) && name) {
      sectionKeys.add(_id);
      sectionList.push({
        key: _id,
        value: name,
      });
    }
  });

  return sectionList.sort((a, b) => a.value.localeCompare(b.value));
};

export const capitalize = (text) => text[0].toUpperCase() + text.slice(1);

export const populateRulesBySection = (sections, rules) => {
  const rulesBySection = {};
  sections.forEach((s) => {
    rulesBySection[s._id] = [];
  });

  rules.forEach((tr) => {
    // eslint-disable-next-line no-param-reassign
    tr.sectionIds = [];
    const sectionIdsSet = new Set();
    tr.sections.forEach((s) => {
      if (rulesBySection[s._id]) {
        rulesBySection[s._id].push(tr);
      } else {
        rulesBySection[s._id] = [tr];
      }
      if (!sectionIdsSet.has(s._id)) {
        tr.sectionIds.push(s._id);
        sectionIdsSet.add(s._id);
      }
    });
  });

  // remove sections that do not have any training rules
  const filteredRulesBySection = Object.entries(rulesBySection).reduce(
    (acc, [sectionId, rulesArr]) => {
      if (rulesArr.length > 0) {
        acc[sectionId] = rulesArr;
      }
      return acc;
    },
    {}
  );

  return filteredRulesBySection;
};

export const handleCheckBoxSelection = (
  selectAll,
  setSelectAll,
  selectAllVisible,
  setSelectAllVisible,
  selectedCheckboxes,
  rowsToShow,
  setSelectedCheckboxes,
  filteredDataLength,
  event
) => {
  if (selectAll && !event.target.checked) {
    setSelectAll(false);
  } else if (selectAllVisible && !event.target.checked) {
    setSelectAllVisible(false);
  }
  const checkboxes = { ...selectedCheckboxes, [event.target.name]: event.target.checked };
  const allChecked = Object.values(checkboxes).filter((checked) => checked);
  if (!selectAll && allChecked.length === filteredDataLength) {
    setSelectAll(true);
  } else if (!selectAllVisible && allChecked.length === rowsToShow) {
    setSelectAllVisible(true);
  }
  setSelectedCheckboxes(checkboxes);
};

export const handleSelectAllCheckboxes = (
  data,
  selectAll,
  setSelectAll,
  selectAllVisible,
  setSelectAllVisible,
  setSelectedCheckboxes,
  preserveState = false,
  rowsToShow = null
) => {
  const checkedIds = {};
  if (rowsToShow) {
    if (preserveState || !selectAllVisible) {
      // state: select all visible will be checked
      data.slice(0, rowsToShow).forEach((item) => {
        checkedIds[item.tuid] = true;
      });
    } else {
      data.forEach((item) => {
        checkedIds[item.tuid] = false;
      });
    }
    setSelectAll(false);
    if (!preserveState) {
      setSelectAllVisible(!selectAllVisible);
    }
  } else {
    if (preserveState || !selectAll) {
      // state: select all will be checked
      data.forEach((item) => {
        checkedIds[item.tuid] = true;
      });
    } else {
      data.forEach((item) => {
        checkedIds[item.tuid] = false;
      });
    }
    setSelectAllVisible(false);
    if (!preserveState) {
      setSelectAll(!selectAll);
    }
  }
  setSelectedCheckboxes(checkedIds);
};

export const calcRuleStrength = (ruleSize, ruleStrengthDivisor) => {
  const rounded = Math.floor(Number((ruleSize / ruleStrengthDivisor).toFixed(1)));
  if (rounded > MAX_RULE_STRENGTH) {
    return MAX_RULE_STRENGTH;
  }
  return rounded;
};

export const scaleValueFromPercent = (min, max, value) => {
  // scale min and max to be within 0-10 range to match sliders
  const scaledMin = min * 10; // e.g. 0.80 => 8
  const scaledMax = max * 10; // e.g. 1.00 => 10

  const diff = scaledMax - scaledMin; // e.g. 2
  return Number(((value - 10 * scaledMin) / diff).toFixed(1));
};

export const scaleValueToPercent = (min, max, value) => {
  // scale min and max to be within 0-10 range to match sliders
  const scaledMin = min * 10; // e.g. 0.80 => 8
  const scaledMax = max * 10; // e.g. 1.00 => 10

  const diff = scaledMax - scaledMin; // e.g. 2
  const scaledValue = diff * value * 0.1 + scaledMin; // e.g. 0.90 if value is 5 of 10
  // e.g. 90.0
  return (scaledValue * 10).toPrecision(3);
};

export const showThreeDigits = (value) =>
  value.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 3 });

export const getTextTypeFromTrainType = (trainType) => {
  switch (trainType.toUpperCase()) {
    case TRAIN_TYPES.EMPTY:
      return 'empty';
    case TRAIN_TYPES.POINT:
      return 'point';
    case TRAIN_TYPES.FSI:
      return 'sentence';
    case TRAIN_TYPES.FPI:
      return 'paragraph';
    case TRAIN_TYPES.FSD:
      return 'sentence';
    case TRAIN_TYPES.FPD:
      return 'paragraph';
    case TRAIN_TYPES.LIST_EDIT:
      return 'list';
    default:
      return 'unknown';
  }
};

export const getNumSlots = (text) => {
  const slotRegex = /(\$\{[a-z_ A-Z]+:'[a-z_ A-Z]+'\})/gm;
  const matches = text.match(slotRegex);
  return matches ? matches.length : 0;
};

export const clearFoundByBlackBoilerFromTextList = (text) =>
  text.map((t) => {
    if (t.foundByBlackBoiler || t.editedByBlackBoiler || t.isFindSimilarSrc) {
      const tmp = { ...t };
      delete tmp.foundByBlackBoiler;
      delete tmp.editedByBlackBoiler;
      delete tmp.srcText;
      delete tmp.srcTuid;
      delete tmp.isFindSimilarSrc;
      return tmp;
    }
    return { ...t };
  });

export const clearFoundByBlackBoilerFromText = (text) => {
  const tmp = { ...text };
  delete tmp.foundByBlackBoiler;
  delete tmp.editedByBlackBoiler;
  delete tmp.srcText;
  delete tmp.srcTuid;
  return tmp;
};

export const getEditTypeForFindSimilar = (textObj) => {
  if (textObj.editedByBlackBoiler) {
    if (textObj.edited_value.length === 0) {
      return EDIT_TYPES.REJECT;
    }
    return EDIT_TYPES.REVISE;
  }
  return EDIT_TYPES.ACCEPT;
};

export const getTrainTypeForFindSimilar = (textObj) => {
  if (textObj.editedByBlackBoiler) {
    if (textObj.edited_value.length === 0) {
      if (textObj.type.toLowerCase() === TEXT_TYPES.SENTENCE) {
        return TRAIN_TYPES.FSD;
      }
      if (textObj.type.toLowerCase() === TEXT_TYPES.PARAGRAPH) {
        return TRAIN_TYPES.FPD;
      }
    } else {
      return TRAIN_TYPES.POINT;
    }
  } else {
    return TRAIN_TYPES.EMPTY;
  }
  return TRAIN_TYPES.EMPTY;
};

// For Rule Detail filtering
export const getEditAndTrainTypes = (
  tType = TRAIN_TYPES.EMPTY,
  eType = EDIT_TYPES.ACCEPT,
  sentence = '',
  editedSentence = '',
  editedSentenceSlotted = ''
) => {
  // default to an accept edit
  let trainType = tType;
  let editType = eType;
  if (sentence.length > 0 && editedSentence.length === 0) {
    editType = EDIT_TYPES.REJECT;
    if (tType === TRAIN_TYPES.FSI || tType === TRAIN_TYPES.FSD) {
      trainType = TRAIN_TYPES.FSD;
    } else if (tType === TRAIN_TYPES.FPI || tType === TRAIN_TYPES.FPD) {
      trainType = TRAIN_TYPES.FPD;
    } else if (tType === TRAIN_TYPES.POINT || tType === TRAIN_TYPES.EMPTY) {
      // rare case where the user originally entered the data as a point edit or accept and then deleted all the text
      // we don't have tracking on original data type so we can't infer FSD or FPD, leaving us with a point edit delete (exact match)
      trainType = TRAIN_TYPES.POINT;
    }
  } else if (
    sentence !== editedSentence ||
    getNumSlots(sentence) !== getNumSlots(editedSentenceSlotted)
  ) {
    if (tType !== TRAIN_TYPES.FSI && tType !== TRAIN_TYPES.FPI) {
      // not an insert (not editable in this manner)
      editType = EDIT_TYPES.REVISE;
      trainType = TRAIN_TYPES.POINT;
    }
  } else if (
    sentence === editedSentence &&
    getNumSlots(sentence) === getNumSlots(editedSentenceSlotted)
  ) {
    // accept data
    trainType = TRAIN_TYPES.EMPTY;
    editType = EDIT_TYPES.ACCEPT;
  }

  return {
    editType,
    trainType,
  };
};

// For custom build flow
export const getEditAndTrainType = (textType, value, editedValue) => {
  let trainType;
  let editType;
  if (editedValue.length === 0) {
    editType = EDIT_TYPES.REJECT;
    switch (textType) {
      case TEXT_TYPES.SENTENCE:
        trainType = TRAIN_TYPES.FSD;
        break;
      case TEXT_TYPES.PARAGRAPH:
        trainType = TRAIN_TYPES.FPD;
        break;
      default:
        trainType = TRAIN_TYPES.FSD;
    }
  } else if (value !== editedValue) {
    editType = EDIT_TYPES.REVISE;
    trainType = TRAIN_TYPES.POINT;
  } else {
    editType = EDIT_TYPES.ACCEPT;
    trainType = TRAIN_TYPES.EMPTY;
  }
  return { editType, trainType };
};

// Rule Filtering
export const filterSearch = (ruleData, search) =>
  ruleData.filter(
    (tr) =>
      tr.name.toLowerCase().includes(search.toLowerCase()) ||
      tr.description.toLowerCase().includes(search.toLowerCase())
  );
export const filterSection = (ruleData, sectionId) =>
  ruleData.filter((tr) => tr.sectionIds.includes(sectionId));
export const filterTrainType = (ruleData, trainType) =>
  ruleData.filter((tr) => tr.trainTypes.includes(trainType));
export const filterRuleStrength = (ruleData, strength) =>
  ruleData.filter((tr) => tr.strength === strength);
export const filterModel = (ruleData, model) =>
  ruleData.filter((tr) => tr.model === model);

// Text Filtering
export const filterTextBySearch = (textObjArr, search) =>
  textObjArr.filter((textObj) =>
    textObj.value.toLowerCase().includes(search.toLowerCase())
  );
export const filterTextBySection = (textObjArr, sectionId) =>
  textObjArr.filter((textObj) => textObj.sections.includes(sectionId));
export const filterTextByTypes = (textObjArr, textTypes) =>
  textObjArr.filter(({ type }) => textTypes.includes(type));

// FXI Filtering
export const filterFXISearch = (textObjArr, search) =>
  textObjArr.filter((textObj) =>
    textObj.value.toLowerCase().includes(search.toLowerCase())
  );
export const filterFXISection = (textObjArr, sectionId) =>
  textObjArr.filter((textObj) => textObj.sections.map((s) => s._id).includes(sectionId)); // internal mapping due to populated data being passed
