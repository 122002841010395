const GridCellBullseyeStyles = () => ({
  icon: {
    height: 12,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: 10,
  },
});

export default GridCellBullseyeStyles;
