import { SECTION_STATUS } from '../../components/AnswerQuestionsStatus/constants';
import {
  RESET_ANSWER_QUESTIONS,
  SET_ANSWER,
  SET_ANSWER_PREVIEWS,
  SET_CURRENT_QUESTION,
  SET_CURRENT_SECTION,
  SET_QUESTION_TYPES,
  SET_QUESTIONNAIRE,
  SET_SECTION_STATUS,
  SET_SECTION_STATUSES,
  DELETE_SECTION_STATUS,
  SET_SELECTED_QUESTIONS,
} from '../action-types/answerQuestionsTypes';

export const SetAnswerPreviews = (ruleId, edits) => ({
  type: SET_ANSWER_PREVIEWS,
  payload: {
    ruleId,
    edits,
  },
});

export const SetCurrentQuestion = (question) => ({
  type: SET_CURRENT_QUESTION,
  payload: question,
});

export const SetCurrentSection = (section) => ({
  type: SET_CURRENT_SECTION,
  payload: section,
});

export const SetSectionStatusesIncomplete = (
  selectedSections,
  unselectedSections,
  selectedQuestions
) => {
  const statusLookup = {};
  selectedSections.forEach((section) => {
    statusLookup[section._id] = {
      _id: section._id,
      name: section.name,
      className: section.className,
      questions: selectedQuestions
        .filter((q) => q.section === section._id)
        .map((q) => ({ ...q, answer: null })),
      status: SECTION_STATUS.INCOMPLETE,
    };
  });
  unselectedSections.forEach((section) => {
    statusLookup[section._id] = {
      _id: section._id,
      name: section.name,
      className: section.className,
      status: SECTION_STATUS.UNSELECTED,
    };
  });

  return {
    type: SET_SECTION_STATUSES,
    payload: statusLookup,
  };
};

export const DeleteSectionStatus = (sectionId) => ({
  type: DELETE_SECTION_STATUS,
  payload: sectionId,
});

export const SetAnswer = (sectionId, questionId, answer) => ({
  type: SET_ANSWER,
  payload: {
    sectionId,
    questionId,
    answer,
  },
});

export const SetQuestionnaire = (data) => ({
  type: SET_QUESTIONNAIRE,
  payload: data,
});

export const SetSectionStatus = (sectionId, status) => ({
  type: SET_SECTION_STATUS,
  payload: {
    sectionId,
    status,
  },
});

export const SetSelectedQuestions = (questions) => ({
  type: SET_SELECTED_QUESTIONS,
  payload: questions,
});

export const ResetAnswerQuestions = () => ({
  type: RESET_ANSWER_QUESTIONS,
});

export const SetQuestionTypes = (questionTypes) => ({
  type: SET_QUESTION_TYPES,
  payload: questionTypes,
});
