import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import withStyles from '@mui/styles/withStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Grid, Menu, MenuItem } from '@mui/material';

import GridCellMenuStyles from './styles';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { CONTRACTS_ENDPOINT } from '../../endpoints.config';

const GridCellMenu = ({ classes, customMultiFieldId, contract }) => {
  const { customFieldsMulti: contractMultiField, _id: contractId } = contract;

  const { getAuthHeader, config: companyConfig } = useAuthUpdateContext();

  const allCustomMultiFields = companyConfig && companyConfig.customFieldsMulti;

  if (!allCustomMultiFields?.length || !contractMultiField?.length) {
    return;
  }

  const [targetConfigMultiField] = allCustomMultiFields.filter(
    ({ _id }) => _id === customMultiFieldId
  );
  const configDropdownLabels = targetConfigMultiField?.dropDownList;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState();
  const [selectedId, setSelectedId] = useState();

  useEffect(() => {
    const [newLabel] = configDropdownLabels.filter(({ _id }) => _id === selectedId);
    setSelectedLabel(newLabel);
  }, [selectedId]);

  useEffect(() => {
    const [targetContractMultiField] = contractMultiField.filter(
      ({ _id }) => _id === customMultiFieldId
    );
    if (targetContractMultiField) {
      const [initialSelectedLabel] = targetContractMultiField?.dropDownList?.filter(
        ({ isSelected }) => isSelected
      );
      setSelectedLabel(initialSelectedLabel);
      setSelectedId(initialSelectedLabel?._id);
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSubmit(targetId, multiFieldId) {
    const updatedContractMultiField = contractMultiField.reduce((acc, multiObj) => {
      if (multiObj?._id === multiFieldId) {
        const updateTargetDropdown = multiObj?.dropDownList?.map((multi) => {
          if (multi?._id === targetId) {
            return { ...multi, isSelected: true };
          }
          return { ...multi, isSelected: false };
        });
        return [...acc, { ...multiObj, dropDownList: updateTargetDropdown }];
      }
      return acc;
    }, []);

    getAuthHeader().then((headers) => {
      const body = { customFieldsMulti: updatedContractMultiField };

      axios
        .put(`${CONTRACTS_ENDPOINT}${contractId}`, body, { headers })
        .then(() => {
          setSelectedId(targetId);
          handleClose();
        })
        .catch((e) => {
          // TODO: Handle error and loading with popover
          console.log(e);
        });
    });
  }

  const handleMenuItemClick = (targetId) => {
    handleSubmit(targetId, targetConfigMultiField?._id);
  };

  return (
    <>
      {companyConfig?.enableCustomFieldDropdown ? (
        <div>
          <Grid container alignItems="center" justifyContent="flex-start">
            <Grid item>
              <Button
                aria-controls="status-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="medium"
                variant="outlined"
                className={classes.statusBtn}
              >
                <span className={classes.dot} /> {selectedLabel?.name}
              </Button>
              <ArrowDropDownIcon />
            </Grid>
          </Grid>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {configDropdownLabels.map((label) => (
              <MenuItem
                key={label._id}
                selected={label._id === selectedId}
                disabled={label._id !== selectedId}
                // Currently disabled for all clients
                // onClick={(event) => handleMenuItemClick(label._id)}
              >
                {label.name} <span className={`${classes.dot} ${classes.menuDot}`} />
              </MenuItem>
            ))}
          </Menu>
        </div>
      ) : (
        <div>{selectedLabel?.name}</div>
      )}
    </>
  );
};

GridCellMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  customMultiFieldId: PropTypes.string.isRequired,
  contract: PropTypes.object.isRequired,
};

export default withStyles(GridCellMenuStyles)(GridCellMenu);
