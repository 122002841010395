import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Button } from '@mui/material';

import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { submitContractExportWebhook } from '../../requests/general';
import { forceSaveRequest, logDocumentExport } from '../../utils/OnlyOfficeUtils';
import OpenExportDialog from './OpenExportDialog';

const ExportButton = () => {
  const onlyOfficeUrl = useSelector(
    (state) => state.OnlyOffice.onlyOfficeUrl,
    shallowEqual
  );

  const urlSearchParams = new URLSearchParams(useLocation().search);
  const contractId = urlSearchParams.get('contract');

  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [intervalId, setIntervalId] = useState();

  const { getAuthHeader, config } = useAuthUpdateContext();
  const { exportWebhook = {} } = config || {};

  const handleExport = () => {
    setOpenExportDialog(false);
    try {
      getAuthHeader().then((headers) => {
        logDocumentExport(headers, contractId);
        if (Object.keys(exportWebhook).length) {
          submitContractExportWebhook(headers, contractId)
            .then(() => console.log('Contract export webhook submitted'))
            .catch((err) => {
              throw err;
            });
        }
      });
      window.DocsAPICopy.downloadAs();
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenExport = () => {
    getAuthHeader().then((headers) => {
      forceSaveRequest(headers, onlyOfficeUrl, contractId)
        .then((resp) => console.log('Received response from force save request:', resp))
        .catch((err) =>
          console.log('Failed to perform force save request with error:', err)
        );
    });
    setOpenExportDialog(true);
  };

  useEffect(() => {
    if (isDisabled) {
      const newIntervalId = setInterval(() => {
        setIsDisabled(typeof window.DocsAPI === 'undefined');
      }, 1000);
      setIntervalId(newIntervalId);
    } else {
      setIntervalId(null);
      clearInterval(intervalId);
    }
  }, [isDisabled]);

  useEffect(() => () => clearInterval(intervalId), [isDisabled]);

  return (
    <>
      <Button
        disabled={isDisabled}
        color="primary"
        variant="contained"
        onClick={handleOpenExport}
      >
        Export Contract
      </Button>
      <OpenExportDialog
        open={openExportDialog}
        handleExport={handleExport}
        handleClose={() => setOpenExportDialog(false)}
      />
    </>
  );
};

export default ExportButton;
