import Colors from '../../styles/Colors';

const InsertTextPreviewTableStyles = () => ({
  insertTextTableHead: {
    backgroundColor: Colors.grey7,
  },
  insertTextCell: {
    fontSize: '1.3rem',
    fontStyle: 'italic',
    borderRight: `1px solid ${Colors.grey8}`,
  },
  insertScoreCell: {
    fontSize: '1.3rem',
    borderRight: `1px solid ${Colors.grey8}`,
  },
  insertTextGreen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '15px 30px',
    color: Colors.green,
    '& p': {
      color: Colors.green,
      fontSize: '1.3rem',
    },
    '& svg': {
      fontSize: 30,
    },
  },
  insertTextRed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '15px 30px',
    color: Colors.red,
    '& p': {
      color: Colors.red,
      fontSize: '1.3rem',
    },
    '& svg': {
      fontSize: 30,
    },
  },
  insertTextBlank: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '15px 30px',
    color: Colors.blue,
    '& p': {
      color: Colors.blue,
      fontSize: '1.3rem',
    },
    '& svg': {
      fontSize: 30,
    },
  },
});

export default InsertTextPreviewTableStyles;
