import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Editor,
  EditorState,
  CompositeDecorator,
  Modifier,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import 'draft-js/dist/Draft.css';

import withStyles from '@mui/styles/withStyles';

import {
  getEntityStrategy,
  tokenSpan,
  convertFromBlackBoiler,
  getBbTextFromRaw,
} from '../../utils/ClauseUtils';
import RenderClauseStyles from './styles';

const RenderClause = ({
  classes,
  clauseObject,
  updateComponent,
  updateSlot,
  mode,
  updateBbText = (f) => f,
}) => {
  const decorator = new CompositeDecorator([
    {
      strategy: getEntityStrategy('IMMUTABLE'),
      component: tokenSpan,
    },
  ]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(decorator)
  );

  const insertSlot = () => {
    const selection = editorState.getSelection();

    // Get entity map
    const contentState = editorState.getCurrentContent();
    const {
      _map: {
        _root: { entries },
      },
    } = contentState;
    const [, [, entityMap]] = entries;

    // Replace text to
    const editedContentState = Modifier.replaceText(
      contentState,
      selection.merge({
        anchorOffset: selection.getAnchorOffset(),
        focusOffset: selection.getFocusOffset(),
      }),
      updateSlot
    );

    const offset = selection.getAnchorOffset() + updateSlot.length;
    const updateSelection = selection.set('focusOffset', offset);

    // Add entity
    const nextContentState = Object.entries(entityMap).reduce(
      (acc, [entityName, entityKey]) => {
        if (entityName === updateSlot) {
          return Modifier.applyEntity(editedContentState, updateSelection, entityKey);
        }
        return acc;
      },
      editorState.getCurrentContent()
    );

    // Update content state
    const nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      'apply-entity'
    );

    setEditorState(nextEditorState);
  };

  useEffect(() => {
    if (editorState) {
      const convertedFromBlackBoiler = convertFromBlackBoiler(clauseObject, mode);
      const content = convertFromRaw(convertedFromBlackBoiler);
      const newEditorState = EditorState.push(editorState, content);
      setEditorState(newEditorState);
    }
  }, [clauseObject]);

  useEffect(() => {
    if (updateSlot) {
      insertSlot(updateSlot);
    }
  }, [updateSlot, updateComponent]);

  useEffect(() => {
    if (editorState) {
      const currentContent = editorState.getCurrentContent();
      const raw = convertToRaw(currentContent);
      const blackBoilerText = getBbTextFromRaw(raw);
      updateBbText(blackBoilerText);
    }
  }, [editorState]);

  return (
    <div className={classes.root}>
      <Editor
        readOnly={mode === 'read'}
        editorState={editorState}
        onChange={setEditorState}
        placeholder="Type clause ..."
      />
    </div>
  );
};

RenderClause.propTypes = {
  classes: PropTypes.object.isRequired,
  updateComponent: PropTypes.bool.isRequired,
  updateSlot: PropTypes.string.isRequired,
  clauseObject: PropTypes.object.isRequired,
  updateBbText: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
};

export default withStyles(RenderClauseStyles)(RenderClause);
