import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import FillInTheBlankStyles from './FillInTheBlankStyles';

function FillInTheBlank({ classes }) {
  const dispatch = useDispatch();
  const { currentSection, currentQuestion } = useSelector(
    (state) => state.AnswerQuestions
  );

  return (
    <>
      <Typography className={classes.label}>{label}</Typography>
      {!!value && (
        <TextField
          className={classes.textField}
          variant="outlined"
          defaultValue={currentQuestion.answer}
          value={currentQuestion.answer}
          onChange={(evt) =>
            dispatch(SetAnswer(currentSection._id, currentQuestion._id, evt.target.value))
          }
        />
      )}
    </>
  );
}

FillInTheBlank.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(FillInTheBlankStyles)(FillInTheBlank);
