import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Tooltip, Typography } from '@mui/material';

import { buildLastModifiedText } from '../../utils/modelDisplayUtils';
import TooltipCustomStyles from '../TooltipCustom/styles';

const TooltipRuleInfo = ({
  classes,
  dateCreated = '',
  dateModified = '',
  createdBy = '',
  modifiedBy = '',
  children = null,
  titleTexts = [],
  position = 'left',
}) => (
  <Tooltip
    title={
      titleTexts.length > 0 ? (
        <>
          {titleTexts.map((text, idx) => (
            <Typography className={classes.tooltip} key={`idx-${idx}-${text}`}>
              {text}
            </Typography>
          ))}
        </>
      ) : (
        <>
          <Typography className={classes.tooltip}>
            {`Created: ${buildLastModifiedText(dateCreated)}`}
          </Typography>
          {createdBy && (
            <Typography className={classes.tooltip}>
              {`Created by: ${createdBy}`}
            </Typography>
          )}

          <br />

          <Typography className={classes.tooltip}>
            {`Modified: ${buildLastModifiedText(dateModified)}`}
          </Typography>
          {modifiedBy && (
            <Typography className={classes.tooltip}>
              {`Modified by: ${modifiedBy}`}
            </Typography>
          )}
        </>
      )
    }
    arrow
    placement={position}
    classes={{ tooltip: classes.noMaxWidth }}
  >
    <div>{children}</div>
  </Tooltip>
);

TooltipRuleInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  dateCreated: PropTypes.string,
  dateModified: PropTypes.string,
  createdBy: PropTypes.string,
  modifiedBy: PropTypes.string,
  children: PropTypes.any,
  titleTexts: PropTypes.array,
  position: PropTypes.string,
};

export default withStyles(TooltipCustomStyles)(TooltipRuleInfo);
