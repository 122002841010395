// PERMISSION is a  RIGHT
// export const COMPANY_ADMIN_PERMISSION = 'companyadmin';
// export const CONTRACTS_PERMISSION = 'contracts';
// export const DOWNLOAD_CONTRACT_PERMISSION = 'downloadContract';
// export const UPLOAD_CONTRACT_PERMISSION = 'uploadContract';
// export const CHANGE_PASSWORD_PERMISSION = 'changePassword';
// export const CLAUSE_EDITOR_PERMISSION = 'clauseEditor';
// export const FAST_TRACK_PERMISSION = 'fasttrack';
// export const DATA_DASHBOARD_PERMISSION = 'datadashboard';
// export const METRICS_DASHBOARD_PERMISSION = 'metricsdashboard';

export const BLACKBOILER_ADMIN_PERMISSION = 'blackboileradmin';
export const COMPANY_ADMIN_PERMISSION = 'companyadmin';
export const CONTRACTS_PERMISSION = 'contracts';
export const DOWNLOAD_CONTRACT_PERMISSION = 'downloadContract';
export const UPLOAD_CONTRACT_PERMISSION = 'uploadContract';
export const CHANGE_PASSWORD_PERMISSION = 'changePassword';
export const CLAUSE_EDITOR_PERMISSION = 'clauseEditor';
export const FAST_TRACK_PERMISSION = 'fasttrack';
export const POA_INTAKE_PERMISSION = 'poaIntake';
export const POA_REVIEW_PERMISSION = 'poaReview';
export const POA_GEMS_PERMISSION = 'poaGems';
export const DATA_DASHBOARD_PERMISSION = 'datadashboard';
export const REPORTING_DASHBOARD_PERMISSION = 'reportingdashboard';
export const METRICS_DASHBOARD_PERMISSION = 'metricsdashboard';
export const METRICS_FASTTRACK_PERMISSION = 'metricsfasttrack';
export const VIEW_DRAFT_MODELS_PERMISSION = 'viewdraftmodels';
export const PLAYBOOK_BUILDER_PERMISSION = 'playbookbuilder';
export const BULLSEYE_PERMISSION = 'bullseye';
export const ACCOUNT_ADMIN_PERMISSION = 'accountmanager';
export const ANSWER_QUESTIONS_PERMISSION = 'answer_question';

export const ALL_PERMISSIONS = [
  BLACKBOILER_ADMIN_PERMISSION,
  COMPANY_ADMIN_PERMISSION,
  CONTRACTS_PERMISSION,
  DOWNLOAD_CONTRACT_PERMISSION,
  UPLOAD_CONTRACT_PERMISSION,
  CHANGE_PASSWORD_PERMISSION,
  CLAUSE_EDITOR_PERMISSION,
  FAST_TRACK_PERMISSION,
  POA_INTAKE_PERMISSION,
  POA_REVIEW_PERMISSION,
  POA_GEMS_PERMISSION,
  DATA_DASHBOARD_PERMISSION,
  REPORTING_DASHBOARD_PERMISSION,
  METRICS_DASHBOARD_PERMISSION,
  METRICS_FASTTRACK_PERMISSION,
  VIEW_DRAFT_MODELS_PERMISSION,
  PLAYBOOK_BUILDER_PERMISSION,
  BULLSEYE_PERMISSION,
  ACCOUNT_ADMIN_PERMISSION,
  ANSWER_QUESTIONS_PERMISSION,
];

const setDefaultPermissions = (hasPermission = false) =>
  ALL_PERMISSIONS.reduce(
    (acc, permission) => ({ ...acc, [permission]: hasPermission }),
    {}
  );
const defaultFalsePermissions = setDefaultPermissions(false);

export const ACCOUNT_GROUPS_CONFIG = {
  // ROLE: { PERMISSION }
  blackboiler: setDefaultPermissions(true),
  admin: {
    ...defaultFalsePermissions,
    [COMPANY_ADMIN_PERMISSION]: true,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [REPORTING_DASHBOARD_PERMISSION]: true,
  },
  user: {
    ...defaultFalsePermissions,
    [CONTRACTS_PERMISSION]: true,
    [DOWNLOAD_CONTRACT_PERMISSION]: true,
    [UPLOAD_CONTRACT_PERMISSION]: true,
    [CHANGE_PASSWORD_PERMISSION]: true,
  },
  playbook_builder: {
    ...defaultFalsePermissions,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [PLAYBOOK_BUILDER_PERMISSION]: true,
    [ANSWER_QUESTIONS_PERMISSION]: true,
  },
  group_metrics: {
    ...defaultFalsePermissions,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [METRICS_DASHBOARD_PERMISSION]: true,
  },
  fasttrack_metrics: {
    ...defaultFalsePermissions,
    [METRICS_FASTTRACK_PERMISSION]: true,
  },
  clause_editor: {
    ...defaultFalsePermissions,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [CLAUSE_EDITOR_PERMISSION]: true,
  },
  bullseye: {
    ...defaultFalsePermissions,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [BULLSEYE_PERMISSION]: true,
  },
  fasttrack: {
    ...defaultFalsePermissions,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [FAST_TRACK_PERMISSION]: true,
  },
  poa_intake: {
    ...defaultFalsePermissions,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [POA_INTAKE_PERMISSION]: true,
  },
  poa_review: {
    ...defaultFalsePermissions,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [POA_INTAKE_PERMISSION]: true,
    [POA_REVIEW_PERMISSION]: true,
  },
  poa_manage: {
    ...defaultFalsePermissions,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [POA_INTAKE_PERMISSION]: true,
    [POA_REVIEW_PERMISSION]: true,
    [POA_GEMS_PERMISSION]: true,
  },
  accountmanager: {
    ...defaultFalsePermissions,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [ACCOUNT_ADMIN_PERMISSION]: true,
  },
  answer_question: {
    ...defaultFalsePermissions,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [ANSWER_QUESTIONS_PERMISSION]: true,
  },
  tier1: {
    ...defaultFalsePermissions,
    [CHANGE_PASSWORD_PERMISSION]: true,
  },
  tier2: {
    ...defaultFalsePermissions,
    [CONTRACTS_PERMISSION]: true,
    [DOWNLOAD_CONTRACT_PERMISSION]: true,
    [UPLOAD_CONTRACT_PERMISSION]: true,
    [CHANGE_PASSWORD_PERMISSION]: true,
    [BULLSEYE_PERMISSION]: true,
  },
  tier3: {
    ...defaultFalsePermissions,
  },
};
