const RulesGridStyles = () => ({
  DataRow: {
    backgroundColor: 'white',
    padding: '10px 15px',
    marginBottom: 15,
  },
  ruleText: {
    color: 'black',
    overflowWrap: 'break-word',
  },
  EditRuleButton: {
    borderRadius: 20,
  },
  DottedLineGridWrapper: {
    marginBottom: 10,
  },
  dottedLine: {
    borderBottom: '1px dotted black',
  },
  RuleCountHeader: {
    fontSize: 'x-small',
    marginLeft: 10,
  },
  RulesGridWrapper: {
    width: '100%',
  },
});

export default RulesGridStyles;
