import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import PageContentStyles from './styles';

const PageContent = ({ classes, hasPadding = true, isBlue = false, children = null }) => (
  <>
    <div
      className={`
            ${!isBlue ? classes.pageContentWrapper : classes.pageContentWrapperBlue}
            ${hasPadding ? classes.pageContentPadding : ''}
        `}
    >
      {children}
    </div>
  </>
);

PageContent.propTypes = {
  classes: PropTypes.object.isRequired,
  isBlue: PropTypes.bool,
  hasPadding: PropTypes.bool,
  children: PropTypes.any,
};

export default withStyles(PageContentStyles)(PageContent);
