const ModifyListFieldsStyles = () => ({
  deleteIcon: {
    fontSize: '2.5rem',
  },
  paperSetting: {
    backgroundColor: 'white',
  },
  textField: {
    width: '95%',
    marginBottom: '10px',
    display: 'flex',
  },
  sectionHeader: {
    marginBottom: '20px',
  },
});

export default ModifyListFieldsStyles;
