import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { fetchEditPreviews } from '../../requests/training';
import { SetAnswerPreviews } from '../../redux/actions/answerQuestionsActions';
import { Box, Typography, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import StepperDots from './StepperDots';
import TrainTypeNugget from './TrainTypeNugget';
import EditTypeNugget from './EditTypeNugget';
import RulePopover from './RulePopover';
import CircularProgress from '@mui/material/CircularProgress';
import TextDiffWithContext from './TextDiffWithContext';

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const styles = {
  borderStyles: {
    borderColor: '#222222',
    borderStyle: 'solid',
    borderWidth: '0.5pt',
    borderRadius: '3px',
  },

  navBtnStyles: {
    ...flexCenter,
    px: 1,
  },

  widthControlledContainer: {
    display: 'flex',
    width: '80%',
  },

  flexCenter,
};

export default function EditPreview({ question }) {
  const dispatch = useDispatch();
  const { getAuthHeader } = useAuthUpdateContext();
  const { questionTypes, answerPreviews } = useSelector((state) => state.AnswerQuestions);

  const { answer } = question;
  const [previews, setPreviews] = useState([]);
  const [currentPreview, setCurrentPreview] = useState();
  const [ruleInfoAnchor, setRuleInfoAnchor] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const selectedRules = getSelectedRules() || [];
      if (!selectedRules.length) {
        setPreviews([]);
      } else {
        for (const ruleId of selectedRules) {
          if (!answerPreviews[ruleId]) {
            const previewData = await fetchPreviews(ruleId);
            dispatch(SetAnswerPreviews(ruleId, previewData));
          } else {
            updatePreview();
          }
        }
      }
      setLoading(false);
    })();
  }, [answer]);

  useEffect(() => {
    updatePreview();
  }, [answerPreviews]);

  useEffect(() => {
    updatePreviewsWithUserInput();
  }, [question, answerPreviews]);

  function updatePreviewsWithUserInput() {
    const { type: qType, answer } = question;
    switch (qType) {
      case questionTypes.YES_NO_TEXT_EDITOR:
        const { templated } = answer || {};
        // update previews with custom text
        if (templated && previews?.length) {
          setPreviews((prvs) => {
            // Update all previews
            const newPreviews = prvs.map((p) => {
              // update previews for the types that make sense
              if (['FPI', 'FSI'].includes(p?.train_type?.toUpperCase())) {
                return { ...p, edited_sentence: templated };
              }
              return p;
            });
            // Update the current preview as well
            setCurrentPreview(newPreviews[currentPosition()]);
            return newPreviews;
          });
        }
      default:
        return;
    }
  }

  function updatePreview() {
    const selectedRules = getSelectedRules() || [];
    const dataToPreview = selectedRules
      .map((rId) => answerPreviews[rId])
      .filter((val) => Boolean(val))
      .reduce(
        (
          acc,
          { previewData: ruleData, stats: ruleStats, trainingRuleData: trainingRules }
        ) => {
          const newItems = ruleData.map((pd) => ({
            ...pd,
            ruleStats,
            trainingRules,
          }));
          return acc.concat(newItems);
        },
        []
      );
    setPreviews(dataToPreview);
    setCurrentPreview(dataToPreview[0]);
  }

  async function fetchPreviews(rule) {
    const headers = await getAuthHeader();
    const previews = await fetchEditPreviews(headers, [rule]);
    return previews;
  }

  function getSelectedRules() {
    const { type: qType, answer, options } = question;
    switch (qType) {
      case questionTypes.YES_NO:
        return Object.keys(options[answer] || {});
      case questionTypes.YES_NO_TEXT_EDITOR:
        const { value: answerVal } = answer || {};
        return Object.keys(options[answerVal] || {});
      case questionTypes.GOVERNING_LAW:
      case questionTypes.FORUM:
        return Object.keys(options || {});
      default:
        throw new Error(`EditPreview does not support question type ${qType}}`);
    }
  }

  function currentPosition() {
    return previews.indexOf(currentPreview);
  }

  function prevPreview() {
    const idx = currentPosition();
    setCurrentPreview(idx == 0 ? previews[previews.length - 1] : previews[idx - 1]);
  }

  function nextPreview() {
    const idx = currentPosition();
    setCurrentPreview(idx == previews.length - 1 ? previews[0] : previews[idx + 1]);
  }

  if (loading) {
    return (
      <Box sx={{ my: 6, ...styles.widthControlledContainer, ...styles.flexCenter }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!question || !answer || !previews.length) {
    return null;
  }

  const {
    training_id: trainingId,
    train_type: trainType,
    edit_type: editType,
  } = currentPreview;

  return (
    <>
      <Typography sx={{ mt: 2 }} variant="h3">
        Sample Edit
      </Typography>
      <Box
        sx={{
          mt: 2,
          px: 0,
          py: 2,
          ...styles.widthControlledContainer,
          ...styles.borderStyles,
        }}
      >
        <Box sx={{ ...styles.navBtnStyles }}>
          <IconButton sx={{ ...styles.navBtnStyles }} onClick={() => prevPreview()}>
            <NavigateBeforeIcon sx={{ fontSize: 'large' }} />
          </IconButton>
        </Box>
        <Box sx={{ fontSize: 'small', flex: '1 1 auto' }}>
          <TextDiffWithContext trainingRecord={currentPreview} />
        </Box>
        <Box sx={{ ...styles.navBtnStyles }}>
          <IconButton onClick={() => nextPreview()}>
            <NavigateNextIcon sx={{ fontSize: 'large' }} />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ my: 1, ...styles.widthControlledContainer }}>
        <Typography
          sx={{ flex: '0 0 150px', m: 0, color: '#888888', fontSize: '1.1rem' }}
          variant="body1"
        >
          {trainingId}
        </Typography>
        <StepperDots
          sx={{ flex: '1 1 auto' }}
          items={previews}
          selectedItem={currentPreview}
          keyFunc={(p) => p._id}
          onItemChange={(pr) => setCurrentPreview(pr)}
        />
        <Box sx={{ display: 'flex', flex: '0 0 150px', gap: 1 }}>
          <Box sx={{ display: 'flex' }}>
            <EditTypeNugget editType={editType} />
            <TrainTypeNugget trainType={trainType} />
          </Box>
          <InfoIcon
            sx={{ ml: 'auto' }}
            onMouseEnter={(e) => setRuleInfoAnchor(e.currentTarget)}
            onMouseLeave={() => setRuleInfoAnchor(null)}
          />
        </Box>
      </Box>
      <RulePopover
        rules={currentPreview?.trainingRules}
        sentence={currentPreview}
        ruleStats={currentPreview?.ruleStats}
        anchorEl={ruleInfoAnchor}
      />
    </>
  );
}
