// import Colors from './Colors';

const PageHeaderStyles = () => ({
  pageHeaderWrapper: {
    backgroundColor: 'white',
    paddingLeft: 20,
    paddingRight: 20,
  },
  pageHeaderWrapperBlue: {
    backgroundColor: '#f7fafd',
    paddingLeft: 20,
    paddingRight: 20,
  },
  pageHeaderRow: {
    paddingTop: 20,
    minHeight: 70,
  },
  pageHeaderTab: {
    height: 115,
  },
  pageHeaderTabRow: {
    height: 'auto',
  },
});

export default PageHeaderStyles;
