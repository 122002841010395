const ModifyMixObjectFieldsStyles = () => ({
  paperSetting: {
    backgroundColor: 'white',
    height: '25em',
  },
  sectionHeader: {
    marginBottom: '20px',
  },
  textField: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '90%',
  },
  formControlLabel: {
    fontSize: 12,
    display: 'block',
  },
});

export default ModifyMixObjectFieldsStyles;
