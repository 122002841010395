import Colors from '../../styles/Colors';

const PageContentStyles = () => ({
  pageContentPadding: {
    // paddingTop: 20,
  },
  pageContentWrapper: {
    backgroundColor: 'white',
  },
  pageContentWrapperBlue: {
    backgroundColor: Colors.pageContentBlue,
    minHeight: '100vh',
  },
});

export default PageContentStyles;
