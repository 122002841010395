import Colors from '../../styles/Colors';

const AnswerQuestionsSelectSectionStyles = () => ({
  selectAllCheckbox: {
    color: Colors.blue,
    textDecoration: 'underline',
    // backgroundColor: Colors.lightGrey,
  },
  checkboxButtonWrapper: {
    width: '100%',
    backgroundColor: Colors.lightGrey,
  },
  sectionText: {
    textAlign: 'left',
    color: 'black',
    textTransform: 'none',
  },
  sectionSubText: {
    color: 'gray',
    textTransform: 'none',
  },
  checkboxGridItemWrapper: {
    width: '100%',
  },
  selectAllPadding: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  innerCheckboxWrapper: {},
});

export default AnswerQuestionsSelectSectionStyles;
