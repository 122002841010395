export const SECTION_STATUS = {
  INCOMPLETE: 'INCOMPLETE',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
  UNSELECTED: 'UNSELECTED',
  UNKNOWN: 'UNKNOWN',
};

export const SECTION_STATUS_TO_DISPLAY_TEXT = {
  INCOMPLETE: 'Not started',
  IN_PROGRESS: 'In progress',
  COMPLETE: 'Complete!',
  UNSELECTED: 'No changes',
  UNKNOWN: 'Unknown',
};
