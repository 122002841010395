import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Typography } from '@mui/material';

import { Flex } from '../../components';

const OnboardingThankYou = ({ handleClose, isChangedPreferredModel, subdomain }) => {
  return (
    <Flex.V sx={{ gap: '32px', margin: '32px 0', alignItems: 'center' }}>
      <CheckCircleIcon sx={{ fontSize: '100px', color: '#0D6DFF' }} />

      <Typography variant="h1">Thank you!</Typography>

      <Typography variant="body2">
        Setup Complete!
        {isChangedPreferredModel &&
          ` Send documents to review@${subdomain}.blackboiler.com address to be processed.`}
      </Typography>

      <Button variant="contained" sx={{ width: '130px' }} onClick={() => handleClose()}>
        OK
      </Button>
    </Flex.V>
  );
};

OnboardingThankYou.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isChangedPreferredModel: PropTypes.bool,
  subdomain: PropTypes.string,
};

export default OnboardingThankYou;
