import Colors from '../../styles/Colors';

const InfoPopoverStyles = () => ({
  infoContent: {
    maxWidth: 250,
    minWidth: 100,
    fontSize: '1.0rem',
    padding: 10,
    color: Colors.trueWhite,
    border: `1px solid ${Colors.grey9}`,
    backgroundColor: Colors.grey9,
  },
  popover: {
    pointerEvents: 'none',
  },
});

export default InfoPopoverStyles;
