import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { SetAnswer } from '../../redux/actions/answerQuestionsActions';
import YesNoStyles from '../../styles/YesNoStyles';
import { EditPreview } from '../EditPreview';

function YesNo({ classes }) {
  const dispatch = useDispatch();
  const { currentSection, currentQuestion } = useSelector(
    (state) => state.AnswerQuestions
  );
  return (
    <>
      <RadioGroup
        row
        aria-label="yesNo"
        name="yesNo"
        value={currentQuestion.answer}
        onChange={(evt) =>
          dispatch(SetAnswer(currentSection._id, currentQuestion._id, evt.target.value))
        }
        className={classes.radioGroup}
      >
        <FormControlLabel
          value="YES"
          control={<Radio className={classes.radioButton} />}
          label="Yes"
          className={classes.formControlLabel}
        />
        <FormControlLabel
          value="NO"
          control={<Radio className={classes.radioButton} />}
          label="No"
          className={classes.formControlLabel}
        />
      </RadioGroup>
      <EditPreview question={currentQuestion} />
    </>
  );
}

YesNo.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(YesNoStyles)(YesNo);
