import {
  CLEAR_ONLY_OFFICE_REDUX_STORE,
  SET_ONLY_OFFICE_URL,
} from '../action-types/onlyOfficeTypes';

const initialState = {
  onlyOfficeUrl: '',
};

function onlyOfficeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ONLY_OFFICE_URL:
      return {
        ...state,
        onlyOfficeUrl: action.payload,
      };
    case CLEAR_ONLY_OFFICE_REDUX_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default onlyOfficeReducer;
