// https://github.com/mui-org/material-ui/tree/master/examples/create-react-app-with-jss/src
import React, { useState } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { create } from 'jss';
import PropTypes from 'prop-types';

// A theme with custom primary and secondary color.
// It's optional.
// In this project we are defining the theme externally, and bringing it in through props
// import theme from '../styles/MaterialTheme';

// Create a JSS instance with the default preset of plugins.
// It's optional.
const jss = create(jssPreset());

function CustomThemeProvider({ children, ...otherParams }) {
  const { theme, productionPrefix } = otherParams;
  const [classNameGenerator] = useState(() =>
    createGenerateClassName({ productionPrefix })
  );

  return (
    <StylesProvider jss={jss} generateClassName={classNameGenerator}>
      {/* ThemeProvider makes the theme available down the React tree
      thanks to React context. */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/*
                          CssBaseline kickstart an elegant,
                          consistent, and simple baseline to build upon.
                        */}
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
}

CustomThemeProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default CustomThemeProvider;
