import {
  SET_AUTHENTICATED,
  SET_CONFIG,
  SET_FINISH_AUTHENTICATION,
  SET_USER,
  UPDATE_MOUNT_COUNT,
} from '../action-types/authActionTypes';

const initialState = {
  authenticated: false,
  authenticationFinished: false,
  config: null,
  user: null,
  mountCount: 0,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload,
      };
    case SET_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    case SET_FINISH_AUTHENTICATION:
      return {
        ...state,
        authenticationFinished: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_MOUNT_COUNT:
      return {
        ...state,
        mountCount: state.mountCount + 1,
      };
    default:
      return state;
  }
}

export default authReducer;
