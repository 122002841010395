import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, Button, Grid, Typography } from '@mui/material';

import EditTextControlsStyles from './styles';

const EditTextControls = ({ classes, text, saveHandler, hidden = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(text);

  useEffect(() => () => setValue(text), []);

  const handleClose = () => {
    setValue(text);
    setIsEditing(false);
  };

  const handleSave = () => {
    saveHandler(value);
    setIsEditing(false);
  };

  return isEditing ? (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={8}>
        <TextField
          variant="standard"
          defaultValue={value}
          multiline
          onChange={(evt) => setValue(evt.target.value)}
        />
      </Grid>
      <Grid item xs={2} className={classes.topMargin}>
        <Button onClick={handleClose}>
          <CloseRoundedIcon className={classes.cancelEditButton} />
        </Button>
      </Grid>
      <Grid item xs={2} className={classes.topMargin}>
        <Button onClick={handleSave}>
          <CheckRoundedIcon className={classes.acceptEditButton} />
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Grid container alignItems="center">
      <Grid item xs={8}>
        <Typography variant="body1" className={classes.ruleText}>
          {text}
        </Typography>
      </Grid>
      {hidden ? null : (
        <Grid item xs={2}>
          <Button onClick={() => setIsEditing(true)}>
            <EditIcon className={classes.makeEditButton} />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

EditTextControls.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  saveHandler: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
};

export default withStyles(EditTextControlsStyles)(EditTextControls);
