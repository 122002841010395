import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

const Flex = ({ children, sx, ...props }) => {
  return (
    <Box sx={{ display: 'flex', ...sx }} {...props}>
      {children}
    </Box>
  );
};

const FlexH = ({ children, sx, ...props }) => {
  return (
    <Flex sx={{ flexDirection: 'row', ...sx }} {...props}>
      {children}
    </Flex>
  );
};

const FlexV = ({ children, sx, ...props }) => {
  return (
    <Flex sx={{ flexDirection: 'column', width: '100%', ...sx }} {...props}>
      {children}
    </Flex>
  );
};

const FLEX_PROPS = {
  children: PropTypes.any,
  sx: PropTypes.object,
  onClick: PropTypes.func,
};

Flex.propTypes = FLEX_PROPS;
FlexV.propTypes = FLEX_PROPS;
FlexH.propTypes = FLEX_PROPS;

const FlexNotation = Object.assign(Flex, { H: FlexH, V: FlexV });

export default FlexNotation;
