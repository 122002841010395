import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Buffer } from 'buffer/'; // <-- no typo here ("/")
import { useAuth0 } from '@auth0/auth0-react';
import Auth from '@aws-amplify/auth';

import { API_CONFIG_ENDPOINT, CURRENT_USER_ENDPOINT } from '../endpoints.config';
import { ACCOUNT_GROUPS_CONFIG, BLACKBOILER_ADMIN_PERMISSION } from '../groups.config';
import { postErrorEvent, postLogEvent } from '../requests/eventlogs';
import { GetCurrentTenant, GetTenants } from '../requests/tenantManagement';
import { LOGIN_ROUTE } from '../routes.config';
import {
  setAuthenticated,
  setAuthenticationFinished,
  setConfig,
  setUser,
  updateMountCount,
} from '../redux/actions/authActions';

const AuthUpdateContext = createContext();
export const useAuthUpdateContext = () => useContext(AuthUpdateContext);

export default function AuthUpdateProvider({
  configNA,
  provider,
  login,
  logoUrl,
  children = null,
}) {
  const dispatch = useDispatch();
  const { authenticated, authenticationFinished, config, user, mountCount } = useSelector(
    (state) => state.Auth
  );

  const [hasRoleAuth, setHasRoleAuth] = useState(false);
  const [tokens, setTokens] = useState({ access: null, id: null });
  const [permissions, setPermissions] = useState(new Set());
  const [roles, setRoles] = useState(new Set());
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [allTenants, setAllTenants] = useState([]);
  const [tenant, setTenant] = useState('');
  const [timeRepeater, setTimeRepeater] = useState(0);

  const {
    getAccessTokenSilently,
    getIdTokenClaims,
    logout: logoutAuth0,
    user: auth0User,
    isLoading: auth0IsLoading,
  } = useAuth0();

  useEffect(() => {
    if (mountCount >= 1) {
      // This is to detect an issue, AuthUpdateProvider should not be re-mounting
      const msg = `AuthUpdateProvider component mounted more than once. Mount # ${mountCount}`;
      console.warn(msg);
      sendEventMetric('auth_provider', msg);
    }
    dispatch(updateMountCount());
  }, []);

  useEffect(() => {
    if (auth0User) {
      dispatch(setUser(auth0User.email));
    } else if (!auth0IsLoading) {
      dispatch(setAuthenticationFinished(true));
    }
  }, [auth0User, auth0IsLoading]);

  useEffect(() => {
    if (user) {
      checkAuthTokens();
    }
  }, [user]);

  useEffect(() => {
    if (config?.useTenantManagement && permissions.has(BLACKBOILER_ADMIN_PERMISSION)) {
      getAllTenants();
      getTenant();
    }
    if (authenticated && !config && config !== undefined) {
      sendErrorMetric(
        `An unexpected error occurred: config is ${String(config)} while the user is logged in`,
        null
      );
      getConfig();
    }
  }, [config, permissions]);

  useEffect(() => {
    if (user && tokens.id && tokens.access) {
      dispatch(setAuthenticated(true));
      dispatch(setAuthenticationFinished(true));
      getConfig();
      updateUserPermissions();
    }
  }, [user, tokens]);

  useEffect(() => {
    // This interesting little roundabout way to do setInterval is necessary
    // as a normal setInterval results in the "tokens" state to never change
    // which causes setTokens() inside of checkAuthTokens() to be called repeatedly
    const timer = setTimeout(() => {
      checkAuthTokens();
      setTimeRepeater(timeRepeater + 1);
    }, 60000);
    return () => {
      clearTimeout(timer);
    };
  }, [timeRepeater]);

  async function checkAuthTokens() {
    let newTokens = { access: null, id: null };
    switch (provider) {
      case 'aws':
        try {
          const session = await Auth.currentSession();
          newTokens = {
            access: session.accessToken.jwtToken,
            id: session.idToken.jwtToken,
          };
        } catch (err) {
          dispatch(setAuthenticated(false));
          console.error('Failed to get auth tokens from AWS with error:', err);
        }
        break;

      case 'auth0':
        try {
          // Don't update tokens if you don't need to
          let accessToken = tokens?.access;
          let idToken = tokens?.id;
          let isExpired = false;
          if (accessToken) {
            const { exp } = decodeJWT(accessToken);
            const expirationDate = new Date(exp * 1000);
            if (expirationDate <= new Date()) {
              isExpired = true;
              console.debug(`Auth0 access token expired on ${expirationDate}`);
            }
          }
          if (isExpired || !accessToken) {
            console.debug(`Fetching new Auth0 token`);
            accessToken = await getAccessTokenSilently();
            const idTokenResp = await getIdTokenClaims();
            idToken = idTokenResp.__raw;
          }
          newTokens = {
            access: accessToken,
            id: idToken,
          };
        } catch (err) {
          dispatch(setAuthenticated(false));
          console.error('Failed to get token from Auth0 with error:', err);
        }
        break;

      default:
        dispatch(setAuthenticated(false));
        throw new Error(`Auth Provider "${provider}" not supported`);
    }
    const { access, id } = tokens;
    const { access: newAccess, id: newId } = newTokens;
    if (access !== newAccess || id !== newId) {
      setTokens(newTokens);
      if (newTokens?.access) {
        const { exp: accessExpiry } = decodeJWT(newTokens?.access);
        console.debug(`Access Token will expire at ${new Date(accessExpiry * 1000)}`);
      }
      if (newTokens?.id) {
        const { exp: identityExpiry } = decodeJWT(newTokens.id);
        console.debug(`Identity Token will expire at ${new Date(identityExpiry * 100)}`);
      }
    }
    return newTokens;
  }

  async function getAuthHeader() {
    const { access } = await checkAuthTokens();
    if (!access) {
      throw new Error('No access token available');
    }
    return {
      Authorization: `BEARER ${access}`,
    };
  }

  async function getCurrentUser() {
    const headers = await getAuthHeader();
    try {
      const { data: currentUser } = await axios.post(
        `${CURRENT_USER_ENDPOINT}`,
        { idToken: tokens?.id },
        { headers }
      );
      return currentUser;
    } catch (e) {
      console.error(e);
      logout();
    }
  }

  async function updateUserPermissions() {
    const currentUser = await getCurrentUser();
    const { accountGroups } = currentUser;
    const permissionsSet = retrievePermissions(accountGroups.map(({ _id: id }) => id));
    setPermissions(permissionsSet);
    const roleSet = retrieveRoles(currentUser);
    setRoles(roleSet);
    setHasRoleAuth(true);
    setIsFirstLoad(true);
  }

  async function getAllTenants() {
    const headers = await getAuthHeader();
    try {
      const data = await GetTenants(headers);
      setAllTenants(data);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  async function getTenant() {
    const headers = await getAuthHeader();
    try {
      const data = await GetCurrentTenant(headers);
      setTenant(data);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  async function getConfig() {
    const headers = await getAuthHeader();
    try {
      const { data } = await axios.get(API_CONFIG_ENDPOINT, { headers });
      dispatch(setConfig(data));
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  function decodeJWT(token) {
    if (!token) {
      return null;
    }
    const jwtPayload = token.split('.')[1];
    return JSON.parse(Buffer.from(jwtPayload, 'base64').toString());
  }

  function getFamilyName() {
    const familyName = decodeJWT(tokens.id)?.family_name;
    // Capitalize first letter
    return familyName?.charAt(0).toUpperCase() + familyName?.slice(1);
  }

  function getGivenName() {
    const givenName = decodeJWT(tokens.id)?.given_name;
    // Capitalize first letter
    return givenName?.charAt(0).toUpperCase() + givenName?.slice(1);
  }

  function getEmail() {
    const token = decodeJWT(tokens.id);
    if (!token) {
      return;
    }
    if (token.email) {
      return token.email;
    }
    if (
      'identities' in token &&
      token.identities.length > 0 &&
      token.identities[0].userId
    ) {
      return token.identities[0].userId;
    }
    return 'UNKNOWN@UNKNOWN.COM';
  }

  function logout() {
    switch (provider) {
      case 'aws':
        Auth.signOut();
        break;
      case 'auth0':
        logoutAuth0({ returnTo: `${window.location.origin}${LOGIN_ROUTE}` });
        break;
      case 'development':
        window.location.replace(`${window.location.origin}${LOGIN_ROUTE}`);
        break;
      default:
        console.error('Failed to logout unknown auth provider:', provider);
    }
  }

  function retrievePermissions(roles) {
    const permissionsSet = new Set();
    roles.forEach((role) => {
      // ignore invalid/unknown roles
      if (ACCOUNT_GROUPS_CONFIG[role]) {
        const { [role]: rights } = ACCOUNT_GROUPS_CONFIG;
        Object.entries(rights).map(([right, isEnabled]) =>
          isEnabled ? permissionsSet.add(right) : null
        );
      }
    });
    return permissionsSet;
  }

  function retrieveRoles(user) {
    const { accountGroups } = user;
    return new Set(accountGroups.map(({ _id: id }) => id));
  }

  function hasAnyRole(requiredRoles) {
    if (!roles.size) {
      return false;
    }
    if (!requiredRoles || !requiredRoles.length) {
      return false;
    }
    if (roles.has('blackboiler') || roles.has('admin')) {
      return true;
    }
    for (const requiredRole of requiredRoles) {
      if (roles.has(requiredRole)) {
        return true;
      }
    }
    return false;
  }

  function hasEveryRole(requiredRoles) {
    if (!roles.size) {
      return false;
    }
    if (!requiredRoles || !requiredRoles.length) {
      return false;
    }
    if (roles.has('blackboiler') || roles.has('admin')) {
      return true;
    }
    for (const requiredRole of requiredRoles) {
      if (!roles.has(requiredRole)) {
        return false;
      }
    }
    return true;
  }

  async function sendErrorMetric(errMsg, stack) {
    const headers = await getAuthHeader();
    try {
      return await postErrorEvent(
        {
          message: errMsg,
          stack,
          clientUrl: window.location.href,
        },
        headers
      );
    } catch (err) {
      console.error(`Failed to post error event log message ERROR: ${errMsg}`, err);
    }
  }

  async function sendEventMetric(eventName, eventMsg) {
    const headers = await getAuthHeader();
    try {
      return await postLogEvent(
        {
          eventName,
          message: eventMsg,
        },
        headers
      );
    } catch (err) {
      console.error(`Failed to post event log message ${eventName}: ${eventMsg}`, err);
    }
  }

  return (
    <AuthUpdateContext.Provider
      value={{
        accessToken: tokens?.access,
        authenticated,
        authenticationFinished,
        allTenants,
        config,
        hasRoleAuth,
        idToken: tokens?.id,
        isFirstLoad,
        login,
        logoUrl,
        permissions,
        provider,
        roles,
        tenant,
        user,
        checkAuthTokens,
        getAuthHeader,
        getEmail,
        getFamilyName,
        getGivenName,
        hasAnyRole,
        hasEveryRole,
        logout,
        setConfig,
        setIsFirstLoad,
        getCurrentUser,
        sendErrorMetric,
        sendEventMetric,
      }}
    >
      {!!configNA && children}
    </AuthUpdateContext.Provider>
  );
}

AuthUpdateProvider.propTypes = {
  configNA: PropTypes.object.isRequired,
  provider: PropTypes.string.isRequired,
  login: PropTypes.func.isRequired,
  logoUrl: PropTypes.string.isRequired,
  children: PropTypes.any,
};
