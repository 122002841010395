import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Button, Grid, Typography } from '@mui/material';

import { BaseDialog } from '../../components';
import FastTrackStyles from '../../styles/FastTrackStyles';

function DeleteConfirmationDialog({
  classes,
  open,
  handleDelete,
  handleClose,
  poaData,
  deleteDisabled,
}) {
  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      showContractIcon
      purpose="Remove PoA"
      title="Removing PoA"
    >
      <Grid container textAlign="center">
        <Grid item>
          <Typography variant="body2" className={`${classes.paper}`}>
            Removing this PoA will remove all data associated including all attached
            files. Are you sure you want to continue?
          </Typography>
        </Grid>
      </Grid>
      <Grid container item justifyContent="space-around">
        <Grid item>
          <Button onClick={handleClose} variant="outlined" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={deleteDisabled}
            onClick={() => handleDelete(poaData)}
            color="error"
            variant="contained"
            fullWidth
          >
            Remove
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
}

DeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  poaData: PropTypes.object.isRequired,
  deleteDisabled: PropTypes.bool,
};

export default withStyles(FastTrackStyles)(DeleteConfirmationDialog);
