// import Colors from '../../styles/Colors';

const HeaderStyles = () => ({
  root: {
    backgroundColor: 'black',
  },
  white: {
    backgroundColor: 'white',
    padding: 0,
  },
});

export default HeaderStyles;
