import React from 'react';
import { Box, Typography } from '@mui/material';
import { TextDiff } from '../TextDiff';
import { EDIT_PREVIEW_TYPES } from '../../config';
import * as editUtils from '../../utils/EditUtils';
import Colors from '../../styles/Colors';

const styles = {
  tailBlurbStyle: {
    fontStyle: 'italic',
    fontSize: '12px',
  },
  tailTextPreviewStyle: {
    color: Colors.textDiffGreen,
  },
};

function TextDiffWithContext({ trainingRecord }) {
  const { editPreviewType, payload } = editUtils.beforeAndAfterEdit(trainingRecord);

  switch (editPreviewType) {
    case EDIT_PREVIEW_TYPES.NORMAL:
      const [originalText, modifiedText] = payload;
      return <TextDiff original={originalText} modified={modifiedText} />;
    case EDIT_PREVIEW_TYPES.TAIL:
      const { contextBlurb, insertedSentence } = payload;
      return (
        <Box>
          <Typography variant="subtitle2" sx={styles.tailBlurbStyle}>
            {contextBlurb}
          </Typography>
          <Typography component="span" sx={styles.tailTextPreviewStyle}>
            {insertedSentence}
          </Typography>
        </Box>
      );
    default:
      return (
        <Box>
          <Typography variant="subtitle2" sx={styles.tailBlurbStyle}>
            Training data with type {trainingRecord?.train_type} is not yet supported for
            preview
          </Typography>
        </Box>
      );
  }
}

export default TextDiffWithContext;
