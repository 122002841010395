import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import 'draft-js/dist/Draft.css';

import withStyles from '@mui/styles/withStyles';
import InfoIcon from '@mui/icons-material/Info';
import ListIcon from '@mui/icons-material/List';
import { Checkbox, Button, Grid, Typography } from '@mui/material';

import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { setSuccessMsg } from '../../redux/actions/snackbarActions';
import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';
import CombineStyles from '../../utils/CombineStyles';
import { UpdateRuleText } from '../../utils/requests';
import { CircularLoading } from '../CircularLoading';
import { EditTextControls } from '../EditTextControls';
// import { SignalStrength } from '../SignalStrength';
import { TooltipRuleInfo } from '../TooltipRuleInfo';
import { TrainTypePill } from '../TrainTypePill';
import RulesGridStyles from './styles';

const RulesGrid = ({
  classes,
  sections,
  sectionIdToRuleIds,
  handleEditRule = (f) => f,
  selectable = false,
  setSelectedIds = (f) => f,
  updateTextById = (f) => f,
  editable = true,
  isLoading = false,
}) => {
  const { getAuthHeader } = useAuthUpdateContext();

  const dispatch = useDispatch();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const handleCheckboxToggle = (event) => {
    const updatedCheckboxes = {
      ...selectedCheckboxes,
      [event.target.name]: event.target.checked,
    };
    setSelectedCheckboxes(updatedCheckboxes);
    const selectedIds = Object.entries(updatedCheckboxes)
      .filter(([, isChecked]) => isChecked)
      .map(([id]) => id);
    setSelectedIds(selectedIds);
  };

  const handleUpdateText = (sectionId, ruleId, name, description) => {
    getAuthHeader().then((headers) => {
      UpdateRuleText(headers, ruleId, name, description)
        .then(() => {
          updateTextById(sectionId, ruleId, name, description);
          dispatch(setSuccessMsg('Rule updated successfully'));
        })
        .catch((err) => {
          console.log('Failed to update rule with error:', err);
        });
    });
  };

  const getRuleCountStr = (rules) => {
    if (!rules) {
      return '0 Rules';
    }
    const rulesLength = rules.length;
    if (rulesLength !== 1) {
      return `${rulesLength} Rules`;
    }
    return '1 Rule';
  };

  if (isLoading) {
    return <CircularLoading />;
  }

  return sections && sectionIdToRuleIds
    ? sections.map(({ _id, name }) =>
        sectionIdToRuleIds[_id] && sectionIdToRuleIds[_id].length > 0 ? (
          <div key={`section_${_id}`}>
            <div
              className="pb-3"
              style={{ fontSize: '16px' }}
              key={`section_name_${_id}`}
            >
              <strong>{name}</strong>
              <span className={classes.RuleCountHeader}>
                {getRuleCountStr(sectionIdToRuleIds[_id])}
              </span>
            </div>
            {sectionIdToRuleIds[_id]
              ? sectionIdToRuleIds[_id].map((tr) => (
                  <Grid
                    key={tr._id}
                    container
                    direction="column"
                    className={classes.DataRow}
                  >
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      key={`rule_row_${tr._id}`}
                      style={{ minHeight: 40 }}
                      spacing={1}
                    >
                      <Grid item xs={4}>
                        <EditTextControls
                          text={tr.name}
                          hidden={!editable}
                          saveHandler={(text) =>
                            handleUpdateText(_id, tr._id, text, tr.description)
                          }
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <EditTextControls
                          text={tr.description}
                          hidden={!editable}
                          saveHandler={(text) =>
                            handleUpdateText(_id, tr._id, tr.name, text)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body1" className={classes.ruleText}>
                          {tr.size} sentence{tr.size === 1 ? '' : 's'}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={2}> */}
                      {/*    <SignalStrength strength={tr.strength} className={classes.ruleText} /> */}
                      {/* </Grid> */}
                      {selectable ? (
                        <Grid item xs={1} container justifyContent="flex-end">
                          <Checkbox
                            color="primary"
                            name={tr._id.toString()}
                            checked={selectedCheckboxes[tr._id] === true}
                            onChange={handleCheckboxToggle}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={1}>
                          <Typography variant="body1">
                            <Button
                              className={classes.EditRuleButton}
                              onClick={(evt) => handleEditRule(evt, tr._id, tr.sections)}
                            >
                              <ListIcon />
                            </Button>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item className={classes.DottedLineGridWrapper}>
                      <div className={classes.dottedLine} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={11}>
                        <Grid
                          item
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={4}
                        >
                          <Grid item xs={1}>
                            <Typography variant="body1">Tags:</Typography>
                          </Grid>
                          {tr.trainTypes.sort().map((tt) => (
                            <Grid item xs={2} key={`train_type_${tt}`}>
                              <TrainTypePill trainType={tt} noGrid />
                            </Grid>
                          ))}
                          {!tr.playbookIds?.length && (
                            <Grid item xs={2} key={`train_type_schemaV1`}>
                              <TrainTypePill
                                generic={true}
                                genericText={'Schema V1'}
                                noGrid
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={1}>
                        <TooltipRuleInfo
                          dateCreated={tr.dateCreated}
                          dateModified={tr.dateModified}
                          createdBy={tr?.createdBy?.email}
                          modifiedBy={tr?.modifiedBy?.email}
                        >
                          <InfoIcon
                            style={{ marginLeft: selectable ? 37.5 : 33, marginTop: 5 }}
                          />
                        </TooltipRuleInfo>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              : null}
          </div>
        ) : null
      )
    : null;
};

RulesGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  sectionIdToRuleIds: PropTypes.object.isRequired,
  handleEditRule: PropTypes.func,
  selectable: PropTypes.bool,
  setSelectedIds: PropTypes.func,
  updateTextById: PropTypes.func,
  editable: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const combinedStyles = CombineStyles(PlaybookBuilderStyles, RulesGridStyles);
export default withStyles(combinedStyles)(RulesGrid);
