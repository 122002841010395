export const METRICS = {
  ERROR: 'ERROR',
  USER: 'USER',
  WEBVITALS: 'WEBVITALS',
};

export const METRIC_ACTIONS = {
  PAGEVIEW: 'PAGEVIEW',
  DOCUMENT_SET: 'DOCUMENT_SET',
  DOCUMENT_LOST: 'DOCUMENT_LOST',
  ACTIVITY_SET: 'ACTIVITY_SET',
  ACTIVITY_LOST: 'ACTIVITY_LOST',
  INVALID_FILE_UPLOAD: 'INVALID_FILE_UPLOAD',
};
