import Colors from './Colors';

const FastTrackStyles = () => ({
  paper: {
    backgroundColor: Colors.trueWhite,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 25,
    paddingRight: 25,
    borderRadius: 10,
  },
  menu: {
    fontSize: 12,
    float: 'left',
  },
  menuSelect: {
    fontSize: 12,
  },
  copyRecipients: {
    fontSize: 16,
    float: 'left',
    fontWeight: 'bold',
  },
  centerInputRightAlignText: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  selectorSelect: {
    width: '100%',
    height: '65%',
    backgroundColor: Colors.trueWhite,
  },
  selectorNoSelect: {
    paddingLeft: 20,
    width: '100%',
    height: 37.5,
    backgroundColor: 'white',
    borderStyle: 'dotted',
  },
  selectorInput: {
    width: '100%',
    height: 40,
    backgroundColor: Colors.trueWhite,
    borderStyle: 'dotted',
  },
  formikSelect: {
    float: 'left',
    width: '90%',
    backgroundColor: Colors.trueWhite,
    height: 40,
    padding: 0,
  },
  formikInput: {
    float: 'left',
    backgroundColor: Colors.trueWhite,
    padding: 0,
    boxShadow: '0px',
    borderWidth: 1,
    fontSize: 16,
  },
  formikFileInput: {
    float: 'left',
    backgroundColor: Colors.trueWhite,
    padding: 0,
    boxShadow: '0px',
    borderWidth: 1,
    borderStyle: 'solid',
    fontSize: 16,
    marginTop: -7,
  },
  formikAutocomplete: {
    float: 'left',
    width: '100%',
    backgroundColor: Colors.trueWhite,
    height: 40,
    padding: 0,
    boxShadow: '0px',
    borderWidth: 1,
    fontSize: 16,
  },
  formikDatePicker: {
    width: '100%',
    height: 40,
    padding: 5,
    borderRadius: 5,
    borderStyle: 'dotted',
  },
  inputActionButton: {
    backgroundColor: Colors.lightBlue,
    color: Colors.trueWhite,
    height: 45,
    '&:hover': {
      backgroundColor: Colors.blue,
      color: Colors.trueWhite,
    },
  },
  alignLeft: {
    textAlign: 'left',
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
  zeroPaddingTop: {
    paddingTop: 0,
  },
  zeroPaddingBottom: {
    paddingBottom: 0,
  },
  sectionHeader: {
    float: 'left',
    fontSize: 25,
    paddingBottom: 15,
  },
  yupErrorMsg: {
    color: Colors.red,
    float: 'left',
    paddingTop: 0,
  },
  responseErrorMsg: {
    color: Colors.red,
    paddingTop: 15,
  },
  actionStatusText: {
    paddingBottom: 10,
    paddingTop: 20,
    textAlign: 'center',
    color: Colors.blue,
  },
  displayBlock: {
    backgroundColor: Colors.trueWhite,
    padding: 25,
  },
  flexWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexible: {
    flex: '1 1 auto',
  },
  spinner: {
    margin: '0 10px',
  },
  submittedMessage: {
    fontSize: 16,
    margin: '0 10px',
    color: Colors.green,
  },
  topMargin: {
    marginTop: 15,
  },
  buttonContainer: {
    marginTop: 10,
  },
  downloadBtn: {
    backgroundColor: Colors.grey10,
    color: Colors.black,
    '&:hover': {
      backgroundColor: Colors.grey3,
      color: Colors.black,
    },
  },
  clearButton: {
    color: Colors.black,
    backgroundColor: Colors.white,
    borderColor: Colors.black,
    '&:hover': {
      backgroundColor: Colors.red,
      color: Colors.trueWhite,
    },
  },
  submitButton: {
    backgroundColor: Colors.blue,
    color: Colors.trueWhite,
    '&:hover': {
      backgroundColor: Colors.green,
      color: Colors.trueWhite,
    },
  },
  responseBlock: {
    marginBottom: 0,
    marginTop: 20,
  },
  statusInfoText: {
    marginBottom: 10,
    marginTop: 20,
    color: Colors.blue,
  },
  docusignRecipientText: {
    float: 'left',
  },
  formfillSectionMarginTop: {
    marginTop: 25,
  },
  checkboxFormControl: {
    float: 'left',
    textAlign: 'left',
    width: '100%',
  },
  clarifyingText: {
    float: 'left',
    fontWeight: 'bold',
    overflowWrap: 'normal',
    width: 325,
    fontSize: 13,
  },
  inputFormikRequiredText: {
    paddingTop: 0,
    width: '80%',
  },
  gatingQuestionsErrorText: {
    textAlign: 'center',
    marginTop: 10,
  },
  noBold: {
    fontWeight: 'normal',
  },
  highlightRed: {
    color: Colors.red,
  },
  highlightBlue: {
    color: Colors.blue,
  },
  highlightBlack: {
    color: Colors.black,
  },
  baseBody: {
    backgroundColor: Colors.pageContentBlue,
    height: '100vh',
    overflow: 'auto',
  },
  baseFrame: {
    marginTop: 25,
    width: '80%',
    margin: 'auto',
  },
  baseFullWidth: {
    width: '100%',
  },
  gatingTitle: {
    marginBottom: 5,
  },
  wrapOnNewline: {
    whiteSpace: 'pre-wrap',
  },
  noFastTrackConfigFound: {
    marginTop: 25,
  },
  link: {
    color: Colors.blue,
  },
});

export default FastTrackStyles;
