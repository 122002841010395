import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Grid, Typography } from '@mui/material';

import { TRAIN_TYPES, TRAIN_TYPES_MAP } from '../../config';
import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';

const TrainTypePill = ({
  classes,
  trainType = '',
  title = null,
  noGrid = false,
  generic = false,
  genericText = '',
  selected = false,
}) => (
  <>
    {noGrid ? (
      <div
        className={`${classes.TrainTypePill} ${generic ? classes.TrainTypePillStatusGeneric : classes[`TrainTypePillStatus${trainType}`]} ${generic && selected ? classes.TrainTypePillStatusGenericSelected : ''}`}
      >
        <Typography variant="body1" className={`${classes.TrainTypePillText}`}>
          {generic ? genericText : TRAIN_TYPES_MAP[trainType]}
        </Typography>
      </div>
    ) : (
      <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
        <Grid item>
          <Typography variant="body1">
            <strong>{title}</strong>
          </Typography>
        </Grid>
        <Grid item>
          <span
            className={`${classes.TrainTypePill} ${classes[`TrainTypePillStatus${trainType}`]}`}
          >
            <Typography variant="body1" className={classes.TrainTypePillText}>
              {TRAIN_TYPES_MAP[trainType]}
            </Typography>
          </span>
        </Grid>
      </Grid>
    )}
  </>
);

TrainTypePill.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  trainType: PropTypes.oneOf(Object.values(TRAIN_TYPES)),
  generic: PropTypes.bool,
  genericText: PropTypes.string,
  selected: PropTypes.bool,
  noGrid: PropTypes.bool,
};

export default withStyles(PlaybookBuilderStyles)(TrainTypePill);
