const SignalStrengthStyles = () => ({
  SignalCellularBarIcon0: {
    color: 'darkred',
  },
  SignalCellularBarIcon1: {
    color: 'red',
  },
  SignalCellularBarIcon2: {
    color: 'yellowgreen',
  },
  SignalCellularBarIcon3: {
    color: 'green',
  },
  SignalCellularBarIcon4: {
    color: 'darkgreen',
  },
});

export default SignalStrengthStyles;
