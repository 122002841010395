import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Grid, Typography } from '@mui/material';

import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';
import combineStyles from '../../utils/CombineStyles';
import PlaybookBuilderOptionsHeaderStyles from './PlaybookBuilderOptionsHeaderStyles';

const PlaybookBuilderOptionsHeader = ({
  classes,
  title,
  extendedTitle,
  description,
  currentStep = null,
  totalSteps = null,
  basicStylingOnly = false,
}) => (
  <div
    className={
      basicStylingOnly
        ? classes.basicStylingHeader
        : classes.playbookBuilderOptionsHeaderWrapper
    }
  >
    <Grid container direction="column">
      {currentStep && totalSteps ? (
        <Grid item style={{ marginBottom: 15 }}>
          <Typography variant="body1" className={classes.blueSubHeader}>
            step {currentStep} of {totalSteps}
          </Typography>
        </Grid>
      ) : null}
      <Grid container item direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="h1">{title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h1" style={{ fontWeight: 'normal' }}>
            {extendedTitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body2">{description}</Typography>
      </Grid>
    </Grid>
  </div>
);

PlaybookBuilderOptionsHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  extendedTitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  basicStylingOnly: PropTypes.bool,
};

const combinedStyles = combineStyles(
  PlaybookBuilderOptionsHeaderStyles,
  PlaybookBuilderStyles
);

export default withStyles(combinedStyles)(PlaybookBuilderOptionsHeader);
