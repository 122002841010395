export function addDays(date, days) {
  const dateObj = new Date(date);
  if (isNaN(dateObj)) {
    console.warn(`${date} is not a valid date, using today's date`);
    return new Date();
  }
  if (!days) {
    return dateObj;
  }
  return new Date(dateObj.setDate(dateObj.getDate() + days));
}
