import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Grid, Typography, IconButton } from '@mui/material';

import { SECTION_STATUS, SECTION_STATUS_TO_DISPLAY_TEXT } from './constants';
import AnswerQuestionsStatusStyles from './styles';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { MAX_RULE_STRENGTH } from '../../config';
import {
  ResetAnswerQuestions,
  SetCurrentSection,
  SetSectionStatus,
  DeleteSectionStatus,
  SetCurrentQuestion,
} from '../../redux/actions/answerQuestionsActions';
import { setErrMsg, setSuccessMsg } from '../../redux/actions/snackbarActions';
import { SaveAnswerQuestionsRules } from '../../utils/requests';
import { PlaybookBuilderPage, BaseDialogPage } from '../../components';

function AnswerQuestionsStatus({
  classes,
  modelId,
  handleNext,
  handleBack,
  redirectTo,
  isOnboarding = false,
}) {
  const {
    getAuthHeader,
    config: { ruleStrengthDivisor },
  } = useAuthUpdateContext();

  const dispatch = useDispatch();
  const setSuccess = (value) => dispatch(setSuccessMsg(value));
  const setErr = (value) => dispatch(setErrMsg(value));

  const { statusLookup, questionnaire, questionTypes } = useSelector(
    (state) => state.AnswerQuestions
  );

  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    setDisableSave(false);
    if (!statusLookup || Object.keys(statusLookup).length === 0) {
      handleBack();
    }
  }, [statusLookup]);

  function backLink() {
    handleBack();
  }

  function isSectionIncomplete() {
    return Object.entries(statusLookup || {}).some(([_, section]) => {
      return [SECTION_STATUS.IN_PROGRESS, SECTION_STATUS.INCOMPLETE].includes(
        section.status
      );
    });
  }

  function handleRemove(section) {
    dispatch(DeleteSectionStatus(section._id));
  }

  function startOrEditLink(section) {
    dispatch(SetCurrentSection(section));
    dispatch(SetCurrentQuestion(section.questions[0]));
    dispatch(SetSectionStatus(section._id, SECTION_STATUS.IN_PROGRESS));
    if (isOnboarding) {
      handleNext();
    } else {
      handleNext(`../${section.className}`);
    }
  }

  async function saveLink() {
    setDisableSave(true);
    const payload = toRequestBody();
    const headers = await getAuthHeader();
    try {
      const success = await SaveAnswerQuestionsRules(headers, payload);
      if (!success) {
        setErr('Failed to create rules');
      }
      // redirect to the rules library page for the model
      dispatch(ResetAnswerQuestions());
      if (!isOnboarding) {
        setSuccess('Rules created');
      }
      redirectTo();
    } catch (err) {
      console.error(err);
      setErr('Failed to create rules');
    } finally {
      setDisableSave(false);
    }
  }

  function toRequestBody() {
    const payload = {
      questionnaireRules: Object.entries(statusLookup)
        .map(([_, section]) => section)
        .filter(({ status }) => status === SECTION_STATUS.COMPLETE)
        .flatMap((section) => questionnaireToRules(section['questions'])),
      destPlaybookId: modelId,
      srcPlaybookIds: questionnaire.playbookIds,
      ruleStrengthDivisor,
      ruleStrengthMax: MAX_RULE_STRENGTH,
      selectedSectionIds: Object.entries(statusLookup)
        .filter(([_, { status }]) => status === SECTION_STATUS.COMPLETE)
        .map(([sectionId, _]) => sectionId),
      unselectedSectionIds: Object.entries(statusLookup)
        .filter(([_, { status }]) => status === SECTION_STATUS.UNSELECTED)
        .map(([sectionId, _]) => sectionId),
    };
    return payload;
  }

  function questionnaireToRules(questions) {
    if (!questions || !questions.length) {
      return [];
    }
    return questions.flatMap((q) => {
      const { answer, options, type: qType } = q;
      let selectedOptionRules = {};
      switch (qType) {
        case questionTypes.YES_NO:
          selectedOptionRules = options[answer];
          break;
        case questionTypes.YES_NO_TEXT_EDITOR:
          const { value: optionAnswer, ...extraOpts } = answer;
          selectedOptionRules = Object.keys(options[optionAnswer] || {}).reduce(
            (acc, ruleId) => {
              acc[ruleId] = extraOpts || true;
              return acc;
            },
            {}
          );
          break;
        case questionTypes.LIST_EDIT:
          selectedOptionRules = Object.keys(options).reduce((acc, currRule) => {
            acc[currRule] = { listEditData: answer };
            return acc;
          }, {});
          break;
        case questionTypes.GOVERNING_LAW:
        case questionTypes.FORUM:
          const nanoSlotData = {
            allowedLocations: answer.allowedLocations.map((loc) => loc.id),
            excludedLocations: answer.excludedLocations.map((loc) => loc.id),
          };
          selectedOptionRules = Object.keys(options).reduce((acc, currRule) => {
            acc[currRule] = {
              nanoSlotData: {
                ...nanoSlotData,
                preferredLocation: nanoSlotData.allowedLocations[0],
              },
            };
            return acc;
          }, {});
          break;
        default:
          throw new Error(`Unsupported question type ${qType}`);
      }

      if (!Object.keys(selectedOptionRules).length) {
        console.warn(`No rules specified on answer ${answer} for question ${q._id}`);
        return [];
      }
      return Object.entries(selectedOptionRules).map(([ruleId, params]) => ({
        ruleId,
        cloneParams: params,
        questionType: q.type,
        sectionId: q.section,
      }));
    });
  }

  const renderContent = (
    <Grid container direction="column">
      {Object.entries(statusLookup || {}).map(([sectionId, sectionData], idx) => (
        <React.Fragment key={sectionId}>
          <div className={classes.dividerTop} />
          <Grid item key={sectionId} container alignItems="center">
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h3">
                  <span>{sectionData.name}</span>
                  {sectionData.status !== SECTION_STATUS.UNSELECTED ? (
                    <span className={classes.ruleCount}>
                      {sectionData.questions.length === 1
                        ? `(1 Question)`
                        : `(${sectionData.questions.length} Questions)`}
                    </span>
                  ) : (
                    <span className={classes.ruleCount}>(Default)</span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <Typography>
                      {SECTION_STATUS_TO_DISPLAY_TEXT[sectionData.status]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {sectionData.status === SECTION_STATUS.INCOMPLETE ? (
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.startEditButton}
                        onClick={() => startOrEditLink(sectionData)}
                      >
                        Start
                      </Button>
                    ) : (
                      (sectionData.status === SECTION_STATUS.IN_PROGRESS ||
                        sectionData.status === SECTION_STATUS.COMPLETE) && (
                        <Button
                          color="primary"
                          variant="outlined"
                          className={classes.startEditButton}
                          onClick={() => startOrEditLink(sectionData)}
                        >
                          Edit
                        </Button>
                      )
                    )}
                  </Grid>
                  <Grid item>
                    {sectionData.status !== SECTION_STATUS.UNSELECTED && (
                      <IconButton
                        aria-label="delete section"
                        onClick={() => handleRemove(sectionData)}
                        size="large"
                      >
                        <DeleteIcon fontSize="small" className={classes.deleteButton} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {Object.keys(statusLookup).length === idx + 1 && (
            <div className={classes.dividerBottom} />
          )}
        </React.Fragment>
      ))}
    </Grid>
  );

  if (isOnboarding) {
    return (
      <BaseDialogPage
        title="NDA Questionnaire"
        description={
          'By answering questions within these sections you will create rules that define' +
          ' how your contracts are edited in the future. You can edit how these rules are built here' +
          ' as well as by custom editing the rules from the model overview screen.'
        }
        lButtonOnClick={backLink}
        rButtonOnClick={saveLink}
        rButtonDisabled={isSectionIncomplete() || disableSave}
        rButtonText="Save"
        showTopNav={false}
      >
        {renderContent}
      </BaseDialogPage>
    );
  }

  return (
    <PlaybookBuilderPage
      title="NDA Questionnaire"
      description={
        'By answering questions within these sections you will create rules that define' +
        ' how your contracts are edited in the future. You can edit how these rules are built here' +
        ' as well as by custom editing the rules from the model overview screen.'
      }
      lButtonOnClick={backLink}
      rButtonOnClick={saveLink}
      rButtonDisabled={isSectionIncomplete() || disableSave}
      rButtonText="Save"
    >
      {renderContent}
    </PlaybookBuilderPage>
  );
}

AnswerQuestionsStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  redirectTo: PropTypes.func.isRequired,
  modelId: PropTypes.string.isRequired,
  isOnboarding: PropTypes.bool,
};

export default withStyles(AnswerQuestionsStatusStyles)(AnswerQuestionsStatus);
