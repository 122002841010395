import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {
  Button,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Logo from '../../assets/images/blackboiler_icon.png';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { LOG_LOGOUT_ENDPOINT, USERGUIDE_ENDPOINT } from '../../endpoints.config';
import {
  ACCOUNT_ADMIN_PERMISSION,
  BLACKBOILER_ADMIN_PERMISSION,
} from '../../groups.config';
import { setErrMsg, setSuccessMsg } from '../../redux/actions/snackbarActions';
import { ChangeTenant } from '../../requests/tenantManagement';
import { ACCOUNT_ROUTE, TENANTS_NEW_ROUTE } from '../../routes.config';
import Colors from '../../styles/Colors';
import { LabeledSelect } from '../LabeledSelect';
import Styles from './AppBarFullStyles';

const AppBarFull = ({ classes, navList, companyName = '', userName, pathname }) => {
  const { getAuthHeader, tenant, allTenants, permissions, config } =
    useAuthUpdateContext();

  const globalSocketId = useSelector((state) => state.GlobalEvent.socketId, shallowEqual);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const setSuccess = (value) => dispatch(setSuccessMsg(value));
  const setErr = (value) => dispatch(setErrMsg(value));

  const [anchorMenu, setAnchorMenu] = useState(null);
  const [anchorProfile, setAnchorProfile] = useState(null);
  const matches = useMediaQuery('(max-width:1070px)');

  const handleMenuClick = (event) => {
    setAnchorMenu(anchorMenu ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorMenu(null);
  };

  const handleProfileClick = (event) => {
    setAnchorProfile(anchorMenu ? null : event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorProfile(null);
  };

  const { logout } = useAuthUpdateContext();
  const handleLogout = () => {
    getAuthHeader().then((headers) => {
      axios
        .get(LOG_LOGOUT_ENDPOINT, { headers })
        .catch((err) => console.log('Failed to log the user logout with error:', err));
    });
    setAnchorProfile(null);
    logout();
  };

  const isActivePath = (path) => {
    const [, , thirdInPathname] = pathname.split('/');
    const [, , thirdInPath] = path.split('/');
    return thirdInPathname === thirdInPath;
  };

  const handleHelpIconClick = (event) => {
    event.preventDefault();
    getAuthHeader().then((headers) => {
      axios.get(USERGUIDE_ENDPOINT, { headers, responseType: 'blob' }).then((resp) => {
        // Build a URL from the file
        const fileURL = URL.createObjectURL(resp.data);
        // Open the URL on new Window
        window.open(fileURL);
      });
    });
  };

  const handleSetTenant = (newTenantId) => {
    if (newTenantId === 'create') {
      navigate(TENANTS_NEW_ROUTE);
    } else {
      getAuthHeader().then((headers) => {
        ChangeTenant(headers, newTenantId, globalSocketId)
          .then(() => {
            setSuccess('Tenant changed');
            window.location.reload(true);
          })
          .catch((e) => {
            console.log(e);
            setErr('Failed to change tenant');
          });
      });
    }
  };

  const showTenantSelect =
    !!tenant && !!Object.keys(tenant).length && allTenants.length > 0;

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid
          container
          item
          alignItems="center"
          spacing={1}
          md={8}
          className={classes.wrapper}
        >
          <Grid item>
            <Link component={RouterLink} to="/" underline="hover">
              <img src={Logo} alt="blackboiler logo" className={classes.logo} />
            </Link>
          </Grid>
          {matches ? (
            <Grid item>
              <IconButton
                aria-controls="appbar-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
                color="inherit"
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          ) : (
            navList.map(({ label, path, role }) =>
              role ? (
                <Grid item key={path}>
                  <Link component={RouterLink} to={path} underline="none">
                    <Typography
                      className={`${classes.primaryLink} ${isActivePath(path) ? classes.primaryLinkActive : ''}`}
                    >
                      {label}
                    </Typography>
                  </Link>
                </Grid>
              ) : null
            )
          )}
        </Grid>

        <Grid
          container
          item
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          md={4}
        >
          <Grid item xs={showTenantSelect ? 4 : 2}>
            {showTenantSelect && allTenants.length > 1 ? (
              <LabeledSelect
                title=""
                value={tenant._id}
                setValue={(evt) => handleSetTenant(evt)}
                items={allTenants}
                keyField="_id"
                valueField="displayName"
                styleProps={{
                  fontWeight: 'bold',
                  color: Colors.trueWhite,
                  backgroundColor: Colors.black,
                }}
                extraMenuItems={[
                  !!config?.useTenantManagement &&
                    permissions.has(BLACKBOILER_ADMIN_PERMISSION) && (
                      <MenuItem key="create-tenant-button" value="create">
                        <Grid container alignItems="center">
                          <Grid item>
                            <Typography className={classes.createTenantButton}>
                              Create Tenant <AddIcon />
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ),
                ]}
              />
            ) : (
              <Typography className={classes.textDisplay} data-cy="AppBarCompanyName">
                {allTenants && !!allTenants.length && allTenants.length === 1
                  ? allTenants[0].displayName
                  : companyName}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton color="inherit" size="large" onClick={handleHelpIconClick}>
              <HelpIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton color="inherit" size="large">
              <NotificationsActiveIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item>
            <Button
              className={classes.profileButton}
              startIcon={<AccountCircleIcon />}
              endIcon={<ExpandMoreIcon />}
              onClick={handleProfileClick}
            >
              {userName.split('@')[0]}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        id="appbar-profile"
        anchorEl={anchorProfile}
        keepMounted
        open={Boolean(anchorProfile)}
        onClose={handleProfileClose}
      >
        {permissions.has(ACCOUNT_ADMIN_PERMISSION) && (
          <Link component={RouterLink} to={ACCOUNT_ROUTE} underline="none">
            <MenuItem
              onClick={handleProfileClose}
              selected={pathname === '/bbweb/account'}
            >
              Account
            </MenuItem>
          </Link>
        )}

        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      </Menu>
      <Menu
        id="appbar-menu"
        anchorEl={anchorMenu}
        keepMounted
        open={Boolean(anchorMenu)}
        onClose={handleMenuClose}
      >
        {navList.map(({ label, path, role }) =>
          role ? (
            <Link component={RouterLink} to={path} underline="none" key={label}>
              <MenuItem key={label} onClick={handleMenuClose}>
                {label}
              </MenuItem>
            </Link>
          ) : null
        )}
      </Menu>
    </>
  );
};

AppBarFull.propTypes = {
  classes: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  navList: PropTypes.array.isRequired,
  companyName: PropTypes.string,
  userName: PropTypes.string.isRequired,
};

export default withStyles(Styles)(AppBarFull);
