const PlaybookBuilderOptionsHeaderStyles = () => ({
  playbookBuilderOptionsHeaderWrapper: {
    backgroundColor: 'white',
    boxShadow: '0px 8px 8px -8px rgba(0,0,0,0.1)',
    marginBottom: 8,
    marginLeft: '5%',
    marginRight: '5%',
    paddingTop: 50,
  },
  basicStylingHeader: {
    paddingTop: 50,
  },
});

export default PlaybookBuilderOptionsHeaderStyles;
