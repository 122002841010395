// eslint-disable-next-line no-template-curly-in-string
export const SLOT_REGEX_TRAINING = new RegExp("\\${[A-z]*:'[A-z ]*'}", 'gi');

export const getSlotDetails = (slotText) => {
  /*
    slotText: "${agreement:'Agreement'}"
     */
  const slot = slotText
    .replace('$', '')
    .replace('{', '')
    .replace('}', '')
    .replaceAll("'", '')
    .split(':');
  return {
    name: slot[0],
    value: slot[0].replace('_', ' '),
  };
};

export const removeSlotsFromText = (text) => {
  const matches =
    [...text.matchAll(SLOT_REGEX_TRAINING)]
      .map((match) => {
        const { name, value } = getSlotDetails(match[0]);
        return {
          name,
          value,
          match: match[0],
          startIdx: match.index,
          length: match[0].length,
        };
      })
      .sort((a, b) => a.startIdx - b.startIdx) || [];

  let slotsRemovedText = text;
  if (matches.length > 0) {
    slotsRemovedText = matches.reduce(
      (acc, { match, name }) => acc.replace(match, name),
      text
    );
  }
  return {
    matches,
    slotsRemovedText,
  };
};
