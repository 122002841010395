import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import PriorityList from './PriorityList';
import PriorityListActionBar from './PriorityListActionBar';

function DualPriorityList({
  includeTitle,
  excludeTitle,
  includeItems,
  excludeItems,
  onIncludeItemsChanged,
  onExcludeItemsChanged,
  ...rest
}) {
  const [includeSelectedItems, setIncludeSelectedItems] = useState([]);
  const [excludeSelectedItems, setExcludeSelectedItems] = useState([]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={5}>
        <PriorityList
          {...rest}
          hideButtons
          items={includeItems}
          onItemsChanged={onIncludeItemsChanged}
          title={includeTitle}
          selectedItems={includeSelectedItems}
          onSelectedItemsChanged={setIncludeSelectedItems}
        />
      </Grid>
      <Grid item xs={2}>
        <PriorityListActionBar
          priorityLists={[
            {
              items: includeItems,
              selectedItems: includeSelectedItems,
              onItemsChanged: onIncludeItemsChanged,
              onSelectedItemsChanged: setIncludeSelectedItems,
            },
            {
              items: excludeItems,
              selectedItems: excludeSelectedItems,
              onItemsChanged: onExcludeItemsChanged,
              onSelectedItemsChanged: setExcludeSelectedItems,
            },
          ]}
        />
      </Grid>
      <Grid item xs={5}>
        <PriorityList
          {...rest}
          hideButtons
          hidePreferred
          items={excludeItems}
          onItemsChanged={onExcludeItemsChanged}
          title={excludeTitle}
          selectedItems={excludeSelectedItems}
          onSelectedItemsChanged={setExcludeSelectedItems}
        />
      </Grid>
    </Grid>
  );
}

DualPriorityList.defaultProps = {
  includeTitle: 'Include Items',
  excludeTitle: 'Exclude Items',
  includeItems: [],
  excludeItems: [],
  onIncludeItemsChanged: (f) => f,
  onExcludeItemsChanged: (f) => f,
  getId: (f) => f.id,
  getLabel: (f) => f.name,
};

DualPriorityList.propTypes = {
  includeTitle: PropTypes.string,
  excludeTitle: PropTypes.string,
  includeItems: PropTypes.array,
  excludeItems: PropTypes.array,
  onIncludeItemsChanged: PropTypes.func,
  onExcludeItemsChanged: PropTypes.func,
  getId: PropTypes.func,
  getLabel: PropTypes.func,
};

export default DualPriorityList;
