import Colors from '../../styles/Colors';

const AdditiveListStyles = () => ({
  minHeight100: {
    minHeight: '100%',
  },
  alignBottom: {
    verticalAlign: 'bottom',
  },
  smallFont: {
    fontSize: 'x-small',
  },
  delete: {
    color: Colors.brightRed,
  },
});

export default AdditiveListStyles;
