// Styling component for all text
import React from 'react';

import { CompositeDecorator } from 'draft-js';
import PropTypes from 'prop-types';

import { getDecoratedStyle } from './ClauseUtils';

const TextEditorStyling = ({ decoratedText, children = null }) => {
  const style = getDecoratedStyle(decoratedText);
  return <span style={style}>{children}</span>;
};

TextEditorStyling.propTypes = {
  decoratedText: PropTypes.string.isRequired,
  children: PropTypes.any,
};

// Text decoration to run on text insertion
const checkSlots = (contentBlock, callback) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null;
  }, callback);
};

export const GenerateDecorator = () =>
  new CompositeDecorator([
    {
      strategy: (contentBlock, callback) => {
        checkSlots(contentBlock, callback);
      },
      component: TextEditorStyling,
    },
  ]);

export const TemplateText = (text, replacement, start, end) =>
  `${text.substring(0, start)}${replacement}${text.substring(end)}`;
