// TODO: Combine with /components/Header/styles.js after components directly restructure

const HeaderStyles = () => ({
  wrapper: {
    padding: '10px 0',
  },
  logo: {
    width: 30,
    height: 30,
    marginRight: 8,
  },
  primaryLink: {
    height: '100%',
    color: 'white',
    fontSize: '1.5rem',
    letterSpacing: 0,
    fontWeight: 400,
    fontStyle: 'normal',
    borderRadius: 0,
    marginRight: 15,
    textTransform: 'none',
  },
  primaryLinkActive: {
    fontWeight: 700,
    // borderBottom: `3px solid ${Colors.blue}`,
    // '& span': {
    //     marginBottom: -3,
    // },
  },
  textDisplay: {
    display: 'inline',
    color: 'white',
    marginRight: 15,
    fontSize: 14,
  },
  profileButton: {
    color: 'white',
    textTransform: 'capitalize',
  },
  createTenantButton: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

export default HeaderStyles;
