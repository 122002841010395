import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import withStyles from '@mui/styles/withStyles';

import { DockTextBlock } from '../DockTextBlock';
import ChartDonutStyles from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      labels: {
        fontSize: 30,
      },
      position: 'right',
    },
  },
};

const ChartDonut = ({ classes, input }) => {
  const { labels, datasets } = input;
  const data = {
    labels,
    datasets,
  };

  if (data.labels && data.labels.length) {
    return <Doughnut options={options} data={data} className={classes.root} />;
  }

  return <DockTextBlock />;
};

ChartDonut.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default withStyles(ChartDonutStyles)(ChartDonut);
