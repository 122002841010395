import React from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { TextField, InputAdornment, IconButton } from '@mui/material';

const Search = ({
  id,
  isLoading,
  placeholder,
  autoComplete,
  value,
  sx,
  onChange,
  onClear,
}) => {
  return (
    <TextField
      variant="outlined"
      fullWidth
      disabled={isLoading}
      sx={sx}
      InputProps={{
        style: { height: '40px' },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="large" sx={{ margin: '0 -8px 0 12px' }} />
          </InputAdornment>
        ),
        endAdornment:
          onClear && value ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="search clear"
                onClick={onClear}
                edge="end"
                sx={{ marginRight: '6px' }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          ),
      }}
      {...{ id, placeholder, autoComplete, value, onChange }}
    />
  );
};

Search.propTypes = {
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  sx: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

export default Search;
