const AccordionPaperStyles = () => ({
  root: {
    width: '100%',
  },
  content: {
    backgroundColor: 'white',
  },
  custom: {
    display: 'block',
    paddingLeft: '25px',
  },
  summary: {
    marginTop: '12px',
    marginBottom: '12px',
  },
});

export default AccordionPaperStyles;
