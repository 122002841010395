import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { CircularLoading } from '../CircularLoading';
import { PageContent } from '../PageContent';

const LoadingFallback = ({ isBlue = false }) => (
  <>
    <PageContent isBlue={isBlue}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <CircularLoading />
        </Grid>
      </Grid>
    </PageContent>
  </>
);

LoadingFallback.propTypes = {
  isBlue: PropTypes.bool,
};

export default LoadingFallback;
