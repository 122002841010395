import Colors from '../../styles/Colors';

const GridCellTimeStyles = () => ({
  user: {
    fontSize: 13,
    color: Colors.grey3,
  },
});

export default GridCellTimeStyles;
