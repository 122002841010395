import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Grid } from '@mui/material';

const styles = (theme) => ({
  button: {
    margin: theme.spacing(0.5, 0),
  },
});

function PriorityListActionBar({ priorityLists, classes }) {
  function handleDelete() {
    priorityLists.forEach(
      ({ items, selectedItems, onItemsChanged, onSelectedItemsChanged }) => {
        onItemsChanged(items.filter((item) => !selectedItems.includes(item)));
        // Also remove them from the check items
        onSelectedItemsChanged((selectedItems) =>
          selectedItems.filter((si) => !selectedItems.includes(si))
        );
      }
    );
  }

  function handleTransfer(fromListIndex, toListIndex) {
    const fromList = priorityLists[fromListIndex];
    const toList = priorityLists[toListIndex];
    toList.onItemsChanged([...toList.items, ...fromList.selectedItems]);
    toList.onSelectedItemsChanged((selectedItems) =>
      selectedItems.filter((si) => !toList.selectedItems.includes(si))
    );
    toList.onSelectedItemsChanged((selectedItems) => [
      ...selectedItems,
      ...fromList.selectedItems,
    ]);
    fromList.onItemsChanged(
      fromList.items.filter((item) => !fromList.selectedItems.includes(item))
    );
    fromList.onSelectedItemsChanged((selectedItems) =>
      selectedItems.filter((si) => !fromList.selectedItems.includes(si))
    );
  }

  function handleShift(steps) {
    priorityLists.forEach(({ items, selectedItems, onItemsChanged }) => {
      let newList = [...items];
      // Move it order so that it caterpillars up/down for multiple
      const sortDirection =
        steps < 0
          ? (a, b) => items.indexOf(a) - items.indexOf(b)
          : (a, b) => items.indexOf(b) - items.indexOf(a);
      selectedItems.sort(sortDirection).forEach((item) => {
        newList = moveItem(newList, item, steps);
      });
      onItemsChanged(newList);
    });
  }

  function moveItem(list, item, steps) {
    // Note: Function mutates list
    const pos = list.indexOf(item);
    if (pos < 0) {
      return list;
    }
    let newPos = pos + steps;
    // Stay in list bounds
    newPos = Math.min(Math.max(newPos, 0), list.length - 1);
    // Remove the item
    list.splice(pos, 1);
    // Insert it into its new position
    list.splice(newPos, 0, item);
    return list;
  }

  const commonButtonProps = {
    variant: 'outlined',
    size: 'small',
    className: `${classes.button}`,
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Button
        {...commonButtonProps}
        onClick={() => handleShift(-1)}
        disabled={priorityLists.every(({ selectedItems }) => selectedItems.length === 0)}
        aria-label="move selected items up"
      >
        <ExpandLessIcon />
      </Button>
      {priorityLists.length > 1 && (
        <>
          <Button
            {...commonButtonProps}
            onClick={() => handleTransfer(0, 1)}
            disabled={priorityLists[0].selectedItems.length === 0}
            aria-label="move selected items right"
          >
            &gt;
          </Button>
          <Button
            {...commonButtonProps}
            onClick={() => handleTransfer(1, 0)}
            disabled={priorityLists[1].selectedItems.length === 0}
            aria-label="move selected items left"
          >
            &lt;
          </Button>
        </>
      )}
      <Button
        {...commonButtonProps}
        onClick={() => handleShift(1)}
        disabled={priorityLists.every(({ selectedItems }) => selectedItems.length === 0)}
        aria-label="move selected items down"
      >
        <ExpandMoreIcon />
      </Button>
      <Button
        {...commonButtonProps}
        onClick={() => handleDelete()}
        disabled={priorityLists.every(({ selectedItems }) => selectedItems.length === 0)}
        aria-label="delete selected items"
      >
        Delete Selected
      </Button>
    </Grid>
  );
}

PriorityListActionBar.defaultProps = {
  priorityLists: [],
};

PriorityListActionBar.propTypes = {
  priorityLists: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.array,
      selectedItems: PropTypes.array,
      onItemsChanged: PropTypes.func,
      onSelectedItemsChanged: PropTypes.func,
    })
  ),
};

export default withStyles(styles)(PriorityListActionBar);
