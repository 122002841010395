import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Typography } from '@mui/material';

import Styles from '../../styles/PlaybookBuilderStyles';
import ToggleableLinkButton from './ToggleableLinkButton';

const ButtonLinkTile = ({
  classes,
  link = '',
  disabled = false,
  title,
  subtitle = '',
  icon = null,
  handleClick = (f) => f,
  text = 'Start',
}) => (
  <div className="col-lg-3 col-md-6 mb-5" style={{ overflow: 'hidden' }}>
    <div className={classes.buttonBlock} style={{ overflow: 'hidden' }}>
      <div className={classes.startBuildingIconWrapper}>{icon}</div>
      <Typography variant="h3" className="pt-3">
        {title}
      </Typography>
      <Typography variant="body1" className="pt-3 pb-3">
        {subtitle}
      </Typography>
      <div className={classes.startBtnWrapper}>
        <ToggleableLinkButton link={link} disabled={disabled} handleClick={handleClick}>
          {text}
        </ToggleableLinkButton>
      </div>
    </div>
  </div>
);

ButtonLinkTile.propTypes = {
  classes: PropTypes.object.isRequired,
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  handleClick: PropTypes.func,
  text: PropTypes.string,
};

export default withStyles(Styles)(ButtonLinkTile);
