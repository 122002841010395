import { SET_SUCCESS_MSG, SET_ERR_MSG } from '../action-types/snackbarTypes';

const initialState = {
  successMsg: null,
  errMsg: null,
};

function snackbarReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SUCCESS_MSG:
      return {
        ...state,
        successMsg: action.payload,
      };
    case SET_ERR_MSG:
      return {
        ...state,
        errMsg: action.payload,
      };
    default:
      return state;
  }
}

export default snackbarReducer;
