import React, { Suspense, useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import axios from 'axios';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';

import AuthUpdateProvider from './auth/AuthUpdateProvider';
import { getRedirect } from './auth/AuthUtils';
import AuthProvider from './AuthProvider';
import { API_CONFIG_ENDPOINT_NA } from './endpoints.config';
import GlobalEventManager from './GlobalEventManager';
import store from './redux/store';
import Routing from './Routing';
import UserFlowManager from './UserFlowManager';
import VisibilityManager from './VisibilityManager';
import { ErrorFallback, LoadingFallback } from './components';

// This sets the document domain to blackboiler.com or localhost to support only office
const hostnameParts = window.location.hostname.split('.');
if (hostnameParts.length > 1) {
  // looks like ['bb', 'blackboiler', 'com'] on bb.blackboiler.com, but ['localhost'] on localhost
  document.domain = [
    hostnameParts[hostnameParts.length - 2],
    hostnameParts[hostnameParts.length - 1],
  ].join('.');
} else {
  document.domain = [
    // 0th element
    hostnameParts[hostnameParts.length - 1],
  ].join('.');
}

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [logoUrl, setLogoUrl] = useState('');
  const [authProvider, setAuthProvider] = useState('');
  const [returnTo, setReturnTo] = useState(null);
  const [configNA, setConfigNA] = useState(null);

  const getConfig = () => {
    axios
      .get(API_CONFIG_ENDPOINT_NA)
      .then(({ data }) => {
        const { provider, companyLogo } = data;
        setLogoUrl(companyLogo);
        setAuthProvider(provider);
        setIsLoading(false);
        setConfigNA(data);
      })
      .catch((e) => {
        // Replace with logger and setError
        setIsLoading(false);
        console.error(e);
      });
  };

  const onRedirectCallback = ({ targetUrl }) => {
    setReturnTo(targetUrl);
  };

  const login = (auth0LoginWithRedirect = null, useDirectIdpLink = false) => {
    switch (authProvider) {
      case 'aws':
        if (useDirectIdpLink) {
          Auth.federatedSignIn({ customProvider: configNA.directIdpName });
        } else {
          Auth.federatedSignIn();
        }
        break;
      case 'auth0':
        // FIXME: handle integration code reporting via api route
        // eslint-disable-next-line no-case-declarations
        const { path: targetUrl } = getRedirect(window.location.search);
        auth0LoginWithRedirect({ appState: { targetUrl } }).catch((e) =>
          console.error(e)
        );
        break;
      default:
        console.error(
          'attempting to log in with unknown auth authProvider:',
          authProvider
        );
    }
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    !isLoading && (
      <Provider store={store}>
        <AuthProvider
          onRedirectCallback={onRedirectCallback}
          login={login}
          configNA={configNA}
        >
          <AuthUpdateProvider
            provider={authProvider}
            login={login}
            logoUrl={logoUrl}
            configNA={configNA}
          >
            <VisibilityManager>
              <GlobalEventManager>
                <UserFlowManager>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<LoadingFallback />}>
                      <Routing returnTo={returnTo} />
                    </Suspense>
                  </ErrorBoundary>
                </UserFlowManager>
              </GlobalEventManager>
            </VisibilityManager>
          </AuthUpdateProvider>
        </AuthProvider>
      </Provider>
    )
  );
};

export default App;
