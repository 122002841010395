import React from 'react';
import StepIcon from '@mui/material/StepIcon';
import { Box } from '@mui/material';

function StepperDots({ items, selectedItem, onItemChange, keyFunc, sx }) {
  const hoverStyle = {
    ':hover': {
      cursor: 'pointer',
    },
  };

  const dotsFix = {
    // due to parent component svg important on OnboardingAnswerQuestions
    '.MuiSvgIcon-root': { fontSize: '8px !important' },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
        ...dotsFix,
      }}
    >
      {items.map((item) => (
        <StepIcon
          sx={{ marginX: '2px', fontSize: '8px !important', ...hoverStyle }}
          key={keyFunc(item)}
          icon=""
          active={selectedItem == item}
          onClick={() => onItemChange(item)}
        />
      ))}
    </Box>
  );
}

export default StepperDots;
