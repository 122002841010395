import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { TextField, Typography, Grid, Button, Autocomplete } from '@mui/material';

import FastTrackStyles from '../../../../styles/FastTrackStyles';

const AutocompleteFormik = ({
  formikValues,
  formikErrors,
  formikTouched,
  formikSetFieldValue,
  title,
  name,
  clarifyingText = null,
  classes,
  xs = 12,
  onChangeOverride,
  actions = [],
  ...rest
}) => {
  return (
    <Grid container item spacing={1} direction="column" xs={xs}>
      <Grid item className={classes.zeroPaddingBottom}>
        <Typography className={classes.floatLeft}>{title}</Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid container spacing={2}>
            <Grid item className={`${classes.zeroPaddingBottom} ${classes.flexible}`}>
              <Autocomplete
                id={`${name}_${new Date().toString()}`}
                onChange={(e, v) =>
                  onChangeOverride ? onChangeOverride(e, v) : formikSetFieldValue(name, v)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={formikValues[name]}
                  />
                )}
                variant="outlined"
                className={classes.formikAutocomplete}
                {...rest}
              />
            </Grid>
            {!!actions.length &&
              actions.map(({ title, action, disabled }) => (
                <Grid item key={title}>
                  <Button
                    disabled={disabled}
                    className={classes.inputActionButton}
                    onClick={() => action(formikValues[name], name)}
                  >
                    {title}
                  </Button>
                </Grid>
              ))}
          </Grid>
          <Grid item className={classes.inputFormikRequiredText}>
            {formikTouched[name] && formikErrors[name] ? (
              <div className={classes.yupErrorMsg}>
                {title.replace('*', '')} is required.
              </div>
            ) : null}
          </Grid>
          {clarifyingText ? (
            <Grid item>
              <div className={classes.clarifyingText}>{clarifyingText}</div>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

AutocompleteFormik.propTypes = {
  classes: PropTypes.object.isRequired,
  formikValues: PropTypes.object.isRequired,
  formikErrors: PropTypes.object.isRequired,
  formikTouched: PropTypes.object.isRequired,
  formikSetFieldValue: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  clarifyingText: PropTypes.string,
  multiline: PropTypes.bool,
  xs: PropTypes.number,
  onChangeOverride: PropTypes.func,
};

export default withStyles(FastTrackStyles)(AutocompleteFormik);
