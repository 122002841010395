import Colors from '../../styles/Colors';

const TextDiffStyles = () => ({
  added: {
    color: Colors.textDiffGreen,
  },
  removed: {
    textDecoration: 'line-through',
    color: Colors.textDiffRed,
  },
});

export default TextDiffStyles;
