import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material';

import formikErrorTextStyles from './styles';

const FormikErrorText = ({
  id,
  touched,
  errors,
  classes,
  justifyContent,
  alignItems,
  ignoreTouched,
  localError,
}) =>
  ((ignoreTouched ? errors?.[id] : touched?.[id] && errors?.[id]) || localError) && (
    <Grid item>
      <Grid container justifyContent={justifyContent} alignItems={alignItems}>
        {(ignoreTouched ? errors?.[id] : touched?.[id] && errors?.[id]) && (
          <Grid item xs={10}>
            <Typography className={classes.text}>
              {(() => {
                if (Array.isArray(errors[id])) {
                  // If the message has "null", not readable for a user
                  return errors[id].some((e) => e.includes('null'))
                    ? 'Required field.'
                    : errors[id];
                }
                return errors[id].includes('null') ? 'Required field.' : errors[id];
              })()}
            </Typography>
          </Grid>
        )}
        {!!localError && (
          <Grid item xs={10}>
            <Typography className={classes.text}>{localError}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

FormikErrorText.defaultProps = {
  justifyContent: 'flex-end',
  alignItems: 'center',
  ignoreTouched: false,
};

FormikErrorText.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  ignoreTouched: PropTypes.bool,
};

export default withStyles(formikErrorTextStyles)(FormikErrorText);
