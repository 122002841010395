import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid } from '@mui/material';

import { USERS_ROUTE } from '../../routes.config';

const AppBarUser = () => (
  <>
    <Grid container spacing={2} alignItems="center" sm={6} md={9}>
      <Grid item>
        <Button
          variant="outlined"
          component={RouterLink}
          to={USERS_ROUTE}
          startIcon={<ArrowBackIcon />}
        >
          Back to Users
        </Button>
      </Grid>
    </Grid>

    <Grid
      container
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
      sm={6}
      md={4}
    >
      <Grid item>
        <Button variant="outlined" component={RouterLink} to={USERS_ROUTE}>
          Discard
        </Button>
      </Grid>
    </Grid>
  </>
);

export default AppBarUser;
