// MODEL DEV
import axios from 'axios';

import { MODELS_ENDPOINT, MODELS_FILE_ENDPOINT } from '../../endpoints.config';
import { SET_CURRENT_MODEL, SET_CURRENT_MODEL_DATA } from '../action-types/modelTypes';
import { SET_IMPORTING_RULES } from '../action-types/playbookBuilderTypes';

export const SetCurrentModel = (modelId) => ({
  type: SET_CURRENT_MODEL,
  payload: modelId,
});
export const CreateModelFromInputs = (headers, data) =>
  async function (dispatch) {
    let {
      name,
      isDeleteProtected,
      stage,
      contractType,
      defaultQuestionnaire,
      groups,
      enableBullseyeByEmail,
      generateAddendum,
      addendumTemplate,
      emailOnUpload = false,
      convertPdfToDocx = false,
      onboarding = false,
      bullseyeTrainingModels = [],
      nano_slot_model = {},
      enableRiskRouting = false,
      enableNewFsdMethod = false,
    } = data;

    const response = await axios.post(
      MODELS_ENDPOINT,
      {
        name,
        isDeleteProtected,
        stage,
        contractType,
        defaultQuestionnaire,
        groups,
        enableBullseyeByEmail,
        generateAddendum,
        addendumTemplate,
        onboarding,
        bullseyeTrainingModels,
        emailOnUpload,
        convertPdfToDocx,
        nano_slot_model,
        enableRiskRouting,
        enableNewFsdMethod,
      },
      {
        headers,
      }
    );

    if (!onboarding) {
      dispatch(SetCurrentModel(response.data.modelId));
      return response.data.modelId;
    }
    return response.data;
  };

export const CreateModelFromFile = (headers, file, data) =>
  async function (dispatch) {
    let {
      title,
      isDeleteProtected,
      stage,
      defaultQuestionnaire,
      groups,
      enableBullseyeByEmail,
      bullseyeTrainingModels,
      emailOnUpload,
      convertPdfToDocx,
      nano_slot_model,
      enableRiskRouting,
      enableNewFsdMethod,
    } = data;
    const fd = new FormData();
    fd.append('files', file);
    fd.append('name', title);
    fd.append('isDeleteProtected', isDeleteProtected);
    fd.append('stage', stage);
    fd.append('defaultQuestionnaire', defaultQuestionnaire);
    fd.append('groups', groups);
    fd.append('enableBullseyeByEmail', enableBullseyeByEmail);
    fd.append('bullseyeTrainingModels', bullseyeTrainingModels);
    fd.append('emailOnUpload', emailOnUpload);
    fd.append('nano_slot_model', nano_slot_model);
    fd.append('convertPdfToDocx', convertPdfToDocx);
    fd.append('enableRiskRouting', enableRiskRouting);
    fd.append('enableNewFsdMethod', enableNewFsdMethod);
    const response = await axios.post(MODELS_FILE_ENDPOINT, fd, {
      headers: { ...headers, 'Content-Type': 'multipart/form-data' },
    });
    if (response.status !== 200) {
      throw Error('Failed to create model');
    }
    dispatch(SetCurrentModel(response.data.modelId));
    return response.data.modelId;
  };

export const SetCurrentModelData = (data) => ({
  type: SET_CURRENT_MODEL_DATA,
  payload: data,
});

export const SetImportingRules = (importRuleCount) => ({
  type: SET_IMPORTING_RULES,
  payload: importRuleCount,
});

export const ClearImportingRules = () => ({
  type: SET_IMPORTING_RULES,
  payload: 0,
});
