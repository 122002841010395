import {
  SET_USER_FLOW_IS_ENABLED,
  SET_USER_FLOW_TOKEN,
  SET_USER_HASH,
} from '../action-types/userflowTypes';

const initialState = {
  userHash: null,
  token: null,
  isEnabled: false,
};

function userFlowReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_HASH:
      return {
        ...state,
        userHash: action.payload,
      };
    case SET_USER_FLOW_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_USER_FLOW_IS_ENABLED:
      return {
        ...state,
        isEnabled: action.payload,
      };
    default:
      return state;
  }
}

export default userFlowReducer;
