export const SET_FXI_DATA = 'setFxiData';
export const SET_TEXT_DATA = 'setTextData';
export const SET_FXI_DEFINED_TERMS_RAW = 'setFxiDefinedTermsRaw';
export const SET_FXI_PRONOUN_RAW = 'setFxiPronounRaw';
export const SET_FXI_DEFINED_TERMS_TEMPLATED = 'setFxiDefinedTermsVersion';
export const SET_FXI_PRONOUN_TEMPLATED = 'setFxiPronounVersion';
export const SET_FXI_SECTION = 'setFxiSection';
export const SET_FXI_TRAIN_TYPE = 'setFxiTrainType';
export const SET_AVAILABLE_SECTIONS = 'setAvailableSections';
export const CLEAR_PLAYBOOK_BUILDER_REDUX_STORE = 'clearPlaybookBuilderReduxStore';
export const SET_IMPORTING_RULES = 'setImportingRules';
