export function formatData(item, userEmail, custom_fields = []) {
  const formattedCustomFields = custom_fields.reduce(
    (acc, { _id, name: fieldName, isRequired }) => {
      acc[_id] = {
        _id,
        fieldName,
        isRequired,
        value: '',
      };
      return acc;
    },
    {}
  );
  const { file } = item;
  const { name: fileName, counterparty, model } = file;
  return {
    model,
    counterparty,
    name: fileName,
    email: userEmail,
    uploadedFile: file,
    contractName: '',
    contractModel: '',
    emailResults: false,
    emailList: [],
    customFields: formattedCustomFields,
    showAdvanced: false, // only needed by the ui during creation
    advancedMode: null, // this may need to be prepoppulated if Model has default value selected
    advancedSettings: null,
    modifiedContractName: false,
  };
}

const validateCustomFields = (customFields) => {
  if (typeof customFields === 'undefined') {
    return false;
  }

  const customFieldsArray = Object.values(customFields);
  return customFieldsArray.reduce(
    (acc, field) => (!field?.isRequired ? acc && true : acc && field?.value.length > 0),
    true
  );
};

const validateCustomFieldsMulti = (customFieldsMulti, companyConfig) => {
  const required = companyConfig
    ?.filter(({ isRequired }) => isRequired)
    .map(({ _id }) => _id);
  const isPresent = customFieldsMulti?.map(({ _id }) => _id);

  if (!required?.length) {
    return true;
  }

  if (typeof customFieldsMulti === 'undefined') {
    return false;
  }

  const isRequiredSet = new Set(required);
  const isPresentSet = new Set(isPresent);
  return (
    required.every((id) => isPresentSet.has(id)) &&
    required.every((id) => isRequiredSet.has(id))
  );
};

export function validate(data, archive, config) {
  const isValid = data.filter(
    ({
      contractName,
      counterparty,
      contractPlaybook,
      modifiedContractName,
      customFields,
      customFieldsMulti,
    }) => {
      const isContractName =
        !modifiedContractName ||
        (typeof contractName !== 'undefined' && contractName.length > 0);
      const isCounterParty =
        (typeof counterparty !== 'undefined' && counterparty.length > 0) || archive;
      const isContractModel =
        typeof contractPlaybook !== 'undefined' && contractPlaybook.length > 0;
      const isCustomFields = validateCustomFields(customFields);
      const isCustomFieldsMulti = validateCustomFieldsMulti(
        customFieldsMulti,
        config?.customFieldsMulti
      );

      return (
        isContractName &&
        isCounterParty &&
        isContractModel &&
        isCustomFields &&
        isCustomFieldsMulti
      );
    }
  );

  return data.length && isValid.length === data.length;
}

export async function checkFileType(file) {
  const buffer = await file.slice(0, 4).arrayBuffer();
  const uint = new Uint8Array(buffer); // uInt8Array
  const hex = uint
    .reduce((acc, byte) => {
      acc.push(byte.toString(16));
      return acc;
    }, [])
    .join('')
    .toUpperCase();
  return {
    validMimetype: getMimetype(hex) !== 'Unknown',
    hex,
  };
}

// Reference links
// https://www.garykessler.net/library/file_sigs.html
// https://en.wikipedia.org/wiki/List_of_file_signatures
export function getMimetype(signature) {
  switch (signature) {
    case '25504446':
      return 'pdf';
    case 'D0CF11E0':
      return 'doc';
    case '504B34':
      return 'docx';
    default:
      return 'Unknown';
  }
}
