import React from 'react';

import { Select, MenuItem } from '@mui/material';

function BBSelect({ label, ...props }) {
  return (
    <Select
      {...props}
      variant="outlined"
      displayEmpty
      // disabled={isLoading}
      // value={textTypes}
      // inputProps={{
      //     'aria-label': 'Select Text Type',
      // }}
      // multiple
      renderValue={(value) => {
        if (!value) {
          return `Select: ${label}`;
        }
        return `${label.split(' ').pop()}: ${value}`;
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      <MenuItem value="" disabled>{`Select: ${label}`}</MenuItem>
      {props.options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

export default BBSelect;
