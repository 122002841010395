import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Checkbox } from '@mui/material';

const GridCellEmailBullseye = ({ data }) => {
  const label = { inputProps: { 'aria-label': 'bullseye-by-email' } };
  return (
    <Grid container spacing={1} alignItems="center" justifyContent="center">
      <Grid item>
        <Checkbox {...label} disabled checked={data} />
      </Grid>
    </Grid>
  );
};

GridCellEmailBullseye.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GridCellEmailBullseye;
