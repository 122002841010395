import { buildInputs } from '../../utils/PoaUtils';

const fileDetails = {
  attachments: 'Attachments',
};

const textDetails = {
  comments: {
    title: 'Comments',
    multiline: true,
    minRows: 4,
    maxRows: 10,
  },
  regionManagerName: 'Regional Corp Sec Attorney Name',
};
const selectDetails = {
  regionManagerEmail: 'Regional Corp Sec Attorney Email',
};
const dateDetails = {
  attorneyApprovalDate: 'Date of Attorney Approval',
  sentForSignatureDate: 'Date Sent to for Signature',
  poaCompleteDate: 'Date PoA Complete',
};
const booleanDetails = {
  regionManagerApproval: 'Approved by Regional Corp Sec Attorney',
  completedBeforeExpiry: 'Completed Before Expiry',
};
const numberDetails = {
  totalParalegalTime: 'Cumulative time spent by Paralegal (in minutes)',
};
// formik initial values
export const dateKeysInitialValues = Object.keys(dateDetails).reduce((acc, key) => {
  acc[key] = null;
  return acc;
}, {});
export const numberKeysInitialValues = Object.keys(numberDetails).reduce((acc, key) => {
  acc[key] = 0;
  return acc;
}, {});

// formik inputs
export const fileInputs = buildInputs(fileDetails);
export const textInputs = buildInputs(textDetails);
export const dateInputs = buildInputs(dateDetails);
export const booleanInputs = buildInputs(booleanDetails);
export const numberInputs = buildInputs(numberDetails);
export const selectInputs = buildInputs(selectDetails);

export const userDetails = {
  user_given_name: 'Requester First Name',
  user_family_name: 'Requester Last Name',
  user_email: 'Requester Email',
};

export const generalDetails = {
  requesterBU: 'Business Unit(s)',
  jurisdictionRegion: 'Jurisdiction Region',
  managerApproval: 'Manager Approval',
  managerApprovalUpload: 'Manager Approval Upload',
  newOrRenewal: 'New or Renewal',
  poaEffectiveDate: 'Target Date to be Sent for Signature',
  poaTerminationDate: 'PoA Termination Date',
  requestDate: 'Date of Request',
  scopeSubType: 'Scope Sub-Type',
  scopeType: 'Scope Type',
  specialReqsOrComments: 'Special Reqs or Comments',
  scopeWording: 'Scope Wording',
};

export const beneficiaryKeys = {
  beneficiaryAddress: 'Address',
  beneficiaryChineseName: 'Chinese Name',
  beneficiaryDateOfBirth: 'Date Of Birth',
  beneficiaryEmail: 'Email',
  beneficiaryGivenName: 'Given Name',
  beneficiaryFamilyName: 'Family Name',
  beneficiaryFullLegalName: 'Full Legal Name',
  beneficiaryNationality: 'Nationality',
  beneficiaryPlaceOfBirth: 'Place Of Birth',
  beneficiaryTETitle: 'TE Title',
  beneficiaryPassportId: 'Passport or ID Number',
  beneficiaryPassportIssuer: 'Passport issuer',
  beneficiaryPassportIssueDate: 'Passport issuance date',
  beneficiaryPassportExpiryDate: 'Passport expiry date',
  beneficiaryPersonalId: 'Personal ID #',
  beneficiaryFatherName: "Father's Name",
};

export const generalEntityDetails = {
  entityActive: 'Active',
  entityActive_1: 'Active.1',
  entityAmountIssued: 'Amount Issued',
  entityCurrencyISOCode: 'Currency ISO Code',
  entityCurrencyISOCode_1: 'Currency ISO Code.1',
  entityEntityType: 'Entity Type',
  entityExternalUser: 'External User',
  entityFullAddress: 'Full Address',
  entityJurisdictionOfFormation: 'Jurisdiction Of Formation',
  entityLongDescription: 'Long Description',
  entityName: 'Name',
  entityRegion: 'Region',
  entityRegisteredHolder: 'Registered Holder',
  entityRegisteredHolderParentInterestHeld: 'Registered Holder Parent Interest Held',
  entityResponsibleParalegal: 'Responsible Paralegal',
  entitySecurity: 'Security',
  entitySecurity_1: 'Security.1',
  entityStateFile: 'State File',
  entityTotalAuthorised: 'Total Authorised',
  entityUserEmail: 'User Email',
  entityValueIssued: 'Value Issued',
  entityValueofIssuedCapital: 'Value of Issued Capital',
};

export const trackingDetails = {
  approvalUser: 'Approved By',
  attorneyApprovalDate: 'Approval Date',
};

export const boolMap = { true: 'Yes', false: 'No' };

export const allPrettyKeys = {
  ...textDetails,
  ...selectDetails,
  ...dateDetails,
  ...booleanDetails,
  ...numberDetails,
  ...userDetails,
  ...generalDetails,
  ...beneficiaryKeys,
  ...generalEntityDetails,
};
