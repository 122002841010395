import React, { useEffect, useState } from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { SetAnswer } from '../../redux/actions/answerQuestionsActions';
import { TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY } from '../../config';
import YesNoStyles from '../../styles/YesNoStyles';
import { TextEditor } from '../TextEditor';
import { EditPreview } from '../EditPreview';

function YesNoTextEditor({ classes }) {
  const dispatch = useDispatch();
  const { currentModelData } = useSelector((state) => state.Model);

  const { currentSection, currentQuestion } = useSelector(
    (state) => state.AnswerQuestions
  );

  const [initialTemplated] = useState(currentQuestion.answer?.templated);
  const [currentRaw, setCurrentRaw] = useState('');
  const [currentTemplated, setCurrentTemplated] = useState('');
  const [currentAnswerValue, setCurrentAnswerValue] = useState();

  function updateAnswer(newAnswer) {
    return dispatch(
      SetAnswer(currentSection._id, currentQuestion._id, {
        ...currentQuestion.answer,
        ...newAnswer,
      })
    );
  }

  useEffect(() => {
    updateAnswer({
      value: currentAnswerValue,
      raw: currentRaw,
      templated: currentTemplated,
    });
  }, [currentRaw, currentTemplated, currentAnswerValue]);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs>
        <RadioGroup
          row
          aria-label="yesNo"
          name="yesNo"
          value={currentQuestion.answer?.value}
          onChange={(evt) => setCurrentAnswerValue(evt.target.value)}
          className={classes.radioGroup}
        >
          <FormControlLabel
            value="YES"
            control={<Radio className={classes.radioButton} />}
            label="Yes"
            className={classes.formControlLabel}
          />
          <FormControlLabel
            value="NO"
            control={<Radio className={classes.radioButton} />}
            label="No"
            className={classes.formControlLabel}
          />
        </RadioGroup>
      </Grid>
      {!!currentQuestion.answer && (
        <Grid item xs>
          <TextEditor
            setTemplated={setCurrentTemplated}
            setRaw={setCurrentRaw}
            slotStrategy={TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY.TRAINING}
            initialValue={initialTemplated}
            contractType={currentModelData?.contractType}
            sx={{ width: '80%' }}
          />
        </Grid>
      )}
      <Grid item xs>
        <EditPreview question={currentQuestion} />
      </Grid>
    </Grid>
  );
}

YesNoTextEditor.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(YesNoStyles)(YesNoTextEditor);
