import { SET_CURRENT_MODEL, SET_CURRENT_MODEL_DATA } from '../action-types/modelTypes';

const initialState = {
  currentModel: null,
  currentModelData: null,
};

function modelReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_MODEL:
      return {
        ...state,
        currentModel: action.payload,
      };
    case SET_CURRENT_MODEL_DATA:
      return {
        ...state,
        currentModelData: action.payload,
      };
    default:
      return state;
  }
}

export default modelReducer;
