import { useEffect, useState } from 'react';

import { useAuthUpdateContext } from './auth/AuthUpdateProvider';
import { METRIC_ACTIONS, METRICS } from './metrics.config';
import { pageVisibilityApi } from './utils/PageVisibilityUtils';
import { WriteMetrics } from './utils/requests';

const { hidden, visibilityChange } = pageVisibilityApi();

const VisibilityManager = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);

  const { user, getAuthHeader } = useAuthUpdateContext();

  const fullUri = encodeURIComponent(window.location.pathname + window.location.search);

  const setVisibility = (flag) => {
    setIsVisible((prevState) => {
      if (prevState === flag) return null;
      return flag;
    });
  };

  const handleVisibilityChange = (forcedFlag) => {
    // this part handles when it's triggered by the focus and blur events
    if (typeof forcedFlag === 'boolean') {
      if (forcedFlag) {
        return setVisibility(true);
      }
      return setVisibility(false);
    }

    // this part handles when it's triggered by the page visibility change events
    if (document[hidden]) {
      return setVisibility(false);
    }
    return setVisibility(true);
  };

  const forceVisibilityTrue = () => {
    handleVisibilityChange(true);
  };

  const forceVisibilityFalse = () => {
    handleVisibilityChange(false);
  };

  useEffect(() => {
    document.addEventListener(visibilityChange, handleVisibilityChange, false);

    document.addEventListener('focus', forceVisibilityTrue, false);
    document.addEventListener('blur', forceVisibilityFalse, false);

    window.addEventListener('focus', forceVisibilityTrue, false);
    window.addEventListener('blur', forceVisibilityFalse, false);

    return () => {
      // clean up function to remove event listeners
      document.removeEventListener(visibilityChange, handleVisibilityChange, false);

      document.removeEventListener('focus', forceVisibilityTrue, false);
      document.removeEventListener('blur', forceVisibilityFalse, false);

      window.removeEventListener('focus', forceVisibilityTrue, false);
      window.removeEventListener('blur', forceVisibilityFalse, false);
    };
  }, []);

  useEffect(() => {
    // Debounce activity tracking
    const activityTimer = setTimeout(() => {
      if (user) {
        if (isVisible) {
          getAuthHeader().then((headers) => {
            WriteMetrics(headers, {
              metric: METRICS.USER,
              action: METRIC_ACTIONS.ACTIVITY_SET,
              path: fullUri,
              timestamp: new Date(),
              userId: user,
            }).catch((err) =>
              console.log(
                `Failed to write metric ${METRICS.USER} ${METRIC_ACTIONS.ACTIVITY_SET} with error:`,
                err
              )
            );
          });
        } else {
          getAuthHeader().then((headers) => {
            WriteMetrics(headers, {
              metric: METRICS.USER,
              action: METRIC_ACTIONS.ACTIVITY_LOST,
              path: fullUri,
              timestamp: new Date(),
              userId: user,
            }).catch((err) =>
              console.log(
                `Failed to write metric ${METRICS.USER} ${METRIC_ACTIONS.ACTIVITY_LOST} with error:`,
                err
              )
            );
          });
        }
      }
    }, 500);
    return () => {
      clearTimeout(activityTimer);
    };
  }, [isVisible]);

  return children;
};

export default VisibilityManager;
