import Colors from '../../styles/Colors';

const PlaybookBuilderPageStyles = () => ({
  footer: {
    width: '100%',
    bottom: 0,
    left: 0,
    paddingBottom: 15,
    paddingTop: 15,
    borderTop: `1px solid ${Colors.grey4}`,
    backgroundColor: 'white',
  },
});

export default PlaybookBuilderPageStyles;
