import {
  CLEAR_PLAYBOOK_BUILDER_REDUX_STORE,
  SET_AVAILABLE_SECTIONS,
  SET_FXI_DATA,
  SET_FXI_DEFINED_TERMS_RAW,
  SET_FXI_DEFINED_TERMS_TEMPLATED,
  SET_FXI_PRONOUN_RAW,
  SET_FXI_PRONOUN_TEMPLATED,
  SET_FXI_SECTION,
  SET_FXI_TRAIN_TYPE,
  SET_TEXT_DATA,
} from '../action-types/playbookBuilderTypes';

export const SetFxiData = (fxiData) => ({
  type: SET_FXI_DATA,
  payload: fxiData,
});

export const SetTextData = (trainingData) => ({
  type: SET_TEXT_DATA,
  payload: trainingData,
});

export const ClearPlaybookBuilderReduxStore = () => ({
  type: CLEAR_PLAYBOOK_BUILDER_REDUX_STORE,
  payload: true,
});

export const SetAvailableSections = (sections) => ({
  type: SET_AVAILABLE_SECTIONS,
  payload: sections,
});

export const SetFxiDefinedTermsRaw = (definedTermsText) => ({
  type: SET_FXI_DEFINED_TERMS_RAW,
  payload: definedTermsText,
});

export const SetFxiPronounRaw = (pronounText) => ({
  type: SET_FXI_PRONOUN_RAW,
  payload: pronounText,
});

export const SetFxiDefinedTermsVersion = (definedTermsText) => ({
  type: SET_FXI_DEFINED_TERMS_TEMPLATED,
  payload: definedTermsText,
});

export const SetFxiPronounVersion = (pronounText) => ({
  type: SET_FXI_PRONOUN_TEMPLATED,
  payload: pronounText,
});

export const SetFxiSection = (section) => ({
  type: SET_FXI_SECTION,
  payload: section,
});

export const SetFxiTrainType = (trainType) => ({
  type: SET_FXI_TRAIN_TYPE,
  payload: trainType,
});
