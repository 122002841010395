import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';

import ModifyListFieldsStyles from './styles';

const ModifyListFields = ({
  classes,
  listData,
  label,
  fieldName,
  updateFieldData,
  addUpdatedFieldName = (f) => f,
}) => {
  // the value will add in fields
  const [value, setValue] = useState('');

  const [fieldList, setFieldList] = useState(listData);

  useEffect(() => {
    updateFieldData(fieldList, fieldName);
  }, [fieldList, setFieldList]);

  // update the value
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleUpdateList = () => {
    // check if the name already in values
    if (value && !fieldList.includes(value)) {
      setFieldList((data) => [...data, value]);
      setValue('');
    }

    addUpdatedFieldName(fieldName);
  };

  const removeValueFromList = (name) => {
    const updatedData = fieldList.filter((valueName) => valueName !== name);
    setFieldList([...updatedData]);
    addUpdatedFieldName(fieldName);
  };

  return (
    <>
      {fieldList
        ? fieldList.map((name) => (
            <List key={name}>
              <ListItem>
                <ListItemText primary={name} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => removeValueFromList(name)} size="large">
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          ))
        : null}

      <TextField
        className={classes.textField}
        label={label}
        value={value}
        onChange={handleChange}
        variant="outlined"
      />

      <Button onClick={handleUpdateList} variant="contained" size="small" color="primary">
        Add
      </Button>
    </>
  );
};

ModifyListFields.propTypes = {
  classes: PropTypes.object.isRequired,
  listData: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  updateFieldData: PropTypes.func.isRequired,
  addUpdatedFieldName: PropTypes.func,
};

export default withStyles(ModifyListFieldsStyles)(ModifyListFields);
