import Colors from '../../styles/Colors';

const GridCellLinkStyles = () => ({
  text: {
    fontSize: 14,
    fontWeight: 600,
  },
  link: {
    color: Colors.blue2,
  },
});

export default GridCellLinkStyles;
