import { lazy } from 'react';

import { useAuthUpdateContext } from '../auth/AuthUpdateProvider';

const isReloadKey = 'bb-force-refreshed';

export const pageHasAlreadyBeenForceRefreshed = () =>
  JSON.parse(window.sessionStorage.getItem(isReloadKey) || 'false');

export const setForceReload = (isReload) => {
  const isReloadValue = typeof isReload === 'boolean' ? isReload.toString() : isReload;

  window.sessionStorage.setItem(isReloadKey, isReloadValue);

  if (isReload) {
    const { sendEventMetric } = useAuthUpdateContext();

    sendEventMetric(
      'FORCED_RELOAD',
      'lazyWithRetry made a forced reload after a chunk was not retrieved.'
    );

    // window.location.reload();
  }
};

// Adapted from https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    try {
      const component = await componentImport();
      setForceReload(false);

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed()) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        setForceReload(true);
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
