import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import { Flex } from '../../components';

const BaseDialogPageHeader = ({
  title,
  extendedTitle,
  description,
  currentStep = null,
  totalSteps = null,
}) => (
  <Flex.V sx={{ marginBottom: '32px' }}>
    {currentStep && totalSteps && (
      <Typography variant="body1">
        step {currentStep} of {totalSteps}
      </Typography>
    )}

    <Typography variant="h1">{title}</Typography>

    <Typography variant="h1" style={{ fontWeight: 'normal' }}>
      {extendedTitle}
    </Typography>

    <Typography variant="body2">{description}</Typography>
  </Flex.V>
);

BaseDialogPageHeader.propTypes = {
  title: PropTypes.string,
  extendedTitle: PropTypes.string,
  description: PropTypes.string,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
};

export default BaseDialogPageHeader;
