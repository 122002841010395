import Colors from '../../styles/Colors';

const FillInTheBlankStyles = () => ({
  label: {
    color: Colors.black,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  textField: {
    width: '50%',
  },
});

export default FillInTheBlankStyles;
