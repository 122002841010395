import React from 'react';
import PropTypes from 'prop-types';

import BeenhereIcon from '@mui/icons-material/Beenhere';
import { Typography } from '@mui/material';

import Colors from '../../styles/Colors';

// note: the className={classes.<class>} structure fails to apply here because it gets overwritten by the root MUI theme for typography styling so inline styling is used instead
const FoundByBlackBoilerText = ({ score = 0, wasEdited = false, isSrc = false }) =>
  isSrc ? (
    <Typography variant="body1" style={{ color: Colors.blue }}>
      <BeenhereIcon color="primary" /> Source text for {wasEdited ? 'edit' : 'find'}{' '}
      similar
    </Typography>
  ) : (
    <Typography variant="body1" style={{ color: Colors.green }}>
      {`${wasEdited ? 'Edited' : 'Found'} by BlackBoiler with ${(score * 100).toFixed(2)}% similarity:`}
    </Typography>
  );

FoundByBlackBoilerText.propTypes = {
  score: PropTypes.number,
  wasEdited: PropTypes.bool,
  isSrc: PropTypes.bool,
};

export default FoundByBlackBoilerText;
