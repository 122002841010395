import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import ClearIcon from '@mui/icons-material/Clear';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { SetAvailableSections } from '../../redux/actions/playbookBuilderActions';
import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';
import { GetTrainingModelSections } from '../../utils/requests';

const SectionTextSearch = ({ classes, onSearchClick, onSectionClick }) => {
  const { getAuthHeader } = useAuthUpdateContext();
  const dispatch = useDispatch();
  const availableSections = useSelector(
    (state) => state.PlaybookBuilder.availableSections,
    shallowEqual
  );

  const [search, setSearch] = useState('');
  const [searchSection, setSearchSection] = useState('');

  const handleMouseDownClearSearch = (event) => {
    event.preventDefault();
  };

  const { id } = useParams();

  useEffect(() => {
    if (!(availableSections.length > 0)) {
      getAuthHeader().then((headers) => {
        GetTrainingModelSections(headers, id).then((ruleSections) => {
          dispatch(SetAvailableSections(ruleSections));
        });
      });
    }
  }, []);

  /* eslint-disable no-underscore-dangle */
  return (
    <>
      <div className="row pt-5 d-flex align-items-end">
        <div className="col">
          <InputLabel id="search-label">Type a keyword or sentence</InputLabel>
          <TextField
            id="text"
            variant="outlined"
            fullWidth
            autoComplete="off"
            value={search}
            onChange={(evt) => setSearch(evt.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {search && (
                    <IconButton
                      aria-label="clear search"
                      onClick={() => setSearch('')}
                      onMouseDown={handleMouseDownClearSearch}
                      edge="end"
                      size="large"
                    >
                      <ClearIcon className={classes.inputIcon} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              inputProps: {
                'aria-labelledby': 'search-label',
              },
            }}
          />
        </div>
        <div className="col-3">
          <Select
            variant="outlined"
            displayEmpty
            value={searchSection}
            inputProps={{
              'aria-label': 'Select: Training Attribute',
            }}
            defaultValue=""
            onChange={(evt) => setSearchSection(evt.target.value)}
          >
            {[{ _id: '', name: 'None' }, ...availableSections].map(
              ({ _id: sId, name: sName }) => (
                <MenuItem key={sId} value={sId}>
                  {sName}
                </MenuItem>
              )
            )}
          </Select>
        </div>
        <div className="col-auto">
          <Button
            color="primary"
            variant="contained"
            onClick={() => onSearchClick(search, searchSection)}
            disabled={!search}
          >
            Search
          </Button>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col">
          <InputLabel id="search-label">Browse sentences by common sections</InputLabel>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg">
          {availableSections.slice(0, availableSections.length / 2).map((item) => (
            <button
              type="button"
              className={`row no-gutters ${classes.listButton}`}
              key={item._id}
              onClick={() => onSectionClick(item._id)}
            >
              <div className="col text-left pl-0">{item.name}</div>
              <div className="col-auto">
                <NavigateNextIcon fontSize="large" />
              </div>
            </button>
          ))}
        </div>
        <div className="col-lg">
          {availableSections.slice(availableSections.length / 2).map((item) => (
            <button
              type="button"
              className={`row no-gutters ${classes.listButton}`}
              key={item._id}
              onClick={() => onSectionClick(item._id)}
            >
              <div className="col text-left pl-0">{item.name}</div>
              <div className="col-auto">
                <NavigateNextIcon fontSize="large" />
              </div>
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

SectionTextSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onSectionClick: PropTypes.func.isRequired,
};

export default withStyles(PlaybookBuilderStyles)(SectionTextSearch);
