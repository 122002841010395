import axios from 'axios';

import {
  TENANT_MANAGEMENT_CHANGE_ENDPOINT,
  TENANT_MANAGEMENT_CURRENT_ENDPOINT,
  TENANT_MANAGEMENT_ENDPOINT,
  TENANT_MANAGEMENT_LIST_ENDPOINT,
} from '../endpoints.config';

export async function GetCurrentTenant(headers) {
  const response = await axios
    .get(`${TENANT_MANAGEMENT_CURRENT_ENDPOINT}`, { headers })
    .catch((err) => {
      if (err.response && err.response.status === 503) {
        return err.response;
      }
      console.log('Error! Failed to retrieve current tenant with error:', err);
      throw err;
    });
  if (response.status === 503) {
    // unavailable
    return null;
  }
  if (response.status !== 200 || !response.data) {
    throw Error('No tenant found');
  }
  return response.data;
}

export async function GetTenants(headers) {
  const response = await axios
    .get(`${TENANT_MANAGEMENT_LIST_ENDPOINT}`, { headers })
    .catch((err) => {
      if (err.response && err.response.status === 503) {
        return err.response;
      }
      console.log('Error! Failed to retrieve tenants with error:', err);
      throw err;
    });
  if (response.status === 503) {
    // unavailable
    return [];
  }
  if (response.status !== 200 || !response.data) {
    throw Error('No tenants found');
  }
  return response.data;
}

export async function ChangeTenant(headers, id, socketId) {
  const response = await axios
    .put(`${TENANT_MANAGEMENT_CHANGE_ENDPOINT(id)}`, { socketId }, { headers })
    .catch((err) => {
      console.log('Error! Failed to change tenant with error:', err);
      throw err;
    });
  if (response.status === 503) {
    // unavailable
    throw Error('Tenant switching unavailable');
  }
  if (response.status !== 200) {
    throw Error('Failed to change tenant');
  }
}

export async function CreateTenant(headers, data) {
  return axios
    .post(TENANT_MANAGEMENT_ENDPOINT, data, { headers })
    .then((resp) => resp)
    .catch((err) => {
      throw Error(err);
    });
}
