import React from 'react';
import PropTypes from 'prop-types';

import { Snackbar, Alert } from '@mui/material';

import nullableProp from '../../utils/nullableProp';

const SnackbarCustom = ({ severity = 'warning', msg = null, setMsg, duration = 5 }) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    autoHideDuration={duration * 1000}
    onClose={() => setMsg(null)}
    open={!!msg}
  >
    <Alert onClose={() => setMsg(null)} severity={severity}>
      {msg}
    </Alert>
  </Snackbar>
);

SnackbarCustom.propTypes = {
  severity: PropTypes.string,
  msg: nullableProp(PropTypes.string.isRequired),
  setMsg: PropTypes.func.isRequired,
  duration: PropTypes.number,
};

export default SnackbarCustom;
