import React from 'react';

const slotStyles = {
  nda: {
    receiving_party: { backgroundColor: 'rgba(26, 111, 135, 0.3)', padding: '0 .3em' },
    disclosing_party: { backgroundColor: 'rgba(135, 32, 66, 0.3)', padding: '0 .3em' },
    confidential_information: {
      backgroundColor: 'rgba(19, 135, 96, 0.3)',
      padding: '0 .3em',
    },
    agreement: { backgroundColor: 'rgba(135, 69, 5, 0.3)', padding: '0 .3em' },
    deal: { backgroundColor: 'rgba(26, 111, 135, 0.1)', padding: '0 .3em' },
  },
  subcontract: {
    subcontractor: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
    contractor: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
    agreement: { backgroundColor: 'rgba(135, 69, 5, 0.3)', padding: '0 .3em' },
    work: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
    project: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
    owner: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
  },
  servicesagreement: {
    service_provider: { backgroundColor: 'rgba(12, 135, 25, 0.3)', padding: '0 .3em' },
    service_receiver: { backgroundColor: 'rgba(255, 0, 0, 0.5)', padding: '0 .3em' },
    services: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
    agreement: { backgroundColor: 'rgba(135, 69, 5, 0.3)', padding: '0 .3em' },
  },
  // default_slot_key_renames is copied from: https://github.com/BlackBoiler/nlp_mini_tools/blob/master/nlp_mini_tools/cerberus/__init__.py#L27
  default: {
    receiving_party: { backgroundColor: 'rgba(26, 111, 135, 0.3)', padding: '0 .3em' },
    disclosing_party: { backgroundColor: 'rgba(135, 32, 66, 0.3)', padding: '0 .3em' },
    confidential_information: {
      backgroundColor: 'rgba(19, 135, 96, 0.3)',
      padding: '0 .3em',
    },
    agreement: { backgroundColor: 'rgba(135, 69, 5, 0.3)', padding: '0 .3em' },
    deal: { backgroundColor: 'rgba(26, 111, 135, 0.1)', padding: '0 .3em' },
    service_provider: { backgroundColor: 'rgba(12, 135, 25, 0.3)', padding: '0 .3em' },
    service_receiver: { backgroundColor: 'rgba(255, 0, 0, 0.5)', padding: '0 .3em' },
    services: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
    subcontractor: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
    contractor: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
    general_project: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
    subcontract_work: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
    owner: { backgroundColor: 'rgba(255, 0, 0, 1.0)', padding: '0 .3em' },
  },
};

export const getSlotStyleByContractType = (contractType = 'default') =>
  slotStyles[contractType] ? slotStyles[contractType] : {};

export const getDecoratedStyle = (key, contractType = 'default') =>
  getSlotStyleByContractType(contractType)[key];

export const slotsEntityMap = (slotsMap) => {
  const result = Object.entries(slotsMap).reduce((acc, [key]) => {
    acc[key] = { type: 'TOKEN', mutability: 'IMMUTABLE' };
    return acc;
  }, {});
  return result;
};

const isSlot = (word) => {
  const regex = /\$\{.+?\}/g;
  const isCurly = word.match(regex);

  if (isCurly) {
    const [getCurly] = isCurly;
    const cropped = getCurly.substring(2, getCurly.length - 1);
    return cropped;
  }
  return false;
};

export const getEntityStrategy = () =>
  function (contentBlock, callback) {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      if (entityKey === null) {
        return false;
      }
      return true;
    }, callback);
  };

export const tokenSpan = (props) => {
  const { decoratedText } = props;
  const style = getDecoratedStyle(decoratedText);
  return (
    <span data-offset-key={props.offsetkey} style={style}>
      {props.children}
    </span>
  );
};

export const convertFromBlackBoiler = (clauseObject, mode) => {
  if (!clauseObject) {
    return {
      blocks: [
        {
          text: '',
          type: 'unstyled',
        },
      ],
      entityMap: slotsEntityMap(getSlotStyleByContractType('default')),
    };
  }
  const { clause } = clauseObject;
  let words = clause.split(' ');

  // Fix for words attached to slots without spaces
  let fixedWords = [];
  words.forEach((word) => {
    if (word.includes('$') && word.charAt(0) !== '$') {
      const newWords = word.split('$');
      newWords[1] = `$${newWords[1]}`;
      newWords.forEach((newWord) => {
        if (newWord.includes('}') && newWord.charAt(-1) !== '}') {
          const newSplitWords = newWord.split('}');
          newSplitWords[0] = `${newSplitWords[0]}}`;
          newSplitWords.forEach((newSplitWord) => {
            fixedWords.push(newSplitWord);
          });
        } else {
          fixedWords.push(newWord);
        }
      });
    } else if (word.includes('}') && word.charAt(-1) !== '}') {
      const newWords = word.split('}');
      newWords[0] = `${newWords[0]}}`;
      newWords.forEach((newWord) => {
        fixedWords.push(newWord);
      });
    } else {
      fixedWords.push(word);
    }
  });

  if (mode === 'read') {
    const maxLength = 150;
    let clauseLength = 0;

    fixedWords = fixedWords.filter((word) => {
      clauseLength += word.length;
      return clauseLength < maxLength;
    });

    if (clauseLength > maxLength) {
      fixedWords = [...fixedWords, ' ... '];
    }
  }

  // Add entityRanges object only entities
  let offset = 0;
  const entityRanges = fixedWords
    .map((word) => {
      const result = {};
      const slot = isSlot(word);

      if (slot) {
        result.key = slot;
        result.length = slot.length;
      } else {
        offset = offset + word.length + 1;
        result.length = word.length;
      }

      result.offset = offset;

      if (slot) {
        offset = offset + slot.length + 1;
        return result;
      }

      return false;
    })
    .filter((value) => value);

  const textWithoutCurly = fixedWords.map((word) => {
    const slot = isSlot(word);

    if (slot) {
      return slot;
    }
    return word;
  });

  return {
    blocks: [
      {
        text: textWithoutCurly.join(' ').replace(/(\r\n|\n|\r)/gm, ''),
        type: 'unstyled',
        entityRanges,
      },
    ],
    entityMap: slotsEntityMap(getSlotStyleByContractType('default')),
  };
};

export const getBbTextFromRaw = (rawContent) => {
  const {
    blocks: [block],
  } = rawContent;
  const { entityRanges, text } = block;

  const entityMap = entityRanges.reduce((acc, { offset, length }) => {
    acc[offset] = '${';
    acc[offset + length] = '}';
    return acc;
  }, {});

  const entityMapKeys = new Set(Object.keys(entityMap).map((x) => parseInt(x, 10)));

  const withCurly = [...text, ' ']
    .map((character, index) => {
      if (entityMapKeys.has(index)) {
        return `${entityMap[index]}${character}`;
      }
      return character;
    })
    .join('');

  return withCurly;
};
