// SNACKBAR
import { SET_ERR_MSG, SET_SUCCESS_MSG } from '../action-types/snackbarTypes';

export const setSuccessMsg = (value) => ({
  type: SET_SUCCESS_MSG,
  payload: value,
});

export const setErrMsg = (value) => ({
  type: SET_ERR_MSG,
  payload: value,
});
