import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Modal, Typography } from '@mui/material';

import ContractIcon from '../../assets/images/contract_icon.png';
import Styles from '../../styles/Modals';

const ToolTipModal = ({ classes, open, handleClose, content }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-label="Edit or Create a model"
    className={classes.modalWrapper}
  >
    <div className={classes.modalContentWrapper}>
      <div className={`container ${classes.modalHeader} pb-2 pt-4`}>
        <div className="row pb-4 text-center">
          <div className="col pt-3 pl-5 pr-0">
            <img src={ContractIcon} alt="" className={`${classes.modalModelImg} pb-4`} />
            <Typography variant="h2">{content.title}</Typography>
          </div>
          <div className="col-auto d-flex flex-column justify-content-start">
            <IconButton
              color="inherit"
              aria-label="Close model modal"
              onClick={handleClose}
              edge="end"
              autoFocus
              size="large"
            >
              <CloseIcon className={classes.modalCloseIcon} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="container pl-5 pr-5 pt-5 pb-5">
        <div className="row pt-3">
          <div className="col text-center">
            <Typography
              variant="body2"
              className="pt-2"
              dangerouslySetInnerHTML={{ __html: content.description }}
            />
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

ToolTipModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(ToolTipModal);
