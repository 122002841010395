import { createTheme, adaptV4Theme } from '@mui/material/styles';

import Colors from './Colors';

const theme = createTheme(
  adaptV4Theme({
    props: {
      MuiTextField: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    overrides: {
      MuiPaper: {
        elevation1: {
          border: `1px solid ${Colors.grey8}`,
          backgroundColor: Colors.white,
          boxShadow: '0 0 8px 0 rgba(0,0,0,0.1)',
        },
      },
      MuiTable: {
        root: {
          height: '100%',
        },
      },
      MuiTableBody: {
        root: {
          border: `1px solid ${Colors.grey8}`,
        },
      },
      MuiTableHead: {
        root: {
          border: `1px solid ${Colors.grey8}`,
        },
      },
      MuiTableRow: {
        root: {
          '&[data-oddrow="true"]': {
            backgroundColor: Colors.grey7,
          },
        },
      },
      MuiTableCell: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
          '& p': {
            color: Colors.grey4,
          },
          borderBottom: `1px solid ${Colors.grey8}`,
        },
        head: {
          height: 56,
          fontSize: '1.3rem',
          fontFamily: 'Inter, san-serif',
          color: 'black',
          fontWeight: 600,
        },
      },
      MuiSlider: {
        rail: {
          borderRadius: 9,
          height: 9,
          backgroundColor: Colors.grey5,
        },
        // this scheme is used for the "gradient" version of the slider
        colorSecondary: {
          '& $rail': {
            background: Colors.grey2,
          },
          '& $markLabel': {
            top: -15,
            color: Colors.grey2,
          },
          '& $markLabelActive': {
            color: Colors.grey2,
          },
          '& $track': {
            backgroundImage: 'linear-gradient(.25turn, #1484C9, #1EAB9E)',
          },
        },
        track: {
          borderRadius: 9,
          height: 9,
          color: Colors.blue4,
        },
        mark: {
          height: 9,
          backgroundColor: Colors.white,
        },
        markLabel: {
          fontSize: '1.3rem',
          fontWeight: 600,
        },
        thumb: {
          height: 20,
          width: 20,
          marginTop: -6,
          color: Colors.blue,
        },
        root: {
          marginTop: 15,
          color: Colors.white,
        },
        valueLabel: {
          top: 25,
          left: -6,
          fontSize: '1.3rem',
          fontWeight: 700,
          '& *': {
            background: 'transparent',
            color: '#000',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          fontWeight: 600,
          fontSize: '1.3rem',
          '&[role="alert"]': {
            color: Colors.red,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          textAlign: 'left',
          color: Colors.grey4,
          outline: 0,
          width: '100%',
          padding: '4px 0',
          borderRadius: 3,
          fontSize: '1.5rem',
          '& $notchedOutline': {
            border: `1px solid ${Colors.grey3}`,
          },
          '&:hover': {
            '& $notchedOutline': {
              border: `1px solid ${Colors.blue}`,
            },
          },
          '&$focused': {
            '& $notchedOutline': {
              border: `1px solid ${Colors.blue}`,
              boxShadow: `0 0 0 3px ${Colors.blue3}`,
            },
            // borderRadius: 3,
            // color: 'black',
          },
          '&$error$focused': {
            '& $notchedOutline': {
              border: '1px solid red',
            },
          },
          '&$disabled': {
            '& $notchedOutline': {
              border: `1px solid ${Colors.grey3}`,
            },
            color: Colors.grey3,
          },
        },
        input: {
          padding: '7px 10px',
          '&::placeholder': {
            color: Colors.grey2,
          },
        },
      },
      MuiStepContent: {
        root: {
          marginLeft: '4px',
        },
      },
      MuiStepConnector: {
        vertical: {
          marginLeft: '4px',
        },
      },
      MuiSvgIcon: {
        fontSizeSmall: {
          fontSize: '2rem',
        },
      },
      MuiButton: {
        outlinedSecondary: {
          // used with tables
          border: `1px solid ${Colors.grey8}`,
          color: 'black',
        },
        containedPrimary: {
          '&$disabled': {
            color: Colors.white,
            backgroundColor: Colors.blue,
          },
        },
      },
      MuiIconButton: {
        colorSecondary: {
          border: `1px solid ${Colors.grey8}`,
          borderRadius: 3,
          height: 30,
          width: 30,
          '& svg': {
            color: Colors.grey5,
          },
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: '1.5rem',
          fontFamily: 'Inter, san-serif',
          color: 'black',
          fontWeight: 600,
          '&$focused': {
            color: 'black',
          },
        },
      },
      MuiInputLabel: {
        outlined: {
          fontSize: '1.5rem',
          fontFamily: 'Inter, san-serif',
          color: 'black',
          fontWeight: 600,
        },
        root: {
          fontSize: '1.5rem',
          fontFamily: 'Inter, san-serif',
          color: 'black',
          fontWeight: 600,
        },
      },
      MuiSelect: {
        iconOutlined: {
          top: 10,
          fill: 'black !important',
        },
        select: {
          '&:focus': {
            backgroundColor: 'none',
          },
        },
      },
      MuiMenu: {
        paper: {
          border: `1px solid ${Colors.grey3}`,
          marginTop: 15,
        },
      },
      MuiListItem: {
        root: {
          borderLeft: '4px solid white',
          fontSize: '1.4rem',
          fontFamily: 'Inter, san-serif',
          color: Colors.grey2,
          fontWeight: 400,
          '&$button': {
            '&:hover, &$focusVisible': {
              backgroundColor: `rgba(${Colors.blue3RGB}, .2)`,
              borderLeft: `4px solid rgba(${Colors.blue3RGB}, .0)`,
            },
            '&$selected, &$selected:hover': {
              backgroundColor: `rgba(${Colors.blue3RGB}, .2)`,
              borderLeft: `4px solid ${Colors.blue}`,
              color: Colors.blue,
            },
            '&$selected$disabled': {
              opacity: 1,
            },
          },
        },
      },
      MuiFormControl: {
        root: {
          width: '100%',
        },
      },
      MuiFormControlLabel: {
        root: {
          '&[data-outlined="true"]': {
            width: '100%',
            marginTop: 14,
            border: 0,
            backgroundColor: Colors.grey6,
            paddingBottom: 7,
            paddingLeft: 4,
            paddingRight: 10,
            paddingTop: 8,
            borderRadius: 5,
            '&[data-checked="true"]': {
              paddingBottom: 6,
              paddingLeft: 3,
              paddingRight: 9,
              paddingTop: 7,
              backgroundColor: Colors.white,
              border: `1px solid ${Colors.blue}`,
            },
          },
          '&[data-outlined="false"]': {
            width: '100%',
            marginTop: 14,
            border: 0,
            backgroundColor: Colors.grey6,
            paddingBottom: 7,
            paddingLeft: 4,
            paddingRight: 10,
            paddingTop: 8,
            borderRadius: 5,
            '&[data-checked="true"]': {
              paddingBottom: 6,
              paddingLeft: 3,
              paddingRight: 9,
              paddingTop: 7,
              backgroundColor: Colors.white,
              border: `1px solid ${Colors.grey6}`,
            },
          },
        },
        label: {
          fontSize: '1.4rem',
          color: 'black',
        },
      },
      MuiCheckbox: {
        root: {
          paddingTop: 5,
          paddingBottom: 5,
          color: Colors.grey5,
          '& svg': {
            width: 18,
            height: 18,
          },
        },
      },
      MuiContainer: {
        maxWidthXl: {
          width: 1440,
        },
      },
      MuiTabs: {
        indicator: {
          backgroundColor: Colors.blue,
          left: 40,
          width: 80,
        },
      },
      MuiRadio: {
        root: {
          paddingTop: 5,
          paddingBottom: 5,
          color: Colors.grey5,
          '& svg': {
            width: 18,
            height: 18,
          },
        },
      },
      MuiDialog: {
        paper: {
          borderRadius: 8,
        },
      },
      MuiListSubheader: {
        root: {
          fontSize: '12px',
          fontWeight: 700,
          marginLeft: '3px',
          textTransform: 'uppercase',
        },
      },
      MuiChip: {
        root: {
          marginRight: 5,
          fontSize: '12px',
          fontWeight: 500,
          backgroundColor: Colors.blue3,
          height: 24,
        },
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: Colors.black,
        },
        colorSecondary: {
          color: Colors.trueWhite,
        },
      },
      MuiAutocomplete: {
        paper: {
          backgroundColor: Colors.trueWhite,
          color: Colors.black,
        },
      },
      MuiSwitch: {
        colorSecondary: {
          '&$checked': {
            color: Colors.red,
            '&$checked + $track': {
              backgroundColor: Colors.red,
            },
          },
        },
      },
    },
    typography: {
      useNextVariants: true,
      fontFamily: 'Inter, san-serif',
      h1: {
        fontFamily: 'Montserrat, san-serif',
        fontSize: '2.4rem',
        color: 'black',
        fontWeight: 700,
      },
      h2: {
        fontFamily: 'Montserrat, san-serif',
        fontSize: '1.8rem',
        color: 'black',
        fontWeight: 600,
      },
      h3: {
        fontFamily: 'Montserrat, san-serif',
        fontSize: '1.5rem',
        color: 'black',
        fontWeight: 600,
      },
      h4: {
        fontFamily: 'Montserrat, san-serif',
        fontSize: '1.3rem',
        textTransform: 'uppercase',
        color: Colors.blue,
        fontWeight: 700,
      },
      body1: {
        fontSize: '1.3rem',
        color: Colors.grey2,
        fontWeight: 400,
        lineHeight: 1.3,
      },
      body2: {
        fontSize: '1.5rem',
        color: 'black',
        fontWeight: 400,
      },
      button: {
        fontSize: '1.3rem',
        fontWeight: 700,
        minHeight: 40,
        paddingLeft: 24,
        paddingRight: 24,
        letterSpacing: 1,
        borderRadius: 5,
      },
    },
    palette: {
      primary: {
        main: Colors.blue,
        contrastText: Colors.white,
      },
      secondary: {
        main: Colors.grey4,
      },
      background: {
        default: Colors.pageBackground,
      },
      error: {
        main: Colors.red,
      },
      success: {
        main: Colors.green,
      },
    },
  })
);

export default theme;
