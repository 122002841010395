import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import createToolbarPlugin, { Separator } from '@draft-js-plugins/static-toolbar';
import Editor from '@draft-js-plugins/editor';
import { EditorState, Modifier } from 'draft-js';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton,
} from '@draft-js-plugins/buttons';

import withStyles from '@mui/styles/withStyles';
import { Grid, Paper } from '@mui/material';

import { TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY } from '../../config';
import { getSlotStyleByContractType } from '../../utils/ClauseUtils';
import { GenerateDecorator } from '../../utils/TextEditorUtils';
import DraftJsInsertSlotPlugin from './DraftJsInsertSlotPlugin';
import TextEditorStyles from './styles';

// src: https://reactrocket.com/post/getting-started-with-draft-js-plugins/
// src: https://github.com/juliankrispel/draft-js-building-search-and-replace/blob/master/src/App.js

const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;
const plugins = [toolbarPlugin];

// Main editor component to handle slot insertion
const TextEditor = ({
  classes,
  setTemplated = (f) => f,
  setRaw = (f) => f,
  setCombined = (f) => f,
  slotStrategy = TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY.TRAINING,
  initialValue = '',
  readOnly = false,
  showExtendedButtons = false,
  contractType = 'default',
  sx = {},
}) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const updateEditorState = (editState, text, mutability, offset) => {
    const content = editState.getCurrentContent();
    const selection = editState.getSelection();
    const contentStateWithEntity = content.createEntity(text, mutability, { raw: text });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const textWithEntity = Modifier.insertText(
      content,
      selection.merge({
        anchorOffset: offset,
        focusOffset: offset,
      }),
      text,
      null,
      entityKey
    );
    const newState = EditorState.push(
      editState,
      textWithEntity,
      'insert-characters',
      true
    );
    const nextEditorState = EditorState.forceSelection(
      newState,
      textWithEntity.getSelectionAfter()
    );
    return EditorState.createWithContent(
      nextEditorState.getCurrentContent(),
      GenerateDecorator()
    );
  };

  const initializeEditor = () => {
    // Split so we can create entities. e.g. "This is a ${deal:''}" -> ["This is a ", "${deal:''}"]
    const initialValuesSplit = initialValue.split(/(\${[A-z]*[:a-zA-Z_ ']*?})/g);

    const textWithMutability = initialValuesSplit.map((text) => {
      const slotKeys = Object.keys(getSlotStyleByContractType(contractType));
      const match = text.match(/\${([A-z]*)[:a-zA-Z_ ']*?}/);

      if (match && match.length > 1 && slotKeys.includes(match[1])) {
        // slot entity
        const parsedText = match[1];
        return {
          text: parsedText,
          mutability: 'IMMUTABLE',
        };
      }
      // regular entity
      return {
        text,
        mutability: 'MUTABLE',
      };
    });

    let modifiedEditorState = EditorState.createEmpty();
    let offset = 0;

    textWithMutability.forEach(({ text, mutability }) => {
      modifiedEditorState = updateEditorState(
        modifiedEditorState,
        text,
        mutability,
        offset
      );
      offset += text.length;
    });

    return modifiedEditorState;
  };

  useEffect(() => {
    setEditorState(initializeEditor);
  }, [initialValue]);

  return (
    <>
      <Paper className={classes.frame} sx={{ width: '100%', ...sx }}>
        {readOnly ? null : (
          <Toolbar>
            {
              // maybe use React.Fragment instead of div to improve performance after React 16
              (externalProps) => (
                <Grid
                  container
                  className={classes.buttonBar}
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item className={classes.button}>
                    <DraftJsInsertSlotPlugin
                      {...externalProps}
                      editorState={editorState}
                      setEditorState={setEditorState}
                      slotStrategy={slotStrategy}
                      setRaw={setRaw}
                      setTemplated={setTemplated}
                      setCombined={setCombined}
                      contractType={contractType}
                    />
                  </Grid>
                  {showExtendedButtons ? (
                    <>
                      <Grid item className={classes.button}>
                        <BoldButton {...externalProps} />
                      </Grid>
                      <Grid item className={classes.button}>
                        <ItalicButton {...externalProps} />
                      </Grid>
                      <Grid item className={classes.button}>
                        <UnderlineButton {...externalProps} />
                      </Grid>
                      <Grid item className={classes.button}>
                        <CodeButton {...externalProps} />
                      </Grid>
                      <Grid item className={classes.button}>
                        <Separator {...externalProps} />
                      </Grid>
                      <Grid item className={classes.button}>
                        <UnorderedListButton {...externalProps} />
                      </Grid>
                      <Grid item className={classes.button}>
                        <OrderedListButton {...externalProps} />
                      </Grid>
                      <Grid item className={classes.button}>
                        <BlockquoteButton {...externalProps} />
                      </Grid>
                      <Grid item className={classes.button}>
                        <CodeBlockButton {...externalProps} />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              )
            }
          </Toolbar>
        )}
        <Editor
          stripPastedStyles
          readOnly={readOnly}
          editorState={editorState}
          onChange={setEditorState}
          plugins={plugins}
        />
      </Paper>
    </>
  );
};

TextEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  setRaw: PropTypes.func,
  setTemplated: PropTypes.func,
  setCombined: PropTypes.func,
  slotStrategy: PropTypes.string,
  initialValue: PropTypes.string,
  readOnly: PropTypes.bool,
  showExtendedButtons: PropTypes.bool,
  contractType: PropTypes.string,
};

export default withStyles(TextEditorStyles)(TextEditor);
